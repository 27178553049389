import { Link } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import globals from '../../assets/styles/globals.scss'
import { ReactComponent as CheckMarkIconHexagonal } from '../../assets/images/common/icons/check-mark-icon-hexagonal.svg'
import { ReactComponent as Top50IconHexagonal } from '../../assets/images/common/icons/top50-icon-hexagonal.svg'

import classes from './CommunityCard.module.scss'

const CommunityCard = ({ community }) => (
  <Link className={classNames(classes.communityCard, 'd-flex flex-column')} to={`/landing/${community.id}`} target='_blank'>
    <header
      style={{
        background: community.headerImage
          ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${community.headerImage.originalFileUrl}) center/cover no-repeat`
          : globals.darkNavy,
      }}
      className={classes.communityHeaderImage}
    >
      {community.isMasterTop50 && (
        <div className='d-flex align-items-center'>
          <CheckMarkIconHexagonal className={classes.accoladeIcon}/>
          <span className={classes.certifiedText}>Top 50 Master Coach</span>
        </div>
      ) || community.isTop50 && (
        <div className='d-flex align-items-center'>
          <Top50IconHexagonal className={classes.accoladeIcon}/>
          <span className={classes.certifiedText}>Top 50 Coach</span>
        </div>
      ) || community.isCertified && (
        <div className='d-flex align-items-center'>
          <CheckMarkIconHexagonal className={classes.accoladeIcon}/>
          <span className={classes.certifiedText}>Certified Location</span>
        </div>
      )}
    </header>

    <article className={classNames(classes.communityPhoto, 'large-round-image-wrapper')}>
      <img className='round-image' src={community.photo?.originalFileUrl} alt='community avatar' />
    </article>

    <section className={classNames(classes.communityInfo, 'd-flex flex-column flex-grow-1')}>
      <h3 className={classes.nameText}>{community.name}</h3>
      <small className={classes.addressText}>{community.address.name}</small>
    </section>

    <section className={classNames(classes.communityDetails, 'd-flex flex-column justify-content-end')}>
      <span className={classes.programsCountText}>
        {community.activeProgramsCount > 0 && (
          <span>{community.activeProgramsCount} Active Programs</span>
        )}

        {community.futureProgramsCount > 0 && (
          <span>{community.activeProgramsCount > 0 ? ' / ' : ''}{community.futureProgramsCount} Future Programs</span>
        )}

        {community.activeProgramsCount === 0 && community.futureProgramsCount === 0 && (
          <span>No Programs</span>
        )}
      </span>

      {community.distance?.text && (
        <div className={classNames(classes.distanceWrapper, 'd-flex mt-1')}>
          <div className={classes.bulletPoint}></div>
          <span className={classes.distanceText}>{community.distance.text} away / {community.duration.text}</span>
        </div>
      )}
    </section>
  </Link>
)

CommunityCard.propTypes = {
  community: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    activeProgramsCount: PropTypes.number,
    futureProgramsCount: PropTypes.number,
    distance: PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.number,
    }),
    duration: PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.number,
    }),
    isMasterTop50: PropTypes.bool,
    isTop50: PropTypes.bool,
    isCertified: PropTypes.bool,
    address: PropTypes.shape({
      name: PropTypes.string,
    }),
    photo: PropTypes.shape({
      originalFileUrl: PropTypes.string,
    }),
    headerImage: PropTypes.shape({
      originalFileUrl: PropTypes.string,
    }),
  }).isRequired,
}

export default CommunityCard
