import { useSelector, useDispatch } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import classNames from 'classnames'

import { createAuthFamilyMember, createUserSignIn, signInUser } from '../../../actions/authActions'
import { showSideBar, showNavBar, setSideBarState } from '../../../actions/commonActions'
import { initializePendo } from '../../../utility/pendo'
import { NAVIGATION } from '../../../misc/Constants'
import AboutYou from './Steps/AboutYou'
import PrimaryInformation from './Steps/PrimaryInformation'
import SecondaryInformation from './Steps/SecondaryInformation'
import Summary from './Steps/Summary'
import NewMemberAboutData from './Steps/NewMemberAboutData'
import NewMemberLogInData from './Steps/NewMemberLogInData'
import ChildSocialSettings from './Steps/ChildSocialSettings'

import classes from './SignUpPage.module.scss'

const SignUpPage = () => {
  const dispatch = useDispatch()

  const step = useSelector(state => state.signUp.step)

  const handleCreateUser = (user) => {
    dispatch(user.accountSegment ? createUserSignIn(user) : createAuthFamilyMember(user)).unwrap()
      .then(() => {
        if (user.accountSegment) {
          dispatch(signInUser(user.email, user.password))
            .then((response) => {
              initializePendo(window.pendo, response)

              dispatch(showSideBar(true))
              dispatch(showNavBar(true))
              dispatch(setSideBarState(NAVIGATION.COLLAPSED))
            })
        }
      })
      .catch(() => {
        toast.error('Something went wrong. Please check the steps where errors occurred and fix them.', { position: toast.POSITION.TOP_RIGHT })
      })
  }

  return (
    <main className={classes.signUpPage}>
      <section className={classNames(classes.currentStep, 'd-flex')}>
        {step === 4 ? (
          <aside className={classNames(classes.sideCard, 'd-flex ml-auto')}>
            <Summary />
          </aside>
        ) : (
          <aside className={classNames(classes.mainCard, 'd-flex align-self-center m-auto')}>
            {
              step === 1 && <AboutYou /> ||
              step === 2 && <PrimaryInformation /> ||
              step === 3 && <SecondaryInformation onCreateUser={handleCreateUser}/> ||
              step === 5 && <NewMemberAboutData /> ||
              step === 6 && <NewMemberLogInData onCreateUser={handleCreateUser}/> ||
              step === 7 && <ChildSocialSettings onCreateUser={handleCreateUser}/>
            }
          </aside>
        )}
      </section>

      <ToastContainer theme='colored' />
    </main>
  )
}

export default SignUpPage
