import * as types from "../actions/actionTypes";

export default function pages(state = [], action) {
  switch (action.type) {
    case types.GET_COACH_ENGAGEMENT_REPORT:
      return action.pages;
    case types.GET_COMMUNITY_PRIORITY_REPORT:
      return action.pages;
    case types.GET_DRILLS:
      return Object.assign({}, state, {
        public: action.pages,
      });
    case types.GET_MY_DRILLS:
      return Object.assign({}, state, {
        // don't change these key names as they're matched to the view state of the drills page
        myDrills: action.pages,
      });
    case types.GET_MY_ACTIVITIES:
      return Object.assign({}, state, {
        myActivities: action.pages,
      });
    case types.GET_PUBLIC_DRILLS:
      return Object.assign({}, state, {
        publicDrills: action.pages,
      });
    case types.GET_PUBLIC_ACTIVITIES:
      return Object.assign({}, state, {
        publicActivities: action.pages,
      });
    case types.ADD_USER_COMPLETED_COURSES:
      return action.pages;
    case types.ADD_USER_TEMPLATE_COURSES:
      return action.pages;
    case types.ADD_USER_ARCHIVED_COURSES:
      return action.pages;
    case types.ADD_USER_COURSES:
      return action.pages;
    case types.ADD_ANNOUNCEMENTS:
      return action.pages;
    case types.GET_SALES_LEADS:
      return action.pages;
    case types.GET_EVENTS:
      return action.pages ?? state
    case types.GET_COMMUNITIES_PAGINATED:
      return action.pages
    default:
      return state;
  }
}
