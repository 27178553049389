import PropTypes from 'prop-types'
import classNames from 'classnames'

import AvatarPlaceholder from '../../assets/images/mock/default-profile-icon.png'
import SmallMilestoneIcon from '../common/SmallMilestoneIcon'

import classes from './AccountCard.module.scss'

const AccountCard = ({ avatarUrl, fullName, age, familyAdmin, division, className }) => (
  <article
    className={classNames(classes.accountCard, { [className]: className }, 'd-flex align-items-center')}
  >
    <div className={classes.accountVisuals}>
      <div className='medium-round-image-wrapper'>
        <img
          className='round-image'
          src={avatarUrl || AvatarPlaceholder}
          alt='User Avatar'
        />
      </div>

      <div className={classNames(classes.divisionWrapper)}>
        <SmallMilestoneIcon division={division || 1} fromAvatar />
      </div>
    </div>

    <div className={classNames(classes.accountInfo, 'd-flex flex-column justify-content-center align-items-left ml-3')}>
      <p className={classes.fullName}>{`${fullName}, ${age} yrs`}</p>
      {familyAdmin && (
        <p className={classes.familyAdmin}>Family Admin</p>
      )}
    </div>
  </article>
)

AccountCard.propTypes = {
  avatarUrl: PropTypes.string,
  fullName: PropTypes.string,
  age: PropTypes.number,
  familyAdmin: PropTypes.bool,
  className: PropTypes.string,
  division: PropTypes.number,
}

export default AccountCard
