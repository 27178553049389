import { useSelector } from 'react-redux'

import EnrolledFamilyView from './EnrolledFamilyView/EnrolledFamilyView'
import NewFamilyView from './NewFamilyView/NewFamilyView'
import classes from './ProgramsTab.module.scss'

const ProgramsTab = () => {
  const user = useSelector(state => state.user.current_user)

  return (
    <div className={classes.programsTab}>
      {user.communities.length === 0 ? (
        <NewFamilyView />
      ) : (
        <EnrolledFamilyView />
      )}
    </div>
  )
}

export default ProgramsTab
