import PropTypes from 'prop-types'
import classNames from 'classnames'

import { ReactComponent as RoundedPlusIcon } from '../../../../../assets/images/common/icons/rounded-plus-icon.svg'
import AvatarPlaceholder from '../../../../../assets/images/mock/default-profile-icon.png'
import FieldError from '../../../../common/FieldError'

import classes from './AvatarInput.module.scss'

const AvatarInput = ({ avatarUrl, onAvatarChange, error, imageSize }) => (
  <div className={classes.avatarInput}>
    <input id='avatar' type='file' accept='image/*' className='d-none' onChange={onAvatarChange} />
    <label htmlFor='avatar' className={classNames(classes.avatarLabel, 'd-flex align-items-center')}>
      <div className={classNames(classes.avatar, `${imageSize || 'medium'}-round-image-wrapper`)}>
        <img
          className='round-image'
          src={avatarUrl || AvatarPlaceholder}
          alt='User Avatar'
        />
      </div>
      <RoundedPlusIcon className={classes.roundedPlusIcon}/>
      <p className={classes.avatarText}>
        {avatarUrl ? 'Upload Another Photo' : 'Add Photo (Optional)'}
      </p>

      {error && <FieldError message={error} />}
    </label>
  </div>
)

AvatarInput.propTypes = {
  avatarUrl: PropTypes.string,
  onAvatarChange: PropTypes.string,
  error: PropTypes.string,
  imageSize: PropTypes.string,
}

export default AvatarInput
