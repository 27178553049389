import './CommunityPlaceholder.scss'

import greyPin from '../../assets/images/common/icons/op36-location-pin-icon.svg'

const CommunityPlaceholder = () => (
  <div id='CommunityPlaceholder'>
    <img src={greyPin} alt='pin' />

    <p className='mt-3'>
      We&apos;ll display the Operation 36 locations closest to you here. Enter your address or city in the search bar above to start!
    </p>
  </div>
)

export default CommunityPlaceholder
