import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { UncontrolledPopover } from 'reactstrap'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import classNames from 'classnames'

import { getUnreadNotifications, clearNotifications } from '../../../../actions/notificationActions'
import { ReactComponent as NotificationIcon } from '../../../../assets/images/common/icons/bell.svg'
import StockProfilePic from '../../../../assets/images/mock/StockProfilePic.png'
import NotificationsPopover from '../../../common/NotificationsPopover'

import classes from './UserActions.module.scss'

const UserActions = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const user = useSelector(state => state.user.current_user)
  const notifications = useSelector(state => state.notifications)
  const currentUser = useSelector(state => state.user.current_user)
  const [loader, setLoader] = useState(false)

  const navigateFromNotification = (type, id) => {
    switch (type) {
      case 'NotificationApply':
        navigate('/dashboard/programs')
        break
      case 'NotificationFamily':
        navigate('/dashboard/account')
        break
      case 'NotificationComment':
        handleNotificationActivityClick(id)
        break
      case 'NotificationLike':
        handleNotificationActivityClick(id)
        break
      default:
        return
    }
  }

  const handleNotificationActivityClick = (activityId) => {
    navigate(`/profile/${currentUser.id}`, { state: { userId: currentUser.id, activityId } })
  }

  const handleClearNotifications = () => {
    setLoader(true)
    dispatch(clearNotifications(currentUser.id)).then(() => {
      setLoader(false)
    })
  }

  const refreshNotifications = () => {
    setLoader(true)
    dispatch(getUnreadNotifications(currentUser.id)).then(() => {
      setLoader(false)
    })
  }

  return (
    <div className={classNames(classes.userActions, 'd-flex flex-row justify-content-between align-items-center')}>
      <section className={classes.notificationSection}>
        <button className={classes.notificationButton} id='NotificationsButton'>
          <NotificationIcon className={classes.icon}/>
          {notifications.length > 0 && <div className={classes.badge}/>}
        </button>
        <UncontrolledPopover placement='bottom' target='NotificationsButton' trigger='legacy'>
          <NotificationsPopover
            notifications={notifications}
            refreshNotifications={refreshNotifications}
            clearNotifications={handleClearNotifications}
            loader={loader}
            navigateFromNotification={navigateFromNotification}
          />
        </UncontrolledPopover>
      </section>

      <section className={classNames(classes.avatarSection, 'd-flex align-items-center ml-auto')} data-for='userTooltip' data-tip>
        <div className={classNames(classes.avatarWrapper, 'smaller-round-image-wrapper')}>
          <img
            className='round-image'
            src={user.avatar ? user.avatar.file_url : StockProfilePic}
            alt={`${user.first_name} ${user.last_name}`}
          />
        </div>

        <ReactTooltip
          id='userTooltip'
          offset={{ top: '4', left: '4' }}
          effect='solid'
          place='bottom'
          delayUpdate={100}
          delayHide={100}
          className={classes.tooltipWrapper}
        >
          <div className={classNames(classes.userTooltip, 'd-flex flex-column p-0')}>
            <Link to={`/profile/${currentUser.id}`}>View Profile</Link>
            <Link to='/account'>Edit Account</Link>
            <Link to='/log-in' className={classes.logOut}>Log Out</Link>

            <footer className={classNames(classes.termsAndPolicies)}>
              <div className='d-flex align-items-center'>
                <a href='https://operation36.golf/legal/privacy/' target='_blank' rel='noreferrer' className={classes.footerTitle}>
                  Privacy Policy
                </a>

                <span className={classNames(classes.separator, 'mx-1')}>-</span>

                <a href='https://operation36.golf/legal/terms/' target='_blank' rel='noreferrer' className={classes.footerTitle}>
                  Terms of Use
                </a>
              </div>
              <small className={classes.footerSubtitle}>All Rights Reserved | Operation 36</small>
              <h6 className={classes.footerTitle}>v3.0.0</h6>
            </footer>
          </div>
        </ReactTooltip>
      </section>
    </div>
  )
}

export default UserActions
