import React, { useCallback } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import Placeholder from "./Placeholder";

const modalStyles = {
  content: {
    height: "460px",
    width: "30%",
    marginTop: "12%",
    overflow: "hidden"
  }
};

const CommunityBlockModal = ({ showModal, openModal, closeModal }) => {
  const navigate = useNavigate()

  const signOut = useCallback(
    () => {
      navigate('/log-in')
      closeModal && closeModal()
    },
    [ navigate, closeModal ]
  )

  return (
    <Modal
      style={modalStyles}
      isOpen={showModal}
      contentLabel="Invite Family Modal"
    >
      <div className="modal-content animated fadeIn">
        <div className="my-3">
          <div className="col-12 mx-auto">
            <Placeholder
              title={"You do not have Access"}
              icon={"fa-ban"}
              subtext1={
                "Your community is no longer using Operation 36 and you no longer have access."
              }
              subtext2={"Please click the button to sign out of the app."}
              buttonText={"Sign Out"}
              buttonIcon={"fa-sign-out"}
              handleClick={signOut}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CommunityBlockModal;
