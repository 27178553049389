import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { differenceInYears } from 'date-fns'
import classNames from 'classnames'
import moment from 'moment'

import { setUserField, setUserError } from '../../../../reducers/signUpReducer'
import { isValidEmail } from '../../../../utility/helperFunctions'
import ProgressBar from './Helpers/ProgressBar'
import StepButton from './Helpers/StepButton'
import FormInput from './Helpers/FormInput'

import classes from './PrimaryInformation.module.scss'

const customErrorMessages = {
  email: 'Your email address is not valid.',
}

const PrimaryInformation = () => {
  const dispatch = useDispatch()

  const { errors, accountSegment, firstName, lastName, email, birthDate, phone } = useSelector(state => state.signUp.user)

  const formInvalid = useMemo(() => {
    const anyEmptyFields = !firstName || !lastName || !email || !birthDate || !phone
    const anyErrors = !isValidEmail(email)

    return anyEmptyFields || anyErrors
  }, [firstName, lastName, email, birthDate, phone])

  const handleInputChange = e => {
    const field = e.target.name
    const value = e.target.value

    dispatch(setUserField({ field, value }))
  }

  const validateEmail = e => {
    const value = e.target.value

    if (email && !isValidEmail(value)) {
      dispatch(setUserError({ field: 'email', value: customErrorMessages.email }))
    } else {
      dispatch(setUserError({ field: 'email', value: null }))
    }
  }

  return (
    <div className={classNames(classes.primaryInformation, 'd-flex flex-column')}>
      <header className={classes.header}>
        <ProgressBar step={2} label='Your Information'/>
      </header>

      <form className={classNames(classes.mainContent, 'd-flex flex-column align-items-center justify-content-start')}>
        <div className='w-100'>
          <h1 className={classes.titleText}>
            Let&apos;s create your account first. {accountSegment !== 'individual' && 'You can add kids(s) and spouse next.'}
          </h1>
        </div>

        <div className='w-100'>
          <section className='row mb-2'>
            <div className='col-12 col-lg-6'>
              <label htmlFor='firstName' className={classes.inputLabel}>
                First Name <span className='required-text'>*</span>
              </label>

              <FormInput
                name='firstName'
                type='text'
                onChange={handleInputChange}
                value={firstName}
                error={errors.firstName}
                symbol={<i className={classNames(classes.inputSymbol, 'fa fa-user')}/>}
              />

              <label htmlFor='lastName' className={classes.inputLabel}>
                Last Name <span className='required-text'>*</span>
              </label>

              <FormInput
                name='lastName'
                type='text'
                onChange={handleInputChange}
                value={lastName}
                error={errors.lastName}
                symbol={<i className={classNames(classes.inputSymbol, 'fa fa-user')}/>}
              />

              <label htmlFor='email' className={classes.inputLabel}>
                Email <span className='required-text'>*</span>
              </label>

              <FormInput
                name='email'
                type='text'
                onChange={handleInputChange}
                onBlur={validateEmail}
                value={email}
                error={errors.email}
                symbol={<i className={classNames(classes.inputSymbol, 'fa fa-envelope')}/>}
              />
            </div>

            <div className='col-12 col-lg-6'>
              <label htmlFor='birthDate' className={classes.inputLabel}>
                Birthdate <span className='required-text'>*</span>
              </label>

              <FormInput
                name='birthDate'
                type='date'
                onChange={(e) => {
                  handleInputChange({ target: { name: 'birthDate', value: e } })
                  handleInputChange({ target: { name: 'age', value: differenceInYears(new Date(), new Date(e)) } })
                }}
                maxDate={moment().subtract(13, 'years').toDate()}
                placeholder='MM/DD/YYYY'
                value={birthDate}
                symbol={<i className={classNames(classes.inputSymbol, 'fa fa-calendar')}/>}
                error={errors.birthDate}
                autoComplete='off'
              />

              <label htmlFor='phone' className={classes.inputLabel}>
                Phone Number <span className='required-text'>*</span>
              </label>

              <FormInput
                name='phone'
                type='telephone'
                onChange={(e) => handleInputChange({ target: { name: 'phone', value: e } })}
                value={phone}
                error={errors.phone}
              />
            </div>
          </section>
        </div>

        <StepButton color='orange' message='Continue' newStep={3} disabled={formInvalid}/>
        <StepButton color='navy' message='Go Back' newStep={1}/>
      </form>
    </div>
  )
}

export default PrimaryInformation
