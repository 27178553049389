import styles from './Schedule.module.scss'
import { ReactComponent as ArrowIcon } from '../../../../../assets/images/common/icons/arrow-right.svg'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

const EmptySchedule = ({ title, text, noActivePrograms = false }) => {
  const navigate = useNavigate()

  return (
    <div className={styles.noActivePrograms}>
      <div className={styles.content}>
        <p className={styles.title}>{title}</p>
        <p className={styles.text}>{text}</p>
      </div>
      {noActivePrograms && <ArrowIcon className={styles.arrow} onClick={() => navigate('/programs')}/>}
    </div>
  )
}

EmptySchedule.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  noActivePrograms: PropTypes.bool,
}

export default EmptySchedule
