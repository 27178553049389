
import { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { ReactComponent as Op36LocationPinIcon } from '../../assets/images/common/icons/op36-location-pin-icon.svg'
import SubmitButton from '../common/buttons/SubmitButton'
import NoCommunityModal from './NoCommunityModal'

import classes from './NoNearbyCommunities.module.scss'

const NoNearbyCommunities = (address) => {
  const [showRequestLocationModal, setShowRequestLocationModal] = useState(false)

  return (
    <div className={classNames(classes.noNearbyCommunities, 'd-flex flex-column align-items-center h-100')}>
      <Op36LocationPinIcon className={classes.op36LocationPinIcon} />

      <h4 className={classNames(classes.title, 'mt-4')}>Sorry, No Locations Near You</h4>

      <small className={classNames(classes.subtitle, 'mt-2 mb-4')}>
        There are no communities near this location, please try again or browse the map.
      </small>

      <SubmitButton
        onClick={(e) => { e.preventDefault(); setShowRequestLocationModal(true) }}
        buttonMessage='Request a Location'
        buttonColor='orange-button'
        className='mt-auto'
      />

      <div
        className={classNames({ [classes.greyOutModalBackground]: showRequestLocationModal })}
        onClick={() => setShowRequestLocationModal(false)}
        aria-hidden='true'
      />


      {showRequestLocationModal && (
        <NoCommunityModal close={() => setShowRequestLocationModal(false)} location={address} />
      )}
    </div>
  )
}


NoNearbyCommunities.propTypes = {
  address: PropTypes.string,
}

export default NoNearbyCommunities
