import classNames from 'classnames'

import { ReactComponent as Op36Logo } from '../assets/images/logos/horizontal-logo.svg'
import backgroundVideo from './../assets/videos/welcome-page-background-video.mp4'
import backgroundVideoPoster from './../assets/images/welcome-page-background-video-poster.webp'
import ButtonLink from './common/buttons/ButtonLink'

import classes from './WelcomePage.module.scss'

const WelcomePage = () => (
  <main className={classNames(classes.welcomePage, 'd-flex flex-md-row')}>
    <aside className={classes.backgroundPanel}>
      <video autoPlay loop muted poster={backgroundVideoPoster} className={classes.backgroundVideo}>
        <source src={backgroundVideo} type='video/mp4'/>
      </video>
    </aside>

    <section className={classNames(classes.actionPanel, 'd-flex flex-column align-items-center justify-content-end justify-content-md-center p-2')}>
      <Op36Logo className={classes.op36Logo} />
      <h2 className={classNames(classes.logoText, 'mt-3')}>The #1 Way To Learn To Play Golf</h2>

      <ButtonLink navigateTo='sign-up' className={classNames(classes.actionLink, 'mt-4')}>
        Create an Account
      </ButtonLink>

      <ButtonLink navigateTo='log-in' linkColor='dark-navy' className={classNames(classes.actionLink, 'mt-4 mb-5 mb-md-0')}>
        Log In
      </ButtonLink>
    </section>
  </main>
)

export default WelcomePage
