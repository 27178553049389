import { useDispatch, useSelector } from 'react-redux'
import { eventsDates, sessionsDates, setSelectedDate, setSelectedMonthDate } from '../../../../../reducers/studentDashboardReducer'
import { ReactComponent as ArrowIcon } from '../../../../../assets/images/common/icons/arrow-right.svg'

import ReactCalendar from 'react-calendar'

import 'react-calendar/dist/Calendar.css'
import './Calendar.scss'

const Calendar = () => {
  const dispatch = useDispatch()

  const sessions = useSelector(sessionsDates)
  const events = useSelector(eventsDates)
  const selectedDate = useSelector(state => state.studentDashboard.selectedDate)

  const isSameDay = (a, b) => (
    a.getDate() === b.getDate() && a.getMonth() === b.getMonth() && a.getYear() === b.getYear()
  )

  const tileContent = ({ date, view }) => {
    if (view === 'month') {
      if (sessions.find(dDate => isSameDay(dDate, date)) && events.find(dDate => isSameDay(dDate, date))) {
        return <div className='eventAndProgram'>
          <div className='programDate noMargin'/>
          <div className='eventDate noMargin'/>
        </div>
      }
      if (sessions.find(dDate => isSameDay(dDate, date))) {
        return <div className='programDate'/>
      }
      if (events.find(dDate => isSameDay(dDate, date))) {
        return <div className='eventDate'/>
      }
      return <div className='noProgramDate'/>
    }
  }

  return (
    <div className='dashboardCalendarWrapper'>
      <ReactCalendar
        calendarType='gregory'
        tileContent={tileContent}
        value={selectedDate}
        onChange={(value) => dispatch(setSelectedDate(value))}
        nextLabel={<ArrowIcon className='nextArrow'/>}
        prevLabel={<ArrowIcon className='prevArrow'/>}
        onActiveStartDateChange={({ activeStartDate }) => dispatch(setSelectedMonthDate(activeStartDate))}
      />

      <div className='calendarLegend mr-auto'>
        <div className='d-flex align-items-center'>
          <div className='programDate'/>
          <span>Programs</span>
        </div>

        <div className='d-flex align-items-center'>
          <div className='eventDate'/>
          <span>9 Hole Event Series</span>
        </div>
      </div>
    </div>
  )
}

export default Calendar
