import React from "react";
import Modal from "react-modal";
import Axios from "../../../axios";
import DatePicker from "react-datepicker";
import moment from "moment";
import html2canvas from "html2canvas";
import PropTypes from 'prop-types'
// import ReactPlayer from "react-player";

import BaseModal from "../../common/BaseModal";
import StudentPickerRow from "./StudentPickerRow";
// import PieChart from "./PieChart";
import { Doughnut } from "react-chartjs-2";

import { displayWithCurrency } from '../../../utility/currency'
import placeholderIcon from "../../../assets/images/class-icon.png";
import defaultProfilePic from "../../../assets/images/default_profile.png";
import ohSnap from "../../../assets/images/registration-coach/oh-snap.png";
import logo from "../../../assets/images/logos/logo-hori-white.png";
import dollarIcon from "../../../assets/images/common/icons/dollar-icon.png";
import familyIcon from "../../../assets/images/common/icons/family-icon.png";
import golferIcon from "../../../assets/images/common/icons/golfer-half-icon.png";
import flagIcon from "../../../assets/images/common/icons/Union.png";
import trophyIcon from "../../../assets/images/common/icons/trophy-orange.png";
import statsIcon from "../../../assets/images/common/icons/stats-icon.png";
import clipboardIcon from "../../../assets/images/common/icons/clipboard-orange.png";
import starIcon from "../../../assets/images/common/icons/favorite-icon-opaq.png";
import roundsIcon from "../../../assets/images/op36/op36_play.png";
import trainIcon from "../../../assets/images/op36/op36_train.png";
import socialIcon from "../../../assets/images/op36/op36_social.png";
import clapIcon from "../../../assets/images/common/icons/clap-icon-primary-opaq.png";
import commentIcon from "../../../assets/images/common/icons/comment-icon-primary-opaq.png";

// import DIVISIONS from '../../../constants/divisions.json';

import "./FacilityImpactReport.scss";
import jsPDF from 'jspdf'
import Tooltip from "../../common/Tooltip";

class FacilityImpactReport extends React.Component {
  state = {
    averageFee: "", // input
    merchandise: "", // input
    foodAndBev: "", // input
    averageMembership: "", // input
    initiationFee: "", // input
    membershipPercent: "", // input
    newMemberCount: "", // input
    coachingRevenue: "", // input
    revenueShare: "", // input
    startDate: null, // input
    endDate: null, // input
    recipient: "", // input
    studentModal: false, // for picking students to be include
    studentLoader: true, // initial loader for bringing in students
    totalStudents: [], // from api - don't touch
    selectedStudents: [], // controlls actually adding students to the report
    temporarySelectedStudents: [], //conrolls adding students in the modal
    selectionDisplay: "Students", // controls which subsection you're picking students from on the add student modal
    communityCourses: [], // from api - don't touch
    communityCompletedCourses: [], // from api - don't touch
    completedCourseLoader: true, // initial loader for bringing in completed courses
    courseLoader: true, // initial loader for bringing in courses
    familyLoader: true, // initial loader for bringing in families
    families: [], // from api - don't touch
    reportLoader: false, // loader for when the report is being generated
    report: null, // the returned result from the report endpoint stored here
    reportModal: false, // shows the html version of the report
    selectionFilter: "", // the searchbar on the studentModal
    errorModal: false, // show this when something goes wrong
    pdfLoader: false, // loader for pending pdf generation
  };
  componentDidMount() {
    this._grabStudentsList(this.props.community.id).then(() => {
      this.setState({ studentLoader: false });
    });
    this._grabCourseList(this.props.community.id).then(() => {
      this.setState({ courseLoader: false });
    });
    this._grabCompletedCourseList(this.props.community.id).then(() => {
      this.setState({ completedCourseLoader: false });
    });
    this._grabFamilyList(this.props.community.id).then(() => {
      this.setState({ familyLoader: false });
    });

    if (this.props.community.currency.code === 'usd') {
      this.setState({
        averageFee: this.INDUSTRY_STANDARDS.golf_fee.toFixed(2),
        merchandise: this.INDUSTRY_STANDARDS.merchandise.toFixed(2),
        foodAndBev: this.INDUSTRY_STANDARDS.food_and_bev.toFixed(2),
      });
    }
  }

  INDUSTRY_STANDARDS = {
    golf_fee: 26.74,
    merchandise: 5.4,
    food_and_bev: 17.01,
  };

  render() { // eslint-disable-line max-lines-per-function, complexity
    const { goBack, community, coachName } = this.props;
    const {
      averageFee,
      merchandise,
      foodAndBev,
      averageMembership,
      initiationFee,
      membershipPercent,
      newMemberCount,
      coachingRevenue,
      revenueShare,
      startDate,
      endDate,
      recipient,
      selectionDisplay,
      selectionFilter,
      temporarySelectedStudents,
      selectedStudents,
      studentModal,
      reportLoader,
      reportModal,
      report,
      errorModal,
      pdfLoader,
    } = this.state;

    if (report && reportModal) {
      return (
        <div className='w-100 h-100 pt-3 pb-3 report-display-container'>
          <div className='redownload-bar'>
            <div className='d-flex align-items-center justify-content-center w-50 mx-auto'>
              <button
                className='btn-rectangular btn-rectangular-main'
                onClick={this._convertReportToPDF}
                disabled={pdfLoader}
              >
                {pdfLoader ? (
                  <div className='d-flex align-items-center justify-content-center'>
                    <i
                      className='fa fa-spinner fa-spin mr-2'
                      aria-hidden='true'
                    />
                    Loading Report
                    <i
                      className='fa fa-spinner fa-spin ml-2'
                      aria-hidden='true'
                    />
                  </div>
                ) : (
                  <div className='d-flex align-items-center justify-content-center'>
                    <i className='fa fa-file-pdf-o mr-2' aria-hidden='true' />
                    Download Report
                    <i className='fa fa-file-pdf-o ml-2' aria-hidden='true' />
                  </div>
                )}
              </button>
            </div>
          </div>
          <p
            className='back-to-previous'
            onClick={() => this.setState({ reportModal: false })}
          >
            <i className='fa fa-arrow-left' aria-hidden='true' />
            Go Back
          </p>
          <div id='community-impact-report'>
            <div className='d-flex align-items-center report-top-bar p-2'>
              <div
                className='community-photo'
                style={{ backgroundImage: `url(${community.photo.file_url})` }}
                data-html2canvas-ignore
              />
              <div className='report-id-info'>
                <p className='large-bold'>{community.name}</p>
                <p className='small-bold'>{`Coach: ${coachName}`}</p>
                <p className='sub-text'>Prepared for: {recipient}</p>
              </div>
              <div className='report-timeline-container'>
                <div
                  className='logo'
                  style={{ backgroundImage: `url(${logo})` }}
                />
                <p className='bold'>Program Impact Report</p>
                <p>{`${moment(startDate).format("MMM D, YYYY")} - ${moment(
                  endDate
                ).format("MMM D, YYYY")}`}</p>
              </div>
            </div>
            <div className='white-background d-flex'>
              <div className='w-50 facility-impact-revenue'>
                <h4>
                  <div
                    className='inline-icon'
                    style={{ backgroundImage: `url(${dollarIcon})` }}
                  />
                  Facility Impact (Revenue)
                </h4>
                {this._renderTrackedVisits(report.estimated_visits, [
                  averageFee,
                  merchandise,
                  foodAndBev,
                  averageMembership,
                  membershipPercent,
                  coachingRevenue,
                  revenueShare,
                ])}
                {this._renderInputCalcs(
                  [
                    {
                      title: "Membership Retention",
                      value: this._calculateMembershipRetention(
                        averageMembership,
                        membershipPercent,
                        report.total_families
                      ),
                    },
                    {
                      title: "New Member Revenue",
                      value: this._calculateNewMemberRevenue(
                        averageMembership,
                        newMemberCount,
                        initiationFee
                      ),
                    },
                    {
                      title: "Program Revenue",
                      value:
                        coachingRevenue &&
                        displayWithCurrency(parseInt(coachingRevenue, 10), community.currency),
                    },
                    {
                      title: "Direct Revenue Share",
                      value:
                        revenueShare &&
                        displayWithCurrency(parseInt(revenueShare, 10), community.currency),
                    },
                  ],
                  [
                    {
                      title: "Golf Fees",
                      value:
                        averageFee &&
                        displayWithCurrency(averageFee * report.total_rounds, community.currency, true),
                    },
                    {
                      title: "Merchandise",
                      value:
                        merchandise &&
                        displayWithCurrency(merchandise * report.total_rounds, community.currency, true),
                    },
                    {
                      title: "Food & Beverage",
                      value:
                        foodAndBev &&
                        displayWithCurrency(foodAndBev * report.total_rounds, community.currency, true),
                    },
                  ]
                )}
                {this._renderAssumptions([
                  averageFee,
                  merchandise,
                  foodAndBev,
                  averageMembership,
                  initiationFee,
                  membershipPercent,
                  newMemberCount,
                ])}
              </div>
              <div className='w-50 program-demographics'>
                <h4>
                  <div
                    className='inline-icon'
                    style={{ backgroundImage: `url(${familyIcon})` }}
                  />
                  Program Demographics
                </h4>
                <div className='d-flex align-items-center justify-content-center mt-2'>
                  <p className='orange-text med-num mx-1'>
                    {report.total_families}
                  </p>
                  <p className='sub-text mr-2'>Total Families</p>
                  <p className='orange-text med-num mx-1'>
                    {report.total_golfers}
                  </p>
                  <p className='sub-text'>Total Golfers</p>
                </div>
                <div className='percent-bar-container'>
                  <div
                    className='junior-bar'
                    style={{
                      flex: `0 0 ${(parseFloat(report.juniors.count) /
                        report.total_golfers) *
                        100
                        }%`,
                    }}
                  />
                  <div className='leftover-bar' />
                  <div
                    className='adult-bar'
                    style={{
                      flex: `0 0 ${(parseFloat(report.adults.count) /
                        report.total_golfers) *
                        100
                        }%`,
                    }}
                  />
                </div>
                <div className='d-flex'>
                  <div className='w-50 junior-container'>
                    <p className='demographic-title jnr-demo-title'>
                      {"Juniors (Age < 18)"}
                    </p>
                    <p className='jnr-percentage'>
                      {(
                        (parseFloat(report.juniors.count) /
                          report.total_golfers) *
                        100
                      ).toFixed(1)}
                      %{" "}
                    </p>
                    <div className='chart-box jnr-chart-box'>
                      <Doughnut
                        data={{
                          labels: [
                            "Female",
                            "Male",
                            "Other",
                            "Prefer not to say",
                          ],
                          datasets: [
                            {
                              data: [
                                report.juniors.female_percentage,
                                report.juniors.male_percentage,
                                report.juniors.other_gender_percentage,
                                1 -
                                report.juniors.female_percentage -
                                report.juniors.male_percentage -
                                report.juniors.other_gender_percentage,
                              ],
                              backgroundColor: [
                                "#0E7CAC",
                                "#34ACE0",
                                "#79d1f7",
                                "#AFB7BD",
                              ],
                            },
                          ],
                        }}
                        height={82}
                        width={82}
                        options={{
                          maintainAspectRatio: false,
                          plugins: {
                            legend: { display: false },
                            tooltip: {
                              callbacks: {
                                label: (context) => (
                                  `${context.label} : ${(context.raw * 100).toFixed(0)}%`
                                ),
                              },
                            },
                          },
                        }}
                      />
                      <div className='demo-breakdown'>
                        <p className='jnr-female'>
                          Female <br />(
                          {parseInt(report.juniors.female_percentage * 100, 10)}
                          %)
                        </p>
                        <p className='jnr-male'>
                          Male <br />(
                          {parseInt(report.juniors.male_percentage * 100, 10)}%)
                        </p>
                        <p className='jnr-other'>
                          Other <br />(
                          {parseInt(
                            report.juniors.other_gender_percentage * 100,
                            10
                          )}
                          %)
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='w-50 adult-container'>
                    <p className='demographic-title adt-demo-title'>
                      {"Adults (Age 18+)"}
                    </p>
                    <p className='adt-percentage'>
                      {(
                        (parseFloat(report.adults.count) /
                          report.total_golfers) *
                        100
                      ).toFixed(1)}
                      %{" "}
                    </p>
                    <div className='chart-box adt-chart-box'>
                      <Doughnut
                        data={{
                          labels: [
                            "Female",
                            "Male",
                            "Other",
                            "Prefer not to say",
                          ],
                          datasets: [
                            {
                              data: [
                                report.adults.female_percentage,
                                report.adults.male_percentage,
                                report.adults.other_gender_percentage,
                                1 -
                                report.adults.female_percentage -
                                report.adults.male_percentage -
                                report.adults.other_gender_percentage,
                              ],
                              backgroundColor: [
                                "#12A482",
                                "#33D9B2",
                                "#86eed5",
                                "#AFB7BD",
                              ],
                            },
                          ],
                        }}
                        height={82}
                        width={82}
                        options={{
                          maintainAspectRatio: false,
                          plugins: {
                            legend: { display: false },
                            tooltip: {
                              callbacks: {
                                label: (context) => (
                                  `${context.label} : ${(parseInt(context.raw * 100, 10))}%`
                                ),
                              },
                            },
                          }
                        }}
                      />
                      <div className='demo-breakdown'>
                        <p className='adt-female'>
                          Female <br />(
                          {parseInt(report.adults.female_percentage * 100, 10)}
                          %)
                        </p>
                        <p className='adt-male'>
                          Male <br />(
                          {parseInt(report.adults.male_percentage * 100, 10)}%)
                        </p>
                        <p className='adt-other'>
                          Other <br />(
                          {parseInt(
                            report.adults.other_gender_percentage * 100,
                            10
                          )}
                          %)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='dark-background d-flex'>
              <div className='w-50 facility-impact-improvement'>
                <h4>
                  <div
                    className='inline-icon'
                    style={{ backgroundImage: `url(${golferIcon})` }}
                  />
                  Player Improvement
                </h4>
                <p className='white-text'>
                  When players work towards goals and see improvement, it
                  increases engagement in the sport, which increases facility
                  usage and revenue.
                </p>
                <div className='tri-line-container'>
                  <div className='improvement-box'>
                    <div className='d-flex align-items-center justify-content-center'>
                      <div
                        className='improvement-icon'
                        style={{ backgroundImage: `url(${trophyIcon})` }}
                      />
                      <p className='improvement-number'>
                        {this._formatNum(report.milestone_count)}
                      </p>
                    </div>
                    <p className='improvement-title'>Operation 36 Milestones</p>
                  </div>
                  <div className='improvement-box'>
                    <div className='d-flex align-items-center justify-content-center'>
                      <div
                        className='improvement-icon'
                        style={{ backgroundImage: `url(${statsIcon})` }}
                      />
                      <p className='improvement-number'>
                        {this._formatNum(report.strokes_lost)}
                      </p>
                    </div>
                    <p className='improvement-title'>Strokes Lost</p>
                  </div>
                  <div className='improvement-box'>
                    <div className='d-flex align-items-center justify-content-center'>
                      <div
                        className='improvement-icon'
                        style={{ backgroundImage: `url(${clipboardIcon})` }}
                      />
                      <p className='improvement-number'>
                        {this._formatNum(report.completed_objectives)}
                      </p>
                    </div>
                    <p className='improvement-title'>Objectives Completed</p>
                  </div>
                </div>
                <div className='large-improvement-box'>
                  <div
                    className='star-icon'
                    style={{ backgroundImage: `url(${starIcon})` }}
                  />
                  <div className='inner-box'>
                    <p className='improvement-number'>
                      {this._formatNum(report.total_points_earned)}
                    </p>
                    <p className='improvement-title'>
                      Total Points Earned for Our Facility
                    </p>
                  </div>
                  <div
                    className='star-icon'
                    style={{ backgroundImage: `url(${starIcon})` }}
                  />
                </div>
              </div>
              <div className='w-50 facility-impact-engagement'>
                <h4>
                  <div
                    className='inline-icon'
                    style={{ backgroundImage: `url(${flagIcon})` }}
                  />
                  {"Activities & Engagement"}
                </h4>
                <p className='white-text'>
                  Our programs and technology engage communities of golfers
                  inside and outside of classes.
                </p>
                <div className='white-box'>
                  <div className='d-flex'>
                    <div className='half-flex total-golfers'>
                      <p className='total-golfers-num'>
                        {this._formatNum(report.total_golfer_actions)}
                      </p>
                      <p className='total-golfers-title'>
                        Total Golfer Actions
                      </p>
                    </div>
                    <div className='half-flex claps-container'>
                      <div className='d-flex align-items-center'>
                        <div
                          className='ir-icon orange-icon'
                          style={{ backgroundImage: `url(${clapIcon})` }}
                        />
                        <p className='orange-text'>
                          <span className='bold-num'>
                            {this._formatNum(report.golf_claps)}
                          </span>{" "}
                          Golf Claps
                        </p>
                      </div>
                      <div className='d-flex align-items-center'>
                        <div
                          className='ir-icon orange-icon'
                          style={{ backgroundImage: `url(${commentIcon})` }}
                        />
                        <p className='orange-text'>
                          <span className='bold-num'>
                            {this._formatNum(report.comments)}
                          </span>{" "}
                          Comments
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <div className='tri-flex rounds-container'>
                      <div className='d-flex align-items-center justify-content-center'>
                        <div
                          className='ir-icon'
                          style={{
                            backgroundImage: `url(${roundsIcon})`,
                            opacity: 0.5,
                          }}
                        />
                        <p className='bold-num'>
                          {this._formatNum(report.total_rounds)}
                        </p>
                      </div>
                      <p className='engagement-title'>Rounds of Golf</p>
                      <p className='engagement-sublist'>{`- ${this._formatNum(
                        report.formal_rounds
                      )} Formal`}</p>
                      <p className='engagement-sublist'>{`- ${this._formatNum(
                        report.casual_rounds
                      )} Casual`}</p>
                    </div>
                    <div className='tri-flex sessions-container'>
                      <div className='d-flex align-items-center justify-content-center'>
                        <div
                          className='ir-icon'
                          style={{
                            backgroundImage: `url(${trainIcon})`,
                            opacity: 0.5,
                          }}
                        />
                        <p className='bold-num'>
                          {this._formatNum(report.total_training_sessions)}
                        </p>
                      </div>
                      <p className='engagement-title'>Training Sessions</p>
                      <p className='engagement-sublist'>{`- ${this._formatDuration(
                        report.total_training_duration
                      )}`}</p>
                    </div>
                    <div className='tri-flex social-container'>
                      <div className='d-flex align-items-center justify-content-center'>
                        <div
                          className='ir-icon'
                          style={{
                            backgroundImage: `url(${socialIcon})`,
                            opacity: 0.5,
                          }}
                        />
                        <p className='bold-num'>
                          {this._formatNum(report.total_social_actions)}
                        </p>
                      </div>
                      <p className='engagement-title'>Social Actions</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this._renderStudentReport()}
        </div>
      );
    }
    return (
      <div id='FacilityImpactReport'>
        {reportLoader && (
          <BaseModal
            close={() => false}
            backable={false}
            hideClose={true}
            width='350px'
          >
            <div id='impact-report-loading-modal'>
              <i className='fa fa-spinner fa-spin' aria-hidden='true' />
              <p className='main-text text-center'>Generating report...</p>
              <p className='sub-text text-center'>
                This report may take up to 30 seconds to generate. Please be
                patient!
              </p>
            </div>
          </BaseModal>
        )}
        {errorModal && (
          <BaseModal
            close={() => false}
            backable={false}
            hideClose={true}
            width='350px'
          >
            <div id='impact-report-error-modal'>
              <div
                className='oh-snap'
                style={{ backgroundImage: `url(${ohSnap})` }}
              />
              <p className='main-text text-center'>Something went wrong.</p>
              <p className='sub-text text-center'>
                Uh oh. We weren't able to generate your report at this time.
                Please try again later.
              </p>
              <button
                className='btn-rectangular btn-rectangular-main'
                onClick={() => this.setState({ errorModal: false })}
              >
                Close
              </button>
            </div>
          </BaseModal>
        )}
        <div className='impact-top-bar'>
          <i className='fa fa-arrow-left' aria-hidden='true' onClick={goBack} />
          <div className='top-bar-title-container'>
            <h3>Facility Impact Report</h3>
            <p className='title-description'>
              Show your leadership how Operation 36 programming is positively
              impacting your golf facility!
            </p>
          </div>
          <div className='impact-video-trigger-container'>
            <i className='fa fa-play-circle-o' aria-hidden='true' />
            <p>Need help? Watch this tutorial!</p>
          </div>
        </div>
        <div className='d-flex impact-report-main-data'>
          <div className='input-area'>
            <Tooltip multiline className='all-tooltips' html={true} place='right' />
            <h4 className='text-center'>Step 1: Enter details below:</h4>
            {/* <div className='video-container'>
              <ReactPlayer
                height='100%'
                width='100%'
                url='https://youtu.be/0jJ3X5nvBB8'
                controls
              />
            </div> */}
            <p className='label mt-3'>
              Date range of activities (up to one year):
              <span className='orange-text required-field'>*</span>
            </p>
            <div className='date-range-container'>
              <DatePicker
                startDate={startDate}
                endDate={endDate}
                selected={startDate}
                fixedHeight
                onChange={(date) => this._handleDate("startDate", date)}
                placeholderText='Start date'
              />
              <p className='mb-0'>-</p>
              <DatePicker
                startDate={startDate}
                endDate={endDate}
                selected={endDate}
                fixedHeight
                onChange={(date) => this._handleDate("endDate", date)}
                placeholderText='End date'
              />
            </div>
            {startDate &&
              endDate &&
              this._isOverOneYear(startDate, endDate) && (
                <p className='error-text'>
                  Please make sure your dates are at most one year apart.
                </p>
              )}
            {startDate &&
              endDate &&
              this._datesOutOfOrder(startDate, endDate) && (
                <p className='error-text'>
                  Your start date is after your end date???
                </p>
              )}
            <div className='inline-full-input-container'>
              <label htmlFor='recipient' className='mt-2 mb-0'>
                Recipient of report:
                <span className='orange-text required-field'>*</span>
              </label>
              <input
                type='text'
                id='recipient'
                name='recipient'
                value={recipient}
                placeholder='Name of GM, owner, etc.'
                onChange={this._onInputChange}
              />
            </div>
            <hr />
            <div className='input-section-title-box'>
              <p>Average Spending Per Round</p>
              <i
                className='fa fa-question-circle-o'
                aria-hidden='true'
                data-tip={`<div class='interior-tip'>
                    <p class='bold orange'>Average Spending Per Round</p>
                    <p>
                      This section of the report will calculate the total amount
                      of rounds logged by
                      your golfers in casual and formal events in the timeframe
                      you set.
                    </p>
                    <p>
                      If you know your facility's specific average spending per
                      round in these 3 categories, you can plug them in here.
                      This will give your leadership an accurate estimate on
                      potential revenue generated per round through your
                      program. The default median industry values we provide are
                      defined below and can be used at your leisure.
                    </p>
                    <p>
                      If you do not want to present any of the values on your
                      report, you can delete any that are not relevant and they will not show up on the
                      report.
                    </p>
                  </div>`}
              />
            </div>
            <p className='industry-standards'>
              {community.currency.code === 'usd'
                ? <>
                    Fields are filled with the 2012 PGA PerformanceTrak industry medians (
                  </>
                : <>
                    Please refer to the 2012 PGA PerformanceTrak industry
                    medians in USD when filling these values in {community.currency.code.toUpperCase()} (
                  </>
              }
              <span className='orange-text'>
                {displayWithCurrency(this.INDUSTRY_STANDARDS.golf_fee, { code: 'usd' })}
              </span>{" "}
              golf fees,{" "}
              <span className='orange-text'>
                {displayWithCurrency(this.INDUSTRY_STANDARDS.merchandise, { code: 'usd' })}
              </span>{" "}
              merchandise, and{" "}
              <span className='orange-text'>
                {displayWithCurrency(this.INDUSTRY_STANDARDS.food_and_bev, { code: 'usd' })}
              </span>{" "}
              food and beverage).
            </p>
            <div className='inline-dashed-input-container'>
              <p>Golf fees (greens + cart):</p>
              <div className='dash-box' />
              <div className='currency-input'>
                <span>{community.currency.symbol}</span>
                <input
                  type='number'
                  id='fee'
                  name='averageFee'
                  value={averageFee}
                  onWheel={(e) => e.target.blur()}
                  onChange={this._onInputChange}
                  onKeyDown={this._noNegative}
                />
              </div>
            </div>
            <div className='inline-dashed-input-container'>
              <p>Merchandise:</p>
              <div className='dash-box' />
              <span className='currency-input'>
                <span>{community.currency.symbol}</span>
                <input
                  type='number'
                  id='fee'
                  name='merchandise'
                  value={merchandise}
                  onWheel={(e) => e.target.blur()}
                  onChange={this._onInputChange}
                  onKeyDown={this._noNegative}
                />
              </span>
            </div>
            <div className='inline-dashed-input-container'>
              <p>Food and Beverage:</p>
              <div className='dash-box' />
              <span className='currency-input'>
                <span>{community.currency.symbol}</span>
                <input
                  type='number'
                  id='fee'
                  name='foodAndBev'
                  value={foodAndBev}
                  onWheel={(e) => e.target.blur()}
                  onChange={this._onInputChange}
                  onKeyDown={this._noNegative}
                />
              </span>
            </div>
            <hr />
            <div className='input-section-title-box'>
              <p>Private/Semi-Private Club Metrics</p>
              <i
                className='fa fa-question-circle-o'
                aria-hidden='true'
                data-tip={`<div class='interior-tip'>
                    <p class='bold orange'>Private/Semi-Private Club Metrics</p>
                    <p>
                      <span class='bold'>Membership Retention </span>
                      - If your golf club has memberships, and those members are in your program, you play a key role in helping retain those members at the golf club. It is proven that when players are taking instruction consistently, they are more connected to the club and they spend more. Even better, you are providing an environment for them to play consistently as well which makes you even more valuable.
                    </p>
                    <p>
                    If you put in the total average annual membership and the percentage of your participants that are members, you will get the total membership $ that you help retain at the club on an annual basis.  This is based on the number of family club members in your program, multiplied by the total annual membership.
                    </p>
                    <p>
                      <span class='bold'>New Member Revenue </span>
                      - It is also extremely valuable to show if you helped generate new memberships for the club.
                    </p>
                    <p>
                    If you have someone who joined the club explicitly for your program, a participant who became a member, or you had a new member of the club join your program right away, we would tally all of those families and input that number in the "Total New Members In Program".
                    </p>
                    <p>
                    This will take that family count and multiply it by your average annual membership fee to show the new revenue you helped bring to the club.
                    </p>
                  </div>`}
              />
            </div>
            <div className='inline-dashed-input-container'>
              <p>Avg. Annual Membership:</p>
              <div className='dash-box' />
              <span className='currency-input'>
                <span>{community.currency.symbol}</span>
                <input
                  type='number'
                  id='fee'
                  name='averageMembership'
                  value={averageMembership}
                  onWheel={(e) => e.target.blur()}
                  onChange={this._onInputChange}
                  onKeyDown={this._noNegative}
                />
              </span>
            </div>
            <div className='inline-dashed-input-container'>
              <p>New Member Initiation Fee:</p>
              <div className='dash-box' />
              <span className='currency-input'>
                <span>{community.currency.symbol}</span>
                <input
                  type='number'
                  id='fee'
                  name='initiationFee'
                  value={initiationFee}
                  onWheel={(e) => e.target.blur()}
                  onChange={this._onInputChange}
                  onKeyDown={this._noNegative}
                />
              </span>
            </div>
            <div className='inline-dashed-input-container'>
              <p>Percent of Families That Are Members:</p>
              <div className='dash-box' />
              <span className='percent-input'>
                <input
                  type='number'
                  id='fee'
                  name='membershipPercent'
                  value={membershipPercent}
                  onWheel={(e) => e.target.blur()}
                  onChange={this._onInputChange}
                  onKeyDown={this._percentInput}
                  onBlur={this._defaultToOneHundred}
                />
                <span>%</span>
              </span>
            </div>
            <div className='inline-dashed-input-container'>
              <p>Total New Members in Program:</p>
              <div className='dash-box' />
              <input
                type='number'
                id='fee'
                name='newMemberCount'
                value={newMemberCount}
                onWheel={(e) => e.target.blur()}
                onChange={this._onInputChange}
                onKeyDown={this._noNegative}
              />
            </div>
            <hr />
            <div className='input-section-title-box'>
              <p>Other Details</p>
              <i
                className='fa fa-question-circle-o'
                aria-hidden='true'
                data-tip={`<div class='interior-tip'>
                    <p class='bold orange'>Other Details</p>
                    <p>
                      <span class='bold'>Program Revenue </span>
                      - If it is important to your leadership to see the Operation 36 Program Revenue that you generated, you can add it here to add to the report. If you do not add it, it will not be added to the report.
                    </p>
                    <p>
                      <span class='bold'>Direct Revenue Share </span>
                      - If you pay your golf facility a monthly lease or a direct revenue share for rounds or a percentage of your coaching revenue you can input it here. This is a great way for you to compare that number and the estimates to negotiate a better rate in the future.
                    </p>
                  </div>`}
              />
            </div>
            <div className='inline-dashed-input-container'>
              <p>Program Revenue:</p>
              <div className='dash-box' />
              <span className='currency-input'>
                <span>{community.currency.symbol}</span>
                <input
                  type='number'
                  id='fee'
                  name='coachingRevenue'
                  value={coachingRevenue}
                  onWheel={(e) => e.target.blur()}
                  onChange={this._onInputChange}
                  onKeyDown={this._noNegative}
                />
              </span>
            </div>
            <div className='inline-dashed-input-container'>
              <p>Direct Revenue Share:</p>
              <div className='dash-box' />
              <span className='currency-input'>
                <span>{community.currency.symbol}</span>
                <input
                  type='number'
                  id='fee'
                  name='revenueShare'
                  value={revenueShare}
                  onWheel={(e) => e.target.blur()}
                  onChange={this._onInputChange}
                  onKeyDown={this._noNegative}
                />
              </span>
            </div>
          </div>
          <div className='student-section'>
            <h4>Step 2: Select students to include in reporting:</h4>
            {selectedStudents.length === 0 ? (
              <div className='student-placeholder-container'>
                <div
                  className='placeholder-icon'
                  style={{ backgroundImage: `url(${placeholderIcon})` }}
                />
                <p className='bold'>No students selected yet.</p>
                <p>
                  Please select the students to include in this impact report.
                </p>
                <div>
                  <button
                    className='btn-rectangular btn-rectangular-main-positive'
                    onClick={this._openStudentModal}
                  >
                    + Select students to include
                  </button>
                </div>
              </div>
            ) : (
              <div className='student-display-section'>
                <div className='d-flex'>
                  <h4>{`Selected students (${selectedStudents.length})`}</h4>
                  <div
                    className='d-flex align-items-center edit-button ml-5'
                    onClick={this._openStudentModal}
                  >
                    <i
                      className='fa fa-pencil-square-o mr-2'
                      aria-hidden='true'
                    />
                    <p>Edit Selection</p>
                  </div>
                </div>
                <div className='student-row-box'>
                  {selectedStudents.map((student) => {
                    return (
                      <StudentPickerRow
                        key={student.id}
                        student={student}
                        onClick={() => false}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='impact-report-bottom-bar'>
          {!!report ? (
            <button
              className='btn-rectangular btn-rectangular-main-positive'
              onClick={this._reopenReport}
            >
              View your report
            </button>
          ) : (
            <button
              className='btn-rectangular btn-rectangular-main-positive grab-report-button'
              disabled={this._checkValidation()}
              onClick={this._grabReportData}
            >
              Step 3: Generate your report!
            </button>
          )}
        </div>
        <Modal
          isOpen={studentModal}
          contentLabel='Add Student Modal'
          shouldCloseOnOverlayClick
          className='customModal'
        >
          <div id='add-student-to-report-modal'>
            <div className='modal-header'>
              <h5 className='modal-title display-4'>Select students</h5>
              <div className='col-1'>
                <button
                  type='button'
                  className='close pull-right font-200'
                  data-dismiss='modal'
                  aria-label='Close'
                  onClick={this._closeUnsavedStudentModal}
                >
                  <span aria-hidden='true'>✕</span>
                </button>
              </div>
            </div>
            <div className='student-modal-content'>
              <div className='selector-section'>
                <div className='top-selector-bar'>
                  <div
                    className='selection-container'
                    onClick={() => this._switchDisplay("Students")}
                  >
                    <p
                      className={`${selectionDisplay === "Students" ? "isActive" : ""
                        }`}
                    >
                      STUDENTS
                    </p>
                  </div>
                  <div
                    className='selection-container'
                    onClick={() => this._switchDisplay("Families")}
                  >
                    <p
                      className={`${selectionDisplay === "Families" ? "isActive" : ""
                        }`}
                    >
                      FAMILIES
                    </p>
                  </div>
                  <div
                    className='selection-container'
                    onClick={() => this._switchDisplay("Active Classes")}
                  >
                    <p
                      className={`${selectionDisplay === "Active Classes" ? "isActive" : ""
                        }`}
                    >
                      ACTIVE CLASSES
                    </p>
                  </div>
                  <div
                    className='selection-container'
                    onClick={() => this._switchDisplay("Completed Classes")}
                  >
                    <p
                      className={`${selectionDisplay === "Completed Classes"
                        ? "isActive"
                        : ""
                        }`}
                    >
                      COMPLETED CLASSES
                    </p>
                  </div>
                  <div
                    className='selection-indicator'
                    style={this._grabLeftCoordinate()}
                  />
                </div>
                <div className='d-flex align-items-center justify-content-between mt-5'>
                  <h4>{selectionDisplay}</h4>
                  <div className='icon-search-bar'>
                    <i className='fa fa-search' aria-hidden='true' />
                    <input
                      name='selectionFilter'
                      onChange={this._onInputChange}
                      value={selectionFilter}
                      placeholder={`Search ${selectionDisplay}`}
                      disabled={this._checkSearchLoading(selectionDisplay)}
                    />
                  </div>
                </div>
                <div className='picker-selection-display'>
                  {this._renderPicker()}
                </div>
              </div>
              {temporarySelectedStudents.length < 1 ? (
                <div className='placeholder-container d-flex align-items-center justify-content-center flex-column'>
                  <div
                    className='placeholder-icon'
                    style={{ backgroundImage: `url(${placeholderIcon})` }}
                  />
                  <p className='bold'>No students selected yet</p>
                  <p>
                    Please select the students to include in this impact report
                    by selecting classes, families, or individual students.
                  </p>
                </div>
              ) : (
                <div className='student-display-section'>
                  <div className='inner-display'>
                    <p className='bold section-title'>{`Selected students (${temporarySelectedStudents.length})`}</p>
                    <div className='student-box'>
                      {temporarySelectedStudents.map((student) => {
                        return (
                          <StudentPickerRow
                            key={student.id}
                            student={student}
                            clickable
                            onClick={this._removeStudentFromTemporary}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-8 mx-auto'>
              <button
                className='btn-rectangular btn-rectangular-main'
                onClick={this._closeSavedStudentModal}
              >
                Confirm selection
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  _calculateMembershipRetention = (
    averageMembership,
    membershipPercent,
    total_families
  ) => {
    if (!averageMembership || !membershipPercent) {
      return false;
    }
    let dollarVal =
      (averageMembership * membershipPercent * total_families) / 100;
    return displayWithCurrency(parseInt(dollarVal, 10), this.props.community.currency);
  };

  _calculateNewMemberRevenue = (
    averageMembership,
    newMemberCount,
    initiationFee
  ) => {
    if (!newMemberCount || newMemberCount === "0") {
      return false;
    }
    let membershipCheck = averageMembership || 0;
    let initiationCheck = initiationFee || 0;
    let dollarVal =
      membershipCheck * newMemberCount + initiationCheck * newMemberCount;
    return displayWithCurrency(parseInt(dollarVal, 10), this.props.community.currency);
  };

  _convertReportToPDF = () => {
    this.setState({ pdfLoader: true }, () => {
      const report = document.getElementById("community-impact-report");
      const studentPages = document.querySelectorAll(".StudentReport");
      const total_pages = studentPages.length;
      let pageLoadCount = 0;
      html2canvas(report).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        // const pdf = new window.jspdf.jsPDF("l", "in", [11, 8], true);
        const pdf = new jsPDF({
          orientation: 'l',
          unit: 'in',
          format: [11, 8],
          compress: true
        })
        pdf.addImage(imgData, "PNG", 0, 0, 11, 8, "", "FAST");
        studentPages.forEach((page, idx) => {
          html2canvas(page, {
            // onclone: function (cloned) {
            //   cloned
            //     .getElementsByClassName("StudentReport")
            //     [idx].classList.add("pt-5");
            // },
          }).then((canvas2) => {
            pdf.addPage([11, 8]);
            const imgData2 = canvas2.toDataURL("image/png");
            pdf.addImage(imgData2, "PNG", 0, 0, 11, 8, "", "FAST");
            pageLoadCount++;
            if (pageLoadCount === total_pages) {
              pdf.save("impact_report.pdf");
              this.setState({ pdfLoader: false });
            }
          });
        });
      });
    });
  };

  _grabStudentsList = async (community_id) => {
    let response = await Axios.get(
      `/api/v1/communities/${community_id}/students`
    );
    this.setState({ totalStudents: response.data.students });
  };

  _grabCourseList = async (community_id) => {
    let response = await Axios.get(
      `/api/v1/communities/${community_id}/coach_courses`
    );
    this.setState({ communityCourses: response.data.courses });
  };

  _grabCompletedCourseList = async (community_id) => {
    let response = await Axios.get(
      `/api/v1/communities/${community_id}/completed_courses`
    );
    this.setState({ communityCompletedCourses: response.data.courses });
  };

  _grabFamilyList = async (community_id) => {
    let response = await Axios.get(
      `/api/v1/communities/${community_id}/families`
    );
    this.setState({ families: response.data.families });
  };

  _grabReportData = () => {
    this.setState({ reportLoader: true }, async () => {
      const { selectedStudents, startDate, endDate } = this.state;
      try {
        const requestUrl = '/api/v1/reports/community_impact_report'

        const body = {
          start_date: startDate,
          end_date: endDate,
          user_ids: selectedStudents.map(s => s.id),
        }

        const response = await Axios.post(requestUrl, body)
        this.setState({ report: response.data }, () => {
          this.setState(
            { reportLoader: false, reportModal: true, pdfLoader: true },
            () => {
              setTimeout(this._convertReportToPDF, 1000);
            }
          );
        });
      } catch (err) {
        console.error(err)
        this.setState({ reportLoader: false, errorModal: true });
      }
    });
  };

  _percentInput = (event) => {
    if (event.key === "." || event.key === "-") {
      event.preventDefault();
    }
  };

  _noNegative = (event) => {
    if (event.key === "-") {
      event.preventDefault();
    }
  };

  _defaultToOneHundred = (e) => {
    if (parseInt(e.target.value, 10) > 100) {
      this.setState({ [e.target.name]: 100 });
    }
  };

  _onInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "membershipPercent" && value.length > 3) {
      return false;
    }
    this.setState({ [name]: value });
  };

  _handleDate = (type, date) => {
    this.setState((prevState) => {
      let obj = { [type]: date };
      if (prevState.report) obj["report"] = null;
      return obj;
    });
  };

  _switchDisplay = (val) => {
    const { selectionDisplay } = this.state;
    if (val === selectionDisplay) return false;
    this.setState({ selectionFilter: "" }, () => {
      this.setState({ selectionDisplay: val });
    });
  };

  _reopenReport = () => {
    this.setState({ reportModal: true });
  };

  _openStudentModal = () => {
    this.setState({ studentModal: true });
  };

  _closeUnsavedStudentModal = () => {
    const { selectedStudents } = this.state;
    this.setState({ temporarySelectedStudents: [...selectedStudents] }, () => {
      this.setState({ studentModal: false });
    });
  };

  _closeSavedStudentModal = () => {
    const { temporarySelectedStudents, selectedStudents, report } = this.state;
    let obj = { selectedStudents: [...temporarySelectedStudents] };
    if (
      report &&
      this._checkNotSame(temporarySelectedStudents, selectedStudents)
    ) {
      obj["report"] = null;
    }
    this.setState(obj, () => {
      this.setState({ studentModal: false });
    });
  };

  _checkValidation = () => {
    //return true to fail validation and false to pass
    const { startDate, endDate, recipient, selectedStudents } = this.state;
    if (!startDate) return true;
    if (!endDate) return true;
    // if (this._isOverOneYear(startDate, endDate)) return true;
    if (this._datesOutOfOrder(startDate, endDate)) return true;
    if (!recipient) return true;
    if (selectedStudents.length < 1) return true;
    return false;
  };

  _renderPicker = () => {
    const { selectionDisplay, selectionFilter } = this.state;
    switch (selectionDisplay) {
      case "Active Classes":
        return this._renderClasses(selectionFilter);
      case "Completed Classes":
        return this._renderCompletedClasses(selectionFilter);
      case "Families":
        return this._renderFamilies(selectionFilter);
      case "Students":
        return this._renderStudents(selectionFilter);
      default:
        return null;
    }
  };

  _renderClasses = (filter) => {
    const { courseLoader, communityCourses } = this.state;
    if (courseLoader) {
      return (
        <div className='d-flex flex-column align-items-center justify-content-center pt-3'>
          <i className='fa fa-spinner fa-spin' aria-hidden='true' />
          <p className='mt-3'>Loading Active Classes</p>
        </div>
      );
    }
    if (communityCourses.length < 1) {
      return (
        <div className='d-flex justify-content-center mt-3'>
          <p>looks like you don't have any active classes right now</p>
        </div>
      );
    }
    return communityCourses
      .filter((c) => {
        if (!filter || filter.split("").every((char) => char === " "))
          return true;
        return c.name.toLowerCase().includes(filter.trim().toLowerCase());
      })
      .map((course) => {
        const alreadySelected = this._checkAlreadySelected(
          course.enrollments
            .filter((e) => e.status === "active")
            .map((e) => e.user)
        );
        const enrollmentCount = course.enrollments.filter(
          (e) => e.status === "active"
        ).length;
        return (
          <div
            key={course.id}
            className={`class-row ${alreadySelected || enrollmentCount === 0 ? "" : "clickable"
              }`}
            onClick={() =>
              this._addStudentArray(
                this._pullStudentsFromCourse(course, "active")
              )
            }
          >
            <div
              className='class-icon'
              style={{
                backgroundImage: `url(${course.photo
                  ? course.photo.file_url
                  : course.lesson_plan && course.lesson_plan.photo
                    ? course.lesson_plan.photo.file_url
                    : placeholderIcon
                  })`,
              }}
            />
            <div className='name-area'>
              <p className='name'>{course.name}</p>
              <p className='meeting-times'>
                {course.meeting_times}{" "}
                <span className='#ff6633'>{`${enrollmentCount} student${enrollmentCount === 1 ? '' : 's'
                  }`}</span>
              </p>
            </div>
            {alreadySelected ? (
              <i className='fa fa-check-circle' aria-hidden='true' />
            ) : (
              <i className='fa fa-plus' aria-hidden='true' />
            )}
          </div>
        );
      });
  };

  _renderCompletedClasses = (filter) => {
    const { completedCourseLoader, communityCompletedCourses } = this.state;
    if (completedCourseLoader) {
      return (
        <div className='d-flex flex-column align-items-center justify-content-center pt-3'>
          <i className='fa fa-spinner fa-spin' aria-hidden='true' />
          <p className='mt-3'>Loading Completed Classes</p>
        </div>
      );
    }
    if (communityCompletedCourses.length < 1) {
      return (
        <div className='d-flex justify-content-center mt-3'>
          <p>looks like you don't have any completed classes yet</p>
        </div>
      );
    }
    return communityCompletedCourses
      .filter((c) => {
        if (!filter || filter.split("").every((char) => char === " "))
          return true;
        return c.name.toLowerCase().includes(filter.trim().toLowerCase());
      })
      .map((course) => {
        const alreadySelected = this._checkAlreadySelected(
          course.enrollments
            .filter((e) => e.status === "completed")
            .map((e) => e.user)
        );
        const enrollmentCount = course.enrollments.filter(
          (e) => e.status === "completed"
        ).length;
        return (
          <div
            key={course.id}
            className={`class-row ${alreadySelected || enrollmentCount === 0 ? "" : "clickable"
              }`}
            onClick={() =>
              this._addStudentArray(
                this._pullStudentsFromCourse(course, "completed")
              )
            }
          >
            <div
              className='class-icon'
              style={{
                backgroundImage: `url(${course.photo
                  ? course.photo.file_url
                  : course.lesson_plan && course.lesson_plan.photo
                    ? course.lesson_plan.photo.file_url
                    : placeholderIcon
                  })`,
              }}
            />
            <div className='name-area'>
              <p className='name'>
                {course.name}{" "}
                <span className='completed-range'>{`(${moment(
                  course.start_date
                ).format("L")} - ${moment(course.end_date).format(
                  "L"
                )})`}</span>
              </p>
              <p className='meeting-times'>
                {course.meeting_times}{" "}
                <span className='#ff6633'>{`${enrollmentCount} student${enrollmentCount === 1 ? '' : 's'
                  }`}</span>
              </p>
            </div>
            {alreadySelected ? (
              <i className='fa fa-check-circle' aria-hidden='true' />
            ) : (
              <i className='fa fa-plus' aria-hidden='true' />
            )}
          </div>
        );
      });
  };

  _renderFamilies = (filter) => {
    const { familyLoader, families } = this.state;
    if (familyLoader) {
      return (
        <div className='d-flex flex-column align-items-center justify-content-center pt-3'>
          <i className='fa fa-spinner fa-spin' aria-hidden='true' />
          <p className='mt-3'>Loading Families</p>
        </div>
      );
    }
    if (families.lentgh < 1) {
      return (
        <div className='d-flex justify-content-center mt-3'>
          <p>We can't find any families in your community</p>
        </div>
      );
    }
    return families
      .filter((f) => {
        if (!filter || filter.split("").every((char) => char === " "))
          return true;
        return f.name.toLowerCase().includes(filter.trim().toLowerCase());
      })
      .map((family) => {
        const studentCount = family.users.filter((u) => u.is_golfer).length;
        const alreadySelected = this._checkAlreadySelected(
          family.users.filter((u) => u.is_golfer)
        );
        return (
          <div
            key={family.id}
            className={`family-row1 ${alreadySelected || studentCount === 0 ? "" : "clickable"
              }`}
            onClick={() =>
              this._addStudentArray(this._pullStudentsFromFamily(family))
            }
          >
            <i className='fa fa-users' aria-hidden='true' />
            <div className='name-area'>
              <p className='name'>{family.name}</p>
              <p className='student-count'>{`${studentCount} student${studentCount === 1 ? '' : 's'
                }`}</p>
            </div>
            {alreadySelected ? (
              <i className='fa fa-check-circle' aria-hidden='true' />
            ) : (
              <i className='fa fa-plus' aria-hidden='true' />
            )}
          </div>
        );
      });
  };

  _renderStudents = (filter) => {
    const {
      temporarySelectedStudents,
      totalStudents,
      studentLoader,
    } = this.state;
    if (studentLoader) {
      return (
        <div className='d-flex flex-column align-items-center justify-content-center pt-3'>
          <i className='fa fa-spinner fa-spin' aria-hidden='true' />
          <p className='mt-3'>Loading Students</p>
        </div>
      );
    }
    if (totalStudents.length < 1) {
      return (
        <div className='d-flex justify-content-center mt-3'>
          <p>You'll need students in your community to use this report</p>
        </div>
      );
    }
    return totalStudents
      .filter((s) => {
        if (!filter || filter.split("").every((char) => char === " "))
          return true;
        return (
          s.first_name.toLowerCase().includes(filter.trim().toLowerCase()) ||
          s.last_name.toLowerCase().includes(filter.trim().toLowerCase())
        );
      })
      .map((student) => {
        const alreadySelected = temporarySelectedStudents
          .map((s) => s.id)
          .includes(student.id);
        return (
          <div
            key={student.id}
            className={`student-row ${alreadySelected ? "" : "clickable"}`}
            onClick={() => this._addStudent(this._formatStudentData(student))}
          >
            <div
              className='avatar'
              style={{
                backgroundImage: `url(${student.avatar ? student.avatar.file_url : defaultProfilePic
                  })`,
              }}
            />
            <div className='name-area'>
              <p className='name'>{`${student.first_name} ${student.last_name}`}</p>
              <p className='login'>{student.email || student.username}</p>
            </div>
            {alreadySelected ? (
              <i className='fa fa-check-circle' aria-hidden='true' />
            ) : (
              <i className='fa fa-plus' aria-hidden='true' />
            )}
          </div>
        );
      });
  };

  _renderTrackedVisits = (value, otherArgs) => {
    if (otherArgs.every((arg) => !arg)) {
      return (
        <div className='d-flex align-items-center flex-column justify-content-center'>
          <p className='orange-text large-est-revenue-text'>
            {this._formatNum(value)}
          </p>
          <p className='lg-est-visit-text'>Tracked Facility Visits</p>
          <p className='lg-est-visit-text'>(Rounds + Practice Sessions)</p>
        </div>
      );
    }
    return (
      <div className='d-flex align-items-center justify-content-center mt-2'>
        <p className='orange-text small-est-revenue-text'>
          {this._formatNum(value)}
        </p>
        <p className='sm-est-visit-text'>
          Tracked Facility Visits (Rounds + Practice Sessions)
        </p>
      </div>
    );
  };

  _renderInputCalcs = (firstArr, secondArr) => {
    let filteredFirst = firstArr.filter((item) => !!item.value);
    let filteredSecond = secondArr.filter((item) => !!item.value);
    if (filteredFirst.length < 1 && filteredSecond.length < 1) {
      return null;
    }
    if (filteredSecond.length < 1) {
      return (
        <div className='impact-chart single-chart'>
          <p className='section-title1'>Program Revenue Impact</p>
          {filteredFirst.map((obj, idx) => {
            return (
              <div
                className='d-flex align-items-center justify-content-between'
                key={`1${idx}`}
              >
                <p className='section-key'>{obj.title}</p>
                <p className='section-val'>{obj.value}</p>
              </div>
            );
          })}
        </div>
      );
    }
    if (filteredFirst.length < 1) {
      return (
        <div className='impact-chart single-chart'>
          <p className='section-title1'>Est. Revenue Generated</p>
          {filteredSecond.map((obj, idx) => {
            return (
              <div
                className='d-flex align-items-center justify-content-between'
                key={`1${idx}`}
              >
                <p className='section-key'>{obj.title}</p>
                <p className='section-val'>{obj.value}</p>
              </div>
            );
          })}
          {filteredSecond.length > 1 && (
            <div className='d-flex align-items-center justify-content-between total-line'>
              <p className='total-section-key'>Total</p>
              <p className='total-section-val'>
                {displayWithCurrency(
                  filteredSecond.reduce((num, obj) => {
                    const stringVal = obj.value.replace(/[^\d]/g, '')
                    return (num += parseInt(stringVal, 10))
                  }, 0),
                  this.props.community.currency,
                  true
                )}
              </p>
            </div>
          )}
        </div>
      );
    }
    return (
      <div className='d-flex'>
        <div className='impact-chart left-chart'>
          <p className='section-title1'>Program Revenue Impact</p>
          {filteredFirst.map((obj, idx) => {
            return (
              <div
                className='d-flex align-items-center justify-content-between'
                key={`1${idx}`}
              >
                <p className='section-key'>{obj.title}</p>
                <p className='section-val'>{obj.value}</p>
              </div>
            );
          })}
        </div>
        <div className='impact-chart'>
          <p className='section-title1'>Est. Revenue Generated</p>
          {filteredSecond.map((obj, idx) => {
            return (
              <div
                className='d-flex align-items-center justify-content-between'
                key={`1${idx}`}
              >
                <p className='section-key'>{obj.title}</p>
                <p className='section-val'>{obj.value}</p>
              </div>
            );
          })}
          {filteredSecond.length > 1 && (
            <div className='d-flex align-items-center justify-content-between total-line'>
              <p className='total-section-key'>Total</p>
              <p className='total-section-val'>
                {displayWithCurrency(
                  filteredSecond.reduce((num, obj) => {
                    const stringVal = obj.value.replace(/[^\d]/g, '')
                    return (num += parseInt(stringVal, 10))
                  }, 0),
                  this.props.community.currency,
                  true
                )}
              </p>
            </div>
          )}
        </div>
      </div>
    );
  };

  _renderStudentReport = () => {
    const { community, coachName } = this.props;
    const { startDate, endDate, recipient, report } = this.state;
    let userIdArray = Object.keys(report.user_list);
    const pages = parseInt((userIdArray.length - 1) / 14, 10) + 1;
    let return_array = [];
    for (let i = 0; i < pages; i++) {
      let truncatedUserList = {};
      userIdArray
        .slice(i * 14, (i + 1) * 14)
        .map((u) => {
          return { [u]: report.user_list[u] };
        })
        .forEach(
          (s) => (truncatedUserList[Object.keys(s)[0]] = s[Object.keys(s)[0]])
        );
      return_array.push(
        <div className='StudentReport' key={i}>
          <div className='report-spacer' data-html2canvas-ignore />
          <div className='d-flex align-items-center report-top-bar p-2'>
            <div
              className='community-photo'
              style={{ backgroundImage: `url(${community.photo.file_url})` }}
              data-html2canvas-ignore
            />
            <div className='report-id-info'>
              <p className='large-bold'>{community.name}</p>
              <p className='small-bold'>{`Coach: ${coachName}`}</p>
              <p className='sub-text'>Prepared for: {recipient}</p>
            </div>
            <div className='report-timeline-container'>
              <div
                className='logo'
                style={{ backgroundImage: `url(${logo})` }}
              />
              <p className='bold'>Program Impact Report</p>
              <p>{`${moment(startDate).format("MMM D, YYYY")} - ${moment(
                endDate
              ).format("MMM D, YYYY")}`}</p>
            </div>
          </div>
          <div className='student-report-container'>
            <div className='d-flex align-items-center justify-content-between'>
              <p className='orange-text'>{`STUDENTS IMPACTED (${Object.keys(report.user_list).length
                })`}</p>
              <p className='page-count'>{`Page ${i + 1} of ${pages}`}</p>
            </div>

            <table>
              <thead>
                <tr>
                  <th>Golfer</th>
                  <th className='center'>Time in Op 36</th>
                  <th className='center'>Level</th>
                  <th className='center'>Milestones</th>
                  <th className='center'>Strokes Lost</th>
                  <th className='center'>Rounds</th>
                  <th className='center'>Training Sessions</th>
                  <th className='center'>Objectives</th>
                </tr>
              </thead>
              <tbody>{this._renderStudentTable(truncatedUserList)}</tbody>
            </table>
          </div>
        </div>
      );
    }
    return return_array;
  };

  _renderStudentTable = (student_list) => {
    let return_array = [];
    for (const student in student_list) {
      return_array.push(
        <tr key={student}>
          <td className='student-name'>{student_list[student].name}</td>
          <td className='center network-time'>
            {this._formatTimeSince(student_list[student].created_at)}
          </td>
          <td className='center'>
            {`Level ${student_list[student].division}`}
          </td>
          <td className='center orange-text'>
            {student_list[student].milestones}
          </td>
          <td className='center orange-text'>
            {student_list[student].strokes_lost}
          </td>
          <td className='center green-text'>{student_list[student].rounds}</td>
          <td className='center blue-text'>
            {student_list[student].training_sessions}
          </td>
          <td className='center orange-text'>
            {student_list[student].objectives}
          </td>
        </tr>
      );
    }
    return return_array;
  };

  _formatTimeSince = (date) => {
    let now = moment();
    let time = now.diff(moment(date), "years");
    let unit = "yr.";
    if (time < 1) {
      time = now.diff(moment(date), "months");
      unit = "mo.";
      if (time < 1) {
        time = now.diff(moment(date), "weeks");
        unit = "wk.";
        if (time < 1) {
          time = now.diff(moment(date), "days");
          unit = "d.";
          if (time < 1) {
            return "today";
          }
        }
      }
    }
    return `${time} ${unit}`;
  };

  // eslint-disable-next-line complexity
  _renderAssumptions = (arr) => {
    if (!arr.some((v) => !!v)) {
      return null
    }
    // [averageFee, merchandise, foodAndBev, averageMembership, initiationFee, membershipPercent, newMemberCount]
    return (
      <p className='sub-text'>
        <span className='bold'>Assumptions Per Round: </span>
        {arr[0] && (
          <span className='orange-text'>
            {displayWithCurrency(parseFloat(arr[0]), this.props.community.currency)}
          </span>
        )}
        {arr[0] && ` golf fees${this._checkComma(0, arr) ? ', ' : ''}`}
        {this._checkComma(1, arr) ? '' : 'and '}
        {arr[1] && (
          <span className='orange-text'>
            {displayWithCurrency(parseFloat(arr[1]), this.props.community.currency)}
          </span>
        )}
        {arr[1] && ` merchandise revenue${this._checkComma(1, arr) ? ', ' : ''}`}
        {this._checkComma(2, arr) ? '' : 'and '}
        {arr[2] && (
          <span className='orange-text'>
            {displayWithCurrency(parseFloat(arr[2]), this.props.community.currency)}
          </span>
        )}
        {arr[2] &&
          ` food and beverage revenue${this._checkComma(2, arr) ? ', ' : ''}`}
        {this._checkComma(3, arr) ? '' : 'and '}
        {arr[3] && (
          <span className='orange-text'>
            {displayWithCurrency(parseFloat(arr[3]), this.props.community.currency)}
          </span>
        )}
        {arr[3] &&
          ` avg. annual membership${this._checkComma(3, arr) ? ', ' : ''}`}
        {this._checkComma(4, arr) ? '' : 'and '}
        {arr[4] && (
          <span className='orange-text'>
            {displayWithCurrency(parseFloat(arr[4]), this.props.community.currency)}
          </span>
        )}
        {arr[4] &&
          ` member initiation fee${this._checkComma(4, arr) ? ', ' : ''}`}
        {this._checkComma(5, arr) ? '' : 'and '}
        {arr[5] && <span className='orange-text'>{parseInt(arr[5], 10)}%</span>}
        {arr[5] &&
          ` of families that are members${this._checkComma(5, arr) ? ', ' : ''
          }`}
        {this._checkComma(6, arr) ? '' : 'and '}
        {arr[6] && <span className='orange-text'>{arr[6]}</span>}
        {arr[6] && ' total new members in program'}
      </p>
    )
  }

  _checkComma = (idx, arr) => {
    if (!arr[idx]) return true;
    let ret = false;
    for (let i = idx + 1; i < arr.length; i++) {
      if (!!arr[i]) {
        ret = true;
        break;
      }
    }
    return ret;
  };

  _pullStudentsFromFamily = (family) => {
    return family.users
      .filter((user) => user.is_golfer)
      .map((user) => this._formatStudentData(user));
  };

  _pullStudentsFromCourse = (course, status) => {
    return course.enrollments
      .filter((enrollment) => enrollment.status === status)
      .map((enrollment) => this._formatStudentData(enrollment.user));
  };

  _formatStudentData = (student) => {
    return {
      id: student.id,
      name: `${student.first_name} ${student.last_name}`,
      username: student.username || student.email,
      avatar: student.avatar,
    };
  };

  _addStudent = (student) => {
    this.setState((prevState) => {
      if (
        prevState.temporarySelectedStudents
          .map((s) => s.id)
          .includes(student.id)
      ) {
        return prevState;
      }
      return {
        temporarySelectedStudents: [
          ...prevState.temporarySelectedStudents,
          student,
        ],
      };
    });
  };

  _addStudentArray = (students) => {
    this.setState((prevState) => {
      let studentsToAdd = students.filter(
        (s1) =>
          !prevState.temporarySelectedStudents
            .map((s2) => s2.id)
            .includes(s1.id)
      );
      return {
        temporarySelectedStudents: [
          ...prevState.temporarySelectedStudents,
          ...studentsToAdd,
        ],
      };
    });
  };

  _removeStudentFromSelected = (student_id) => {
    this.setState((prevState) => {
      let newList = prevState.selectedStudents.filter(
        (s) => s.id !== student_id
      );
      return {
        selectedStudents: [...newList],
        temporarySelectedStudents: [...newList],
      };
    });
  };

  _removeStudentFromTemporary = (student_id) => {
    this.setState((prevState) => {
      return {
        temporarySelectedStudents: prevState.temporarySelectedStudents.filter(
          (s) => s.id !== student_id
        ),
      };
    });
  };

  _checkNotSame = (one, two) => {
    // checks if arrays hold the same students
    if (one.length !== two.length) return true;
    let sameValues = one.every((val) => {
      return two.map((v) => v.id).includes(val.id);
    });
    return !sameValues;
  };

  _checkAlreadySelected = (users) => {
    if (users.length === 0) return false;
    const { temporarySelectedStudents } = this.state;
    return users.every((user) =>
      temporarySelectedStudents.map((u) => u.id).includes(user.id)
    );
  };

  _grabLeftCoordinate = () => {
    const { selectionDisplay } = this.state;
    switch (selectionDisplay) {
      case "Families":
        return { left: "25%" };
      case "Active Classes":
        return { left: "50%" };
      case "Completed Classes":
        return { left: "75%" };
      default:
        return { left: 0 };
    }
  };

  _isOverOneYear = (start, end) => {
    return moment(end).diff(moment(start), "years", true) > 1;
  };

  _datesOutOfOrder = (start, end) => {
    return moment(start).isAfter(moment(end));
  };

  _formatNum = (num) => {
    return num.toLocaleString();
  };

  _formatDuration = (minutes) => {
    if (minutes < 60) {
      return `${minutes} Minutes`;
    }
    return `${this._formatNum(Math.floor(minutes / 60))} Hours`;
  };

  _checkSearchLoading = (current) => {
    const {
      studentLoader,
      courseLoader,
      completedCourseLoader,
      familyLoader,
    } = this.state;
    switch (current) {
      case "Students":
        return studentLoader;
      case "Families":
        return familyLoader;
      case "Active Classes":
        return courseLoader;
      case "Completed Classes":
        return completedCourseLoader;
      default:
        return true;
    }
  };
}

FacilityImpactReport.propTypes = {
  community: PropTypes.shape({
    currency: PropTypes.object,
  }),
}


export default FacilityImpactReport;
