import React from "react";
import _ from "lodash";
import PropTypes from 'prop-types'
import SearchInput, { createFilter } from "react-search-input";
import ConfirmationModal2 from "../../common/ConfirmationModal2";

import "./AddStudents.scss";
import d1 from "../../../assets/images/division-badges/D01-Badge.png";
import d2 from "../../../assets/images/division-badges/D02-Badge.png";
import d3 from "../../../assets/images/division-badges/D03-Badge.png";
import d4 from "../../../assets/images/division-badges/D04-Badge.png";
import d5 from "../../../assets/images/division-badges/D05-Badge.png";
import d6 from "../../../assets/images/division-badges/D06-Badge.png";
import d7 from "../../../assets/images/division-badges/D07-Badge.png";
import d8 from "../../../assets/images/division-badges/D08-Badge.png";
import d9 from "../../../assets/images/division-badges/D09-Badge.png";
import d10 from "../../../assets/images/division-badges/D10-Badge.png";
import AvatarPlaceholder from "../../../assets/images/mock/StockProfilePic.png";

import Loader from "../../common/Op36Loader";
import { connect } from "react-redux"

const KEYS_TO_FILTERS = ['first_name', 'last_name']

class AddStudents extends React.Component {
  state = {
    showIncreaseGolferLimitConfirmationModal: false,
    searchTerm: ''
  }

  displayStudents(course_id) {
    if (this.props.communityStudents) {
      const filteredStudents = this.props.communityStudents.filter(
        createFilter(this.state.searchTerm, KEYS_TO_FILTERS)
      )

      return filteredStudents.map((user, index) => {
        if (this.isUserPending(user, course_id)) {
          return this.displayPendingStudents(user, course_id, index);
        } else if (this.isUserEnrolled(user, course_id)) {
          return this.displayEnrolledStudents(user, course_id, index);
        } else {
          return this.displayNonEnrolledStudents(user, course_id, index);
        }
      })
    }
  }

  /*View Students*****************************************/
  isUserEnrolled(user, course_id) {
    for (var i = 0; i < user.enrollments.length; i++) {
      if (user.enrollments.length > 0) {
        var enrollment = user.enrollments[i];
        if (enrollment.course_id === course_id) {
          // eslint-disable-line
          return true;
        }
      }
    }
    return false;
  }

  isUserPending(user, course_id) {
    // 0 is Applied
    // 1 is Active
    // 2 is Suspended
    if (this.props.course.enrollments) {
      let enrollments = _.filter(this.props.course.enrollments, {
        user_id: user.id,
        course_id: course_id,
        status: "applied"
      });
      if (enrollments.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  displayEnrolledStudents(user, course_id, index) {
    return (
      // <div key={index} className='row'>
      //   <div className='col'>
      //     <div className='checkbox enrolledFamilyMember'>
      //       {user.first_name} {user.last_name}
      //     </div>
      //   </div>
      // </div>
      <StudentCard
        user={user}
        key={index}
        isEnrolled={true}
        isPending={false}
        onClick={() => false}
      />
    );
  }

  isMemberChecked(members, user_id) {
    if (_.indexOf(members, user_id) > -1) {
      return true;
    } else {
      return false;
    }
  }

  displayNonEnrolledStudents(user, course_id, index) {
    return (
      // <div key={index} className='row'>
      //   <div className='col'>
      //     <div className='checkbox nonEnrolledFamilyMember'>
      //       <label>
      //         <input
      //           name=''
      //           type='checkbox'
      //           checked={this.isMemberChecked(
      //             this.props.activeMembers,
      //             user.id
      //           )}
      //           onChange={this.handleMemberChange.bind(this, user.id)}
      //         />{" "}
      //         {user.first_name} {user.last_name}
      //       </label>
      //     </div>
      //   </div>
      // </div>
      <StudentCard
        user={user}
        key={index}
        isPending={false}
        isEnrolled={false}
        isChecked={this.isMemberChecked(this.props.activeMembers, user.id)}
        onClick={this.handleMemberChange.bind(this, user.id)}
      />
    );
  }

  displayPendingStudents(user, course_id, index) {
    return (
      // <div key={index} className='row'>
      //   <div className='col'>
      //     <div className='checkbox pendingFamilyMember'>
      //       {user.first_name} {user.last_name}{" "}
      //       <small>
      //         <i>Pending</i>
      //       </small>
      //     </div>
      //   </div>
      // </div>
      <StudentCard
        user={user}
        key={index}
        isPending={true}
        onClick={() => false}
      />
    );
  }

  handleMemberChange(user_id) {
    // IF THE MEMBER IS CHECKED UNCHECKED ELSE CHECK IT
    if (this.isMemberChecked(this.props.activeMembers, user_id)) {
      var newArrayIsChecked = _.pull(this.props.activeMembers, user_id);
      this.props.updateActiveMembers(newArrayIsChecked);
    } else {
      var newArrayNotChecked = this.props.activeMembers.slice();
      newArrayNotChecked.push(user_id);
      this.props.updateActiveMembers(newArrayNotChecked);
    }
  }

  handleSubmitButton(course) {
    if (course.golfer_limit && this.props.activeMembers.length + course.enrollments.length > course.golfer_limit) {
      this.setState({ showIncreaseGolferLimitConfirmationModal: true })
    } else {
      this.props.handleSubmitUserJoinCourse(course)
    }
  }

  /*END VIEW STUDENTS****************************************/

  render() {
    return this.props.loader ? (
      <div className='pt-5'>
        <Loader message='grabbing students' />
      </div>
    ) : (
      <div id='AddStudentsModal'>
        <div className='modal-content col-md-6 mx-auto pb-5'>
          <div className='modal-body' id='input-border'>
            <div className="search-container">
              <i className="fa fa-search search-icon" />
              <SearchInput
                className="search-input"
                onChange={term => this.setState({ searchTerm: term })}
                style={{
                  border: "1px solid #2b3843",
                  borderRadius: "25px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  width: "250px"
                }}
                placeholder="Search Students"
              />
            </div>
            <div className='row'>
              <div className='col'>
                <div className='form-group'>
                  {this.displayStudents(this.props.course.id)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='modal-footer fixed-bottom w-100 bg-white'>
          <button
            type='button'
            className='btn btn-primary btn-block btn-op36'
            disabled={this.props.loader || this.props.activeMembers?.length < 1}
            onClick={() => this.handleSubmitButton(this.props.course)}
          >
            {this.props.loader ? "Adding Students..." : "Add Students"}
          </button>
        </div>
        <ConfirmationModal2
          showModal={this.state.showIncreaseGolferLimitConfirmationModal}
          closeModal={() => this.setState({ showIncreaseGolferLimitConfirmationModal: false })}
          onDeny={() => this.setState({ showIncreaseGolferLimitConfirmationModal: false })}
          denyMessage='Cancel'
          onProceed={() => this.props.handleSubmitUserJoinCourse(this.props.course)}
          proceedMessage='Add students'
          // icon={<i className='fa fa-question-circle' style={{ fontSize: '72px' }} />}
          title='Your class is already full!'
          message={`Are you sure you want to add more students? It will increase your maximum class size from
                    ${this.props.course.golfer_limit} to ${this.props.course.enrollments.length + this.props.activeMembers.length}.`}
        />
      </div>
    );
  }
}

const _grabIcon = division => {
  switch (division) {
    case 1:
      return d1;
    case 2:
      return d2;
    case 3:
      return d3;
    case 4:
      return d4;
    case 5:
      return d5;
    case 6:
      return d6;
    case 7:
      return d7;
    case 8:
      return d8;
    case 9:
      return d9;
    case 10:
      return d10;
    default:
      return "";
  }
};

const StudentCard = ({ user, isChecked, isPending, isEnrolled, onClick }) => {
  return (
    <div
      className={`add-student-card d-flex w-100 justify-content-between align-items-center ${
        isPending || isEnrolled ? "unclickable" : "clickable"
      } ${isChecked ? "checked" : ""}`}
      onClick={onClick}
    >
      {isPending && <p>pending</p>}
      <div className='d-flex align-items-center image-name-container'>
        <div
          className='student-pic'
          style={{
            backgroundImage: `url(${
              user.avatar ? user.avatar.file_url : AvatarPlaceholder
            })`
          }}
        />
        <p className='student-name'>{`${user.first_name} ${user.last_name}`}</p>
      </div>
      <div
        className='division-icon'
        style={{ backgroundImage: `url(${_grabIcon(user.current_division)})` }}
      />
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.user.current_user
  };
}

AddStudents.propTypes = {
  communityStudents: PropTypes.array,
}

export default connect(mapStateToProps)(AddStudents);
