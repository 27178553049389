import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { ReactComponent as BackArrowIcon } from '../../../assets/images/common/icons/back-arrow-icon.svg'
import { ReactComponent as CircleLogo } from '../../../assets/images/common/icons/circle-logo.svg'
import { triggerForgotUsername } from '../../../actions/authActions'
import Loader from '../../common/Op36Loader'
import SubmitButton from '../../common/buttons/SubmitButton'
import ButtonLink from '../../common/buttons/ButtonLink'
import FormInput from '../SignUp/Steps/Helpers/FormInput'
import FieldError from '../../common/FieldError'

import classes from './ForgotUsernamePage.module.scss'

export const STEPS = {
  EMAIL_INPUT: 1,
  EMAIL_PENDING: 2,
  EMAIL_SENT: 3,
}

const ForgotUsernamePage = () => {
  const dispatch = useDispatch()

  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [step, setStep] = useState(STEPS.EMAIL_INPUT)

  const handleUsernameEmail = () => {
    setStep(STEPS.EMAIL_PENDING)

    dispatch(triggerForgotUsername(email))
      .then((_) => setStep(STEPS.EMAIL_SENT))
      .catch(() => {
        setError('Something went wrong')
        setStep(STEPS.EMAIL_INPUT)
      })
  }

  return (
    <div className={classNames(classes.forgotUsernamePage, 'd-flex')}>
      <aside className={classNames(classes.mainCard, 'm-auto')}>
        <Link to={'/log-in'}>
          <BackArrowIcon className={classes.backArrowIcon} />
        </Link>

        {step === STEPS.EMAIL_INPUT && (
          <div className={classNames(classes.emailInput, 'd-flex flex-column justify-content-center align-items-center h-100 w-100')}>
            <header className='d-flex flex-column justify-content-center align-items-center'>
              <CircleLogo className={classes.circleLogo} />
              <h2 className={classes.titleText}>Forgot Username?</h2>
              <h5 className={classes.subTitleText}>
                Please enter your admin account email. We will send you a list of your family users and usernames.
              </h5>
            </header>

            <form className='row w-100'>
              <div className='col-12 col-lg-6 offset-lg-3'>
                {error && <FieldError message={error} style={{ paddingBottom: '5px' }} general/>}

                <label htmlFor='email' className={classes.inputLabel}>
                  Email <span className='required-text'>*</span>
                </label>

                <FormInput
                  name='email'
                  type='text'
                  onChange={(e) => { setEmail(e.target.value); setError('') }}
                  value={email}
                  symbol={<i className={classNames(classes.inputSymbol, 'fa fa-envelope')}/>}
                />

                <SubmitButton
                  onClick={(e) => { e.preventDefault(); handleUsernameEmail() }}
                  buttonMessage='Send Email'
                  buttonColor='orange-button'
                  className={classes.sendEmailButton}
                  disabled={!email}
                />
              </div>
            </form>
          </div>
        )}

        {step === STEPS.EMAIL_PENDING && (
          <div className={classNames(classes.emailPending, 'd-flex flex-column justify-content-center align-items-start h-100 w-100')}>
            <Loader message='Sending Email' fullScreen='false' color='white' />
          </div>
        )}

        {step === STEPS.EMAIL_SENT && (
          <div className={classNames(classes.emailSent, 'd-flex flex-column justify-content-center align-items-center  h-100 w-100')}>
            <header className='d-flex flex-column justify-content-center align-items-center'>
              <CircleLogo className={classes.circleLogo} />
              <h2 className={classes.titleText}>Email has been sent!</h2>
              <h5 className={classes.subTitleText}>Please check your inbox to view the list of usernames. Thank you!</h5>
            </header>

            <ButtonLink navigateTo='/log-in' className={classNames(classes.actionLink, 'mt-4 mb-5 mb-md-0')}>
              Back to Log In
            </ButtonLink>
          </div>
        )}
      </aside>
    </div>
  )
}

export default ForgotUsernamePage
