/* eslint-disable react/prop-types, no-unused-vars, no-console, max-len */

import React from 'react'
import { Collapse } from 'reactstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import '../../assets/styles/components/dashboard/OldDashboardPage.scss'
import '../../assets/styles/components/activity/Activity.scss'
import './Onboarding.scss'
import * as commonActions from '../../actions/commonActions.js'
import * as activityActions from '../../actions/activityActions.js'
import * as notificationActions from '../../actions/notificationActions.js'
import * as familyActions from '../../actions/familyActions.js'
import * as courseActions from '../../actions/courseActions.js'
import * as userActions from '../../actions/userActions.js'
import * as communitiesActions from '../../actions/communitiesActions.js'
import * as Constants from '../../misc/Constants.js'
import moment from 'moment'
import { Link, Navigate } from 'react-router-dom'
import StockProfilePic from '../../assets/images/mock/StockProfilePic.png'
import ReactList from 'react-list'
import * as fadeAnimation from '../common/fadeAnimation.js'
import Modal from 'react-modal'
import PropTypes from 'prop-types'

import Activity from '../activity/Activity.js'
import Loader from '../common/Op36Loader-web.js'
import PageHeader from '../common/PageHeader/PageHeader.js'

import FamilyDashboard from './FamilyDashboard.js'

import QUOTES from '../common/Quotes.js'
import TIPS from '../common/Tips.js'

import AppleIcon from './icons/apple-app-store.png'
import GoogleIcon from './icons/google-play.png'
import { withRouting } from '../common/hooks.js'
import CoachDashboardNew from './coachDashboard/CoachDashboardNew.js'

const PER_PAGE = 6
const ROWS_FROM_BOTTOM_LOAD = 2

class OldDashboardPage extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      page: Constants.START_PAGE_DEFAULT,
      totalRows: 0,
      sinceTS: moment().unix(),
      containerHeight: 0,
      rowHeight: 0,
      in: false,
      addCompleted: false,
      registerCompleted: false,
      downloadCompleted: false,
      activityCompleted: false,
      completedOnboarding: false,
      showAddGolferModal: false,
      showRegisterGolferModal: false,
      showDownloadAppModal: false,
      showPostActivityModal: false,
      isOpen: false,
      loading: true,
      className: '',
      scrolled: '',
      userActivityLoader: true,
      statsLoader: this.props.user?.type !== 'Admin',
      enrollmentsLoader: false,
      programsLoader: false,
      pipelineStatsLoader: true,
      randomQuote: '',
      duration: null,
      windowWidth: window.innerWidth,
    }

    this.divisions = [225, 450, 900, 1350, 1800, 'fullTee']

    this.displayGolfers = this.displayGolfers.bind(this)
    this.displayEnrollments = this.displayEnrollments.bind(this)
    this.displayFamilyWidgets = this.displayFamilyWidgets.bind(this)
    // this.displayCoachWidgets = this.displayCoachWidgets.bind(this);
    this.renderItem = this.renderItem.bind(this)
    this.addClick = this.addClick.bind(this)
    this.registerClick = this.registerClick.bind(this)
    this.downloadClick = this.downloadClick.bind(this)
    this.activityClick = this.activityClick.bind(this)
    this.closeAddGolferModal = this.closeAddGolferModal.bind(this)
    this.closeRegisterGolferModal = this.closeRegisterGolferModal.bind(this)
    this.closeDownloadAppModal = this.closeDownloadAppModal.bind(this)
    this.closePostActivityModal = this.closePostActivityModal.bind(this)
    this.toggle = this.toggle.bind(this)
  }

  componentDidMount() {
    const {
      user,
      user_actions,
      common_actions,
      communities_actions,
      notification_actions,
    } = this.props

    window.addEventListener('resize', this.handleWindowResize)

    if (user && user.type !== 'Admin') {
      user_actions.refreshCurrentUser(user.id)
      common_actions.setCurrentTab('Dashboard')
      common_actions.showSideBar(true)
      common_actions.showNavBar(true)
      common_actions.showTabBar(true)

      if (user.type === 'Coach') {
        this.setState({ statsLoader: true })
        communities_actions
          .getStats(user.communities[0].id)
          .then(() => {
            this.setState({
              statsLoader: false,
            })
          })

        this.setState({ pipelineStatsLoader: true })
        communities_actions
          .getPipelineStats(user.communities[0].id, 'formal')
          .then(() => {
            this.setState({
              pipelineStatsLoader: false,
            })
          })

        notification_actions.getUnreadNotifications(user.id)
      }

      if (user.type === 'Student') {
        this.fetchFamilyProps()
      }

      if (this.state.page === Constants.START_PAGE_DEFAULT) {
        this.loadPageActivities()
      }

      setTimeout(() => this.setState({ loading: false }), 1250)

      this.toggleEnterState()

      if (user.type === 'Coach') {
        this.randomizeQuote()
      }

      if (this._shouldShowCountdown(user)) {
        this._initializeCountdown(user.communities[0].season_start_date)
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    window.removeEventListener('resize', this.handleWindowResize)
  }

  render() {
    const {
      stats,
      pipeline_stats,
      user,
      family,
      activities,
      user_activities,
      courses,
      family_stats,
      enrollments,
    } = this.props
    const {
      activityLoader,
      userActivityLoader,
      randomQuote,
      page,
      onboardingComplete,
      addCompleted,
      registerCompleted,
      downloadCompleted,
      activityCompleted,
      statsLoader,
      enrollmentsLoader,
      programsLoader,
    } = this.state

    if (statsLoader) {
      return (
        <div className='row'>
          <div className='col-12'>
            <div className='loading-content stats-loading-content '>
              <Loader message='building dashboard' />
            </div>
          </div>
        </div>
      )
    }
    if (user) {
      return user.type === 'Admin' ? (
        <Navigate to='/communities' replace />
      ) : (
        <div
          id='OldDashboardPage'
        >
          {window.innerWidth < 1200 && this.props.user.type === 'Student' && <PageHeader/>}
          <fadeAnimation.Fade in={this.state.in}>
            {this.state.in ? (
              <>
                {/* Coach Dashboard: */}
                <div className='container-fluid'>
                  {user.type === 'Coach' && (
                    <CoachDashboardNew />
                  )}
                  {/* Family Dashboard: */}
                  {this.props.user.type === 'Student' && (
                    <FamilyDashboard
                      user={user}
                      family={family}
                      stats={family_stats}
                      courses={courses}
                      activities={activities}
                      user_activities={user_activities}
                      onboardingComplete={onboardingComplete}
                      addCompleted={addCompleted}
                      registerCompleted={registerCompleted}
                      downloadCompleted={downloadCompleted}
                      activityCompleted={activityCompleted}
                      userActivityLoader={userActivityLoader}
                      enrollmentsLoader={enrollmentsLoader}
                      programsLoader={programsLoader}
                      addClick={this.addClick}
                      registerClick={this.registerClick}
                      downloadClick={this.downloadClick}
                      activityClick={this.activityClick}
                      enrollments={enrollments}
                      page={page}
                      PER_PAGE={PER_PAGE}
                      ROWS_FROM_BOTTOM_LOAD={ROWS_FROM_BOTTOM_LOAD}
                      loadPageActivities={this.loadPageActivities}
                      fetchFamilyProps={this.fetchFamilyProps}
                    />
                  )}

                </div>
                {this.displayAddGolferModal()}
                {this.displayRegisterGolferModal()}
                {this.displayDownloadAppModal()}
                {this.displayPostActivityModal()}
              </>
            ) : (
              <div />
            )}
          </fadeAnimation.Fade>
        </div>
      )
    } else {
      return null
    }
  }

  handleWindowResize = () => {
    this.setState({ windowWidth: window.innerWidth })
  }

  _shouldDisplayBanner = (user) => (
    !!user &&
      user.type === 'Coach' &&
      user.communities.length > 0 &&
      user.communities[0] &&
      user.communities[0].show_onboarding_banner &&
      user.communities[0].onboarding_checklist_url
  )

  _shouldShowCountdown = (user) => (
    this._shouldDisplayBanner(user) && user.communities[0].season_start_date
  )

  _initializeCountdown = (startDate) => {
    if (this._isInPast(startDate)) { return }
    this.interval = setInterval(() => {
      const targetDate = moment(startDate).startOf('day')
      const nowDate = moment()
      const diffTime = targetDate.diff(nowDate)
      const dur = moment.duration(diffTime, 'milliseconds')

      const days = Math.floor(dur.asDays())
      const hours = dur.hours()
      const dayString = days > 0 ? `${days} DAY${days === 1 ? '' : 'S'}` : ''
      const hoursString =
        days > 0 || hours > 0 ? `${hours} HOUR${hours === 1 ? '' : 'S'}` : ''
      this.setState({
        duration: `${dayString} ${hoursString} ${dur.minutes()} MIN ${dur.seconds()} SEC`,
      })
    }, 1000)
  }

  _isInPast = (startDate) => {
    const now = moment()
    const start = moment(startDate)
    return start.isBefore(now)
  }

  fetchFamilyProps = () => {
    const { family_actions, course_actions, user } = this.props
    this.setState({
      statsLoader: true,
      enrollmentsLoader: true,
      programsLoader: true,
    })
    family_actions.getFamily(user.family.id).then(() => {
      family_actions.getFamilyEnrollments(user.family.id).then(() => {
        this.setState({
          enrollmentsLoader: false,
        })
        course_actions.getCommunityCourses(user.communities[0].id).then(() => {
          this.setState({
            programsLoader: false,
          })
        })
      })
      family_actions.getFamilyStats(user.family.id).then(() => {
        this.setState({ statsLoader: false })
      })
    })
  }

  randomizeQuote = () => {
    const arr = TIPS.concat(QUOTES)
    const randomNum = Math.floor(Math.random() * Math.floor(arr.length))
    const randomQuote = arr[randomNum]

    this.setState({
      randomQuote,
    })
  }

  reloadStats = () => {
    this.setState({ activityLoader: true })
    this.props.communities_actions
      .getStats(this.props.user.communities[0].id)
      .then((res) => {
        this.setState({
          activityLoader: false,
        })
      })
  }

  handleRefresh(resolve, reject) {
    // do some async code here
    this.setState({
      page: Constants.START_PAGE_DEFAULT,
    })
    this.loadPageActivities(resolve)
  }

  toggleEnterState = () => {
    setTimeout(
      () => {
        this.setState({ in: true })
      },
      fadeAnimation.FADE_DURATION
    )
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  renderUserActivities() {
    return this.props.user_activities.map((activity) => (
      <Activity
        key={activity.id}
        displayComments={false}
        activity={activity}
      />
    ))
  }

  displayActivities() {
    if (this.state.userActivityLoader) {
      return (
        <div className='card mb-2 loading-card'>
          <h5 className='p-2 ml-3 my-2 card-header-text'>Activity Feed</h5>
          <div className='card-block p-2 activity-feed-section'>
            <div className='row'>
              <div className='col-12'>
                <div className='loading-content stats-loading-content '>
                  <Loader message='loading golfer activities' />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (
      this.props.user_activities &&
      this.props.user_activities.length > 0 &&
      this.props.user.type === 'Student'
    ) {
      return (
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12 p-0'>
              <div
                id='activityWrapper'
                className='componentBody container-fluid p-0'
              >
                <h5 className='p-2 ml-3 my-2 card-header-text'>
                  Activity Feed
                </h5>
                <div className='p-4 activity-feed-section'>
                  {this.renderUserActivities()}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else if (this.props.activities && this.props.activities.length > 0) {
      return (
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12 p-0'>
              {/* <div
                id="activityWrapper"
                className="componentBody container-fluid p-0"
              > */}
              {this.props.user.type === 'Coach' && (
                <div className='dashboard-third-step dashboard-welcome-container card hidden-sm-down animated fadeIn'>
                  <div className='d-flex flex-row align-items-center justify-content-center dashboard-welcome-info-wrapper'>
                    <div className='round-image-wrapper mr-3 dashboard-welcome-avatar'>
                      <img
                        className='round-image'
                        src={
                          this.props.user.avatar
                            ? this.props.user.avatar.file_url
                            : StockProfilePic
                        }
                        alt={`${this.props.user.first_name} ${this.props.user.last_name}`}
                      />
                    </div>
                    <div>
                      <h5 className='dashboard-time-name'>{`Welcome Coach ${this.props.user.first_name}`}</h5>

                      <div className='dashboard-time-wrapper'>
                        <h5 className='dashboard-time-text'>Today is</h5>
                        <h5 className='dashboard-time animated fadeIn'>
                          {moment().format('MM/DD/YYYY hh:mm A')}
                          {/* <i
                    className="fa fa-question-circle dashboard-tooltip"
                    data-tip={
                      this.props.user.type === "Coach"
                        ? "This is a feed of all your communities golf activity.  Like and comment on their activity to increase engagement and let your golfers know you care"
                        : "This is a feed of all of your community activities."
                    }
                  /> */}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className='dashboard-welcome-tip-wrapper'>
                    <p>{this.state.randomQuote}</p>
                  </div>
                </div>
              )}
              <div className='activity-feed-divider hidden-sm-down' />
              <div className='activity-feed-section dashboard-fourth-step'>
                <ReactList
                  itemRenderer={this.renderItem}
                  length={this.props.activities.length}
                  type='simple'
                />
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      )
    } else if (
      this.props.activities.length === 0 ||
      this.props.user_activities.length === 0
    ) {
      return (
        <div className='card my-3'>
          <h5 className='p-2 ml-3 my-2 card-header-text'>Activity Feed </h5>
          <div className='card-block p-2'>
            <div className='row py-2'>
              <div className='col-12'>
                <h2 className='text-center secondary-text activity-text'>
                  Download App And Log Your 1st Action
                </h2>
              </div>
            </div>
            <div className='d-flex justify-content-center flex-column'>
              <button
                type='button'
                className='btn btn-primary collapse-button'
                onClick={this.toggle}
              >
                Click Here To See How
              </button>
              <Collapse isOpen={this.state.isOpen}>
                <div className='text-center container activity-gif-mobile'>
                  <div className='pt-3 card-body'>
                    <img
                      src='https://media.giphy.com/media/xUOxeWlfa7eheHIR1u/giphy.gif'
                      alt='Post First Activity As A Golfer'
                    />
                  </div>
                </div>
              </Collapse>
            </div>
            <div className='row py-2 justify-content-center d-flex'>
              <div className='col-8 text-center'>
                <a
                  href='https://itunes.apple.com/us/app/op-36-golf/id1186959877?mt=8'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img
                    src={AppleIcon}
                    alt='Operation36 on iOS'
                    className='app-icon'
                  />
                </a>
                <a
                  href='https://play.google.com/store/apps/details?id=com.operation36.app&hl=en'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img
                    src={GoogleIcon}
                    alt='Operation36 on Google Play'
                    className='app-icon'
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className='card my-3 loading-card'>
          <h5 className='p-2 ml-3 my-2 card-header-text'>Activity Feed </h5>
          <div className='card-block p-2'>
            <div className='row'>
              <div className='col-12'>
                <div className='loading-content stats-loading-content '>
                  <Loader message='loading golfer activities' />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  checkUserAvatar(user) {
    if (user.avatar) {
      return (
        <img
          src={user.avatar.file_url}
          alt='Name'
          className='img-responsive rounded-circle w-100 center-block'
        />
      )
    } else {
      return (
        <img
          src={StockProfilePic}
          alt='Name'
          className='img-responsive rounded-circle w-100 center-block'
        />
      )
    }
  }

  navigateTo = (user) => {
    this.props.navigate(
      `/profile/${user.id}`,
      { state: { userId: user.id } }
    )
  }

  displayGolfers() {
    if (this.props.family && this.props.family.users) {
      return this.props.family.users.map((user, index) => {
        if (!user.family_admin) {
          return (
            <div key={'golferRow' + user.id} className='row border-bottom py-2'>
              <div className='col-2'>
                <button
                  className='btn-link border-0'
                  onClick={() => this.navigateTo(user)}
                >
                  {this.checkUserAvatar(user)}
                </button>
              </div>
              <div className='col-10 p-0'>
                <h4 className='my-3'>
                  {user.first_name + ' ' + user.last_name}
                </h4>
              </div>
              <hr />
            </div>
          )
        } else {
          return null
        }
      })
    }
    return
  }

  displayEnrollments() {
    const { enrollments } = this.props
    if (enrollments && enrollments.length > 0) {
      return enrollments.map((enrollment, index) => {
        if (enrollment.course) {
          return (
            <div
              key={'enrollmentrow' + enrollment.id}
              className='row border-bottom py-2'
            >
              <div className='col-2'>
                <button
                  className='btn-link border-0'
                  onClick={() => this.navigateTo(enrollment.user)}
                >
                  {this.checkUserAvatar(enrollment.user)}
                </button>
              </div>
              <div className='col-10 p-0'>
                <h4>
                  {enrollment.user.first_name + ' ' + enrollment.user.last_name}
                </h4>
                <strong>
                  <h6>{enrollment.course.name}</h6>
                </strong>
                <h6>{enrollment.course.meeting_times}</h6>
                <span
                  className={
                    'badge ' +
                    this.renderPillStyle(enrollment.status) +
                    ' badge-pill'
                  }
                >
                  {enrollment.status}
                </span>
              </div>
              <hr />
            </div>
          )
        } else {
          return null
        }
      })
    } else {
      return null
    }
  }

  renderPillStyle(status) {
    switch (status) {
      case 'active':
        return 'badge-success'
      case 'applied':
        return 'badge-warning'
      case 'inactive':
        return 'badge-danger'
      default:
        return 'badge-success'
    }
  }

  displayPoints() {
    let points = 0
    if (this.props.user.type !== 'Coach') {
      this.props.user.family.users.forEach((user) => {
        points += user.points
      })
    } else {
      points = this.props.stats ? this.props.stats.points : ''
    }
    return points
  }

  displayDashboardStatsWidgets = () => {
    if (
      this.props.user.type !== 'Coach' &&
      this.props.user.type !== 'Admin' &&
      this.props.user.family_admin
    ) {
      // dont show family widget for coach
      return this.displayFamilyWidgets()
    }
  }

  // displayCoachWidgets() {
  //   return (
  //     <div className='sticky-widgets'>
  //       <CoachWidgets
  //         stats={this.props.stats}
  //         className={this.state.className}
  //         scrolled={this.state.scrolled}
  //         loader={this.state.statLoader}
  //       />
  //     </div>
  //   );
  // }

  displayFamilyWidgets() {
    return (
      <div>
        <div className='card mt-15px'>
          <h5 className='p-2 text-white primary-background'>
            Golfers
            <Link
              to='/family/true'
              className='btn btn-sm pull-right btn-primary'
            >
              <i className='fa fa-plus' aria-hidden='true' /> Add New
            </Link>
          </h5>
          <div className='card-block p-2'>{this.displayGolfers()}</div>
        </div>

        <div className='card mt-15px'>
          <h5 className='p-2 text-white primary-background'>
            Enrollments{' '}
            <Link to={'/programs'} className='btn btn-sm pull-right btn-primary'>
              <i className='fa fa-plus' aria-hidden='true' /> Add New
            </Link>
          </h5>
          <div className='card-block p-2'>
            {this.displayEnrollments()}
            {/*<p className="text-center pt-2"><Link className="btn btn-sm btn-dark text-white">View More</Link></p>*/}
          </div>
        </div>
      </div>
    )
  }

  closeAddGolferModal() {
    this.setState({
      showAddGolferModal: false,
    })
  }

  closeRegisterGolferModal() {
    this.setState({
      showRegisterGolferModal: false,
    })
  }

  closeDownloadAppModal() {
    this.setState({
      showDownloadAppModal: false,
    })
  }

  closePostActivityModal() {
    this.setState({
      showPostActivityModal: false,
    })
  }

  displayAddGolferModal() {
    return (
      <Modal
        isOpen={this.state.showAddGolferModal}
        onRequestClose={this.closeAddGolferModal}
        contentLabel='Add Golfer Modal'
      >
        <div className='modal-header row'>
          <div className='col-11'>
            <h5 className='modal-title display-4'>How To Add A Golfer</h5>
          </div>
          <div className='col-1'>
            <button
              type='button'
              className='close pull-right font-200'
              data-dismiss='modal'
              aria-label='Close'
              onClick={this.closeAddGolferModal}
            >
              <span aria-hidden='true'>✕</span>
            </button>
          </div>
        </div>
        <div className='modal-content container pt-3 pb-3'>
          <div className='text-center container modal-gif'>
            <img
              src='https://media.giphy.com/media/3ohBV5dQW52TOcuxtC/giphy.gif'
              alt='How To Add A Golfer'
            />
          </div>
        </div>
        <div className='modal-footer container'>
          <a
            className='btn btn-primary form-control onboarding-btn'
            href='/family'
          >
            Add A Golfer Now
          </a>
        </div>
      </Modal>
    )
  }

  displayRegisterGolferModal() {
    return (
      <Modal
        isOpen={this.state.showRegisterGolferModal}
        onRequestClose={this.closeRegisterGolferModal}
        contentLabel='Register Golfer Modal'
      >
        <div className='modal-header row'>
          <div className='col-11'>
            <h5 className='modal-title display-4'>How To Register A Golfer</h5>
          </div>
          <div className='col-1'>
            <button
              type='button'
              className='close pull-right font-200'
              data-dismiss='modal'
              aria-label='Close'
              onClick={this.closeRegisterGolferModal}
            >
              <span aria-hidden='true'>✕</span>
            </button>
          </div>
        </div>
        <div className='modal-content container pt-3 pb-3'>
          <div className='text-center container modal-gif'>
            <img
              src='https://media.giphy.com/media/l2ZE1V9HsPveP6ipG/giphy.gif'
              alt='How To Register A Golfer'
            />
          </div>
        </div>
        <div className='modal-footer container'>
          <a
            className='btn btn-primary form-control onboarding-btn'
            href='/programs'
          >
            Register A Golfer
          </a>
        </div>
      </Modal>
    )
  }

  displayDownloadAppModal() {
    return (
      <Modal
        isOpen={this.state.showDownloadAppModal}
        onRequestClose={this.closeDownloadAppModal}
        contentLabel='Download App Modal'
      >
        <div className='modal-header row'>
          <div className='col-11'>
            <h5 className='modal-title display-4'>
              Download The Operation 36 App
            </h5>
          </div>
          <div className='col-1'>
            <button
              type='button'
              className='close pull-right font-200'
              data-dismiss='modal'
              aria-label='Close'
              onClick={this.closeDownloadAppModal}
            >
              <span aria-hidden='true'>✕</span>
            </button>
          </div>
        </div>
        <div className='modal-content container pt-3 pb-3'>
          <div className='text-center container modal-gif-mobile'>
            <img
              src='https://media.giphy.com/media/l2ZE4yHaOORw0NAfm/giphy.gif'
              alt='Download The Operation 36 App'
            />
          </div>
        </div>
        <div className='modal-footer container justify-content-center d-flex'>
          <div className='row py-2'>
            <div className='col-12 text-center'>
              <a
                href='https://itunes.apple.com/us/app/op-36-golf/id1186959877?mt=8'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  src={AppleIcon}
                  alt='Operation36 on iOS'
                  className='app-icon'
                />
              </a>
              <a
                href='https://play.google.com/store/apps/details?id=com.operation36.app&hl=en'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  src={GoogleIcon}
                  alt='Operation36 on Google Play'
                  className='app-icon'
                />
              </a>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  displayPostActivityModal() {
    return (
      <Modal
        isOpen={this.state.showPostActivityModal}
        onRequestClose={this.closePostActivityModal}
        contentLabel='Post Activity Modal'
      >
        <div className='modal-header row'>
          <div className='col-11'>
            <h5 className='modal-title display-4'>
              Post First Activity As A Golfer
            </h5>
          </div>
          <div className='col-1'>
            <button
              type='button'
              className='close pull-right font-200'
              data-dismiss='modal'
              aria-label='Close'
              onClick={this.closePostActivityModal}
            >
              <span aria-hidden='true'>✕</span>
            </button>
          </div>
        </div>
        <div className='modal-content container pt-3 pb-3'>
          <div className='text-center container modal-gif-mobile'>
            <img
              src='https://media.giphy.com/media/xUOxeWlfa7eheHIR1u/giphy.gif'
              alt='Post First Activity As A Golfer'
            />
          </div>
        </div>
      </Modal>
    )
  }

  addClick() {
    this.setState({
      showAddGolferModal: true,
    })
  }

  registerClick() {
    this.setState({
      showRegisterGolferModal: true,
    })
  }

  downloadClick() {
    this.setState({
      showDownloadAppModal: true,
    })
  }

  activityClick() {
    this.setState({
      showPostActivityModal: true,
    })
  }

  renderTopDivisionCounts = () => this.divisions.map((div, index) => (
    <div className='col' key={index}>
      <div className='card p-2'>
        <h4>{this.calculateDivisionGolfers(div)}</h4>
        <h4>Level {index + 1}</h4>
        <div className='progress'>
          <div
            className={`progress-bar objective-rank-${index + 1}`}
            role='progressbar'
            style={{ width: `${this.calculateDivPercentage(div)}%` }}
            aria-valuenow='25'
            aria-valuemin='0'
            aria-valuemax='100'
          />
        </div>
      </div>
    </div>
  ))

  goToProgram = (id) => {
    this.props.navigate(
      `/programs/${id}`,
      { state: { id: id } }
    )
  }

  goToCreateProgram = () => {
    this.props.navigate('/programs')
  }

  /******
   *
   * CALCULATIONS FOR GOLFERS CREATED
   *
   *****/

  abbrNum = (number, decPlaces) => {
    decPlaces = Math.pow(10, decPlaces)

    const abbrev = ['k', 'm', 'b', 't']

    for (let i = abbrev.length - 1; i >= 0; i--) {
      const size = Math.pow(10, (i + 1) * 3)

      if (size <= number) {
        number = Math.round((number * decPlaces) / size) / decPlaces

        if (number === 1000 && i < abbrev.length - 1) {
          number = 1
          i++
        }

        number += abbrev[i]
        break
      }
    }

    return number
  }

  calculateTotalActivites = (stats) => {
    const total =
      stats.play_activities_count +
      stats.goal_activities_count +
      stats.social_activities_count +
      stats.social_activities_count +
      stats.train_activities_count +
      stats.rank_ups

    return this.abbrNum(total, 1)
  }

  calculateDivisionGolfers = (div) => {
    const { stats } = this.props

    if (stats) {
      if (div === 'fullTee') {
        return stats.plays_by_full_tee.length
      } else {
        return stats.plays_by_division[div].length
      }
    }
  }

  calculateDivPercentage = (div) => {
    const { students_count, plays_by_division, plays_by_full_tee } =
      this.props.stats

    if (div === 'fullTee') {
      return (plays_by_full_tee.length / students_count) * 100
    } else {
      return (plays_by_division[div].length / students_count) * 100
    }
  }

  incrementPage() {
    const stateCopy = Object.assign({}, this.state)
    const page = stateCopy.page + 1
    this.setState({
      page: page,
    })
  }

  setTimeSince() {
    this.setState({
      sinceTS: moment().unix(),
    })
  }

  loadPageActivities = (resolve) => {
    if (this.state.page === Constants.START_PAGE_DEFAULT) {
      this.setTimeSince()
    }

    if (this.props.user.type === 'Student' && this.props.user.family_admin) {
      this.props.actions
        .getFamilyActivities(
          this.props.user.family_id,
          this.state.page,
          PER_PAGE
        )
        .then((response) => {
          this.setState({
            userActivityLoader: false,
          })
          if (resolve) { resolve() }
        })
    } else if (
      this.props.user.type === 'Student' &&
      !this.props.user.family_admin
    ) {
      this.props.user_actions
        .getUserActivities(this.props.user.id)
        .then((res) => {
          if (res) {
            this.setState({
              userActivityLoader: false,
            })
            if (resolve) { resolve() }
          }
        })
    } else if (this.props.user.type === 'Coach') {
      // Activities already loaded in /coachDashboard/CommunityFeed.js
      return null
    } else if (this.props.user.type === 'Admin') {
      return null
    } else {
      return null
    }

    this.incrementPage()
  }

  renderItem(index, key) {
    if (this.props.activities) {
      if (
        index > PER_PAGE * (this.state.page - 1) - ROWS_FROM_BOTTOM_LOAD &&
        index > this.props.activities.length - ROWS_FROM_BOTTOM_LOAD
      ) {
        // If current index is at point to load more
        this.loadPageActivities()
      }

      if (this.props.activities) {
        const rowActivity = this.props.activities[index]
        if (rowActivity) {
          return (
            <Activity
              key={rowActivity.id}
              displayComments={false}
              activity={rowActivity}
              triggerReload={this.reloadStats}
              isStudentJourney
            />
          )
        }
      }
    }
  }
}

OldDashboardPage.propTypes = {
  user_actions: PropTypes.shape({
    getUserActivities: PropTypes.func,
    refreshCurrentUser: PropTypes.func,
  }),
  common_actions: PropTypes.shape({
    setCurrentTab: PropTypes.func,
    showSideBar: PropTypes.func,
    showNavBar: PropTypes.func,
    showTabBar: PropTypes.func,
  }),
  communities_actions: PropTypes.shape({
    getStats: PropTypes.func,
    getPipelineStats: PropTypes.func,
  }),
  notification_actions: PropTypes.shape({
    getUnreadNotifications: PropTypes.func,
  }),
  user: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    communities: PropTypes.array,
  }),
  navigate: PropTypes.func,
}

function mapStateToProps(state, ownProps) {
  return {
    ui: state.ui,
    user: state.user.current_user,
    activities: state.activities,
    user_activities: state.user.user_activities,
    family: state.family,
    family_stats: state.family.family_stats,
    enrollments: state.familyEnrollments,
    stats: state.communities.stats,
    pipeline_stats: state.communities.pipeline_stats,
    courses: state.courses.courses,
    notifications: state.notifications,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(activityActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    common_actions: bindActionCreators(commonActions, dispatch),
    family_actions: bindActionCreators(familyActions, dispatch),
    course_actions: bindActionCreators(courseActions, dispatch),
    communities_actions: bindActionCreators(communitiesActions, dispatch),
    notification_actions: bindActionCreators(notificationActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouting(OldDashboardPage))
