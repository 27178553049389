import ReactToggle from 'react-toggle'
import PropTypes from 'prop-types'

import './Toggle.scss'
import classNames from 'classnames'

const SIZES = ['small', 'medium', 'large']

const Toggle = ({ size, className, ...restProps }) => {
  const size_ = SIZES.includes(size) ? size : 'medium'

  return (
    <ReactToggle
      icons={false}
      className={classNames('op36-toggle', size_, className)}
      {...restProps}
    />
  )
}

Toggle.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
}

export default Toggle
