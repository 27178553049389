import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { setSelectedFamilyMember } from '../../../../reducers/programRegistrationReducer'
import * as Constants from '../../../../misc/Constants'
import MCElement from '../../../common/MCElement'
import { components } from 'react-select'
import { createUser } from '../../../../actions/authActions'
import Select from '../../../common/form/Select'
import SubmitButton from '../../../common/buttons/SubmitButton'
import NewFamilyMemberModal from './NewFamilyMemberModal'

import './FamilyMemberSelection.scss'
import colors from '../../../../assets/styles/globals.scss'
import AvatarPlaceholder from '../../../../assets/images/mock/StockProfilePic.png'

const FamilyMemberSelection = () => {
  const dispatch = useDispatch()
  const { status, selectedFamilyMember } = useSelector(state => state.programRegistration)
  const { familyMembers, coursePackages } = useSelector(state => state.programRegistration.enrollmentData)
  const [isModalOpen, setModalOpen] = useState(false)

  const handleSelectFamilyMember = (familyMember) => {
    if (!familyMember.enrollmentStatus && selectedFamilyMember?.id !== familyMember.id) {
      dispatch(setSelectedFamilyMember(familyMember))
    }
  }

  const openModalAction = () => { setModalOpen(true) }

  const closeModalAction = () => { setModalOpen(false) }

  const displayFamilyMembers = () => familyMembers.map((familyMember) => {
    const isSelected = selectedFamilyMember?.id === familyMember.id

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div
        key={familyMember.id}
        className={classNames('d-flex align-items-center family-member-card', { 'disabled': !!familyMember.enrollmentStatus })}
        onClick={() => handleSelectFamilyMember(familyMember)}
        title={`${familyMember.firstName} ${familyMember.lastName}`}
      >
        <MCElement
          type='radio'
          primaryColor={isSelected ? colors.secondaryColor : colors.lightNavy}
          isSelected={isSelected}
          onKeyDown={(e) => {
            e.key === 'Enter' && handleSelectFamilyMember(familyMember)
          }}
          disabled={!!familyMember.enrollmentStatus}
        />
        <div className='ml-2 small-round-image-wrapper dark-navy-border'>
          <img
            className='round-image'
            src={familyMember.avatar?.fileUrl ? familyMember.avatar.fileUrl : AvatarPlaceholder}
            alt='new user'
          />
        </div>
        <span className='family-member-name'>{`${familyMember.firstName} ${familyMember.lastName}`}</span>
        {familyMember.enrollmentStatus && (
          <span className={`family-member-enrollment-status ${familyMember.enrollmentStatus === 'applied' ? 'request-sent' : 'enrolled'}`}>
            {Constants.ENROLLMENT_STATUS[familyMember.enrollmentStatus]}
          </span>
        )}
      </div>
    )
  })

  const areAllOptionsDisabled = familyMembers.every(fm => !!fm.enrollmentStatus)

  return (
    <div id='FamilyMemberSelection'>
      <NewFamilyMemberModal
        isOpen={isModalOpen}
        onClose={closeModalAction}
        handleCreate={(userData) => dispatch(createUser(userData)).unwrap()}
        loader={status === 'creating user'}
      />
      <h5 className='step-label'>Select a family member:</h5>
      {coursePackages.length === 0 ? (
        <>
          {displayFamilyMembers()}
          <div id='familyMembersSelectionFooter' className='d-flex flex-column'>
            <p className='mt-3 footer-text'>
              {areAllOptionsDisabled ? (
                'All your family members are already registered to this class. ' +
                'You must create a new family member in order to continue the registration process. You can do this here:'
              ) : (
                'Can’t find your family member?'
              )}
            </p>
            <SubmitButton
              noTopMargin
              onClick={openModalAction}
              buttonMessage='Add family member'
              iconType='addButton'
              className='mb-3'
            />
          </div>
        </>
      ) : (
        <Select
          isSearchable={false}
          isOptionDisabled={(option) => !!option?.enrollmentStatus}
          placeholder='See Family Members'
          options={familyMembers}
          value={selectedFamilyMember}
          onChange={familyMember => handleSelectFamilyMember(familyMember)}
          styles={{
            control: (provided) => ({
              ...provided,
              height: '70px !important',
              maxWidth: '350px !important',
            }),
            placeholder: provided => ({
              ...provided, color: colors.primaryNavy,
            }),
            valueContainer: provided => ({
              ...provided, height: '70px', fontSize: '1.25em !important',
            }),
            menu: provided => ({
              ...provided, borderRadius: '8px', maxHeight: '310px', maxWidth: '350px',
            }),
            menuList: provided => ({
              ...provided, maxHeight: '310px !important',
            }),
            option: (provided, state) => ({
              ...provided,
              color: null,
              backgroundColor: 'white',
              '&:hover': {
                backgroundColor: state.isDisabled ? 'white' : colors.disableColor,
              },
              cursor: state.isDisabled ? 'not-allowed' : 'pointer',
            }),
          }}
          components={{
            Option: FamilyMembersOption,
            SingleValue: FamilyMembersSingleValue,
            MenuList: (props) => <FamilyMembersMenuList onClick={openModalAction} {...props} />,
          }}
        />
      )}
    </div>
  )
}

// Disabling prop-types linter error because these components are only used locally
/* eslint-disable react/prop-types */
const FamilyMembersOption = ({ data, ...props }) => (
  <components.Option {...props}>
    <div
      className={classNames('d-flex align-items-center family-member-card', { 'disabled': !!data.enrollmentStatus })}
      title={`${data.firstName} ${data.lastName}`}
    >
      <div className='small-round-image-wrapper dark-navy-border'>
        <img
          className='round-image'
          src={data.avatar?.fileUrl ? data.avatar.fileUrl : AvatarPlaceholder}
          alt='new user'
        />
      </div>
      <span className='family-member-name'>{`${data.firstName} ${data.lastName}`}</span>
      {data.enrollmentStatus && (
        <span className={`family-member-enrollment-status ${data.enrollmentStatus === 'applied' ? 'request-sent' : 'enrolled'}`}>
          {Constants.ENROLLMENT_STATUS[data.enrollmentStatus]}
        </span>
      )}
    </div>
  </components.Option>
)

const FamilyMembersSingleValue = ({ data, ...props }) => (
  <components.SingleValue {...props}>
    <div
      className='d-flex align-items-center family-member-card'
      title={`${data.firstName} ${data.lastName}`}
    >
      <div className='mr-2 medium-round-image-wrapper dark-navy-border'>
        <img
          className='round-image'
          src={data.avatar?.fileUrl ? data.avatar.fileUrl : AvatarPlaceholder}
          alt='selected user'
        />
      </div>
      <span className='family-member-name'>{`${data.firstName} ${data.lastName}`}</span>
      {data.enrollmentStatus && (
        <span className={`family-member-enrollment-status ${data.enrollmentStatus === 'applied' ? 'request-sent' : 'enrolled'}`}>
          {Constants.ENROLLMENT_STATUS[data.enrollmentStatus]}
        </span>
      )}
    </div>
  </components.SingleValue>
)

const FamilyMembersMenuList = ({ onClick, children, options, ...props }) => {
  const areAllOptionsDisabled = options.every(option => !!option.enrollmentStatus)

  return (
    <components.MenuList options={options} {...props}>
      {children}
      <div id='familyMembersSelectionFooter' className='mx-3 d-flex flex-column'>
        <p className='mt-3 text-center footer-text'>
          {areAllOptionsDisabled ? (
            'All your family members are already registered to this class. ' +
            'You must create a new family member in order to continue the registration process. You can do this here:'
          ) : (
            'Can’t find your family member?'
          )}
        </p>
        <SubmitButton
          noTopMargin
          onClick={onClick}
          buttonMessage='Add family member'
          iconType='addButton'
          className='mb-3'
        />
      </div>
    </components.MenuList>
  )
}
/* eslint-enable react/prop-types */

export default FamilyMemberSelection
