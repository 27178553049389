import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import { resetAddress } from '../../../../../reducers/studentDashboardReducer'
import { ReactComponent as PinIcon } from '../../../../../assets/images/common/icons/pin-icon.svg'
import CommunityCard from '../../../../community-map/CommunityCard'

import NoNearbyCommunities from '../../../../community-map/NoNearbyCommunities'
import ExploreLocationsCard from './ExploreLocationsCard'
import RequestLocationCard from './RequestLocationCard'

import classes from './Facilities.module.scss'

const Facilities = () => {
  const dispatch = useDispatch()
  const { address, nearbyCommunities } = useSelector(state => state.studentDashboard)

  const sortedNearbyCommunities = useMemo(() => (
    nearbyCommunities.toSorted((a, b) => a.distance.value - b.distance.value)
  ), [nearbyCommunities])

  return (
    <div className={classes.facilities}>
      <header className={classNames(classes.addressHeader, 'd-flex align-items-center')}>
        <PinIcon className={classes.pinIcon} />
        <span className={classNames(classes.addressText, 'ml-3 mr-2')}>My Current Location: {address}</span>
        <button className={classNames(classes.addressChangeButton, 'btn btn-primary ml-auto px-2 px-md-4')} onClick={() => dispatch(resetAddress())}>
          Change
        </button>
      </header>

      <main className={classNames(classes.facilitiesList, 'mt-3')}>
        {sortedNearbyCommunities.length === 0 ? (
          <div className='d-flex flex-column align-items-center'>
            <NoNearbyCommunities address={address} />

            <article className='mt-4'>
              <ExploreLocationsCard />
            </article>
          </div>
        ) : (
          <div>
            <h5 className={classNames(classes.title, 'mb-3')}>Closest Facilities to you:</h5>
            <div className='row'>
              {sortedNearbyCommunities.map((community, index) => (
                <div key={index} className='col-12 col-md-6 col-lg-4 mb-4'>
                  <CommunityCard community={community} />
                </div>
              ))}
            </div>

            <section className='row'>
              <article className='col-12 col-md-8 mb-4 mb-md-0'>
                <ExploreLocationsCard />
              </article>

              <article className='col-12 col-md-4'>
                <RequestLocationCard />
              </article>
            </section>
          </div>
        )}
      </main>
    </div>
  )
}

export default Facilities
