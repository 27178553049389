import PropTypes from 'prop-types'
import classNames from 'classnames'

import classes from './ProgressBar.module.scss'

const ProgressBar = ({ step, label }) => {
  const stepClass = (step === 1 && classes.firstStep) ||
    (step === 3 && classes.lastStep) ||
    classes.otherStep

  return (
    <div>
      <nav className={classNames(classes.progressBar, 'd-flex align-items-center')}>
        <div className={classNames(classes.backgroundLine, stepClass)}/>

        <div className={classNames(classes.outerCircle, stepClass, 'd-flex justify-content-center align-items-center')}>
          <div className={classNames(classes.innerCircle, 'd-flex justify-content-center align-items-center')}>
            {step}
          </div>
        </div>
      </nav>

      <div className={classNames(classes.labelWrapper, stepClass)}>
        <label className={classes.stepLabel}>
          {label}
        </label>
      </div>
    </div>
  )
}

ProgressBar.propTypes = {
  step: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
}

export default ProgressBar
