import React from 'react'
import PropTypes from 'prop-types'

import Notification from './Notification'
import ActivityLoader from 'react-loaders'

import './NotificationsPopover.scss'

const displayNotifications = (notifications, navigateFromNotification) => {
  if (notifications && notifications.length > 0) {
    return notifications.map(notification => (
      <Notification
        notification={notification}
        navigateFromNotification={navigateFromNotification}
        key={notification.id}
      />
    ))
  }
  return (
    <p className='notifications-empty-text'>
      No notifications to show right now
    </p>
  )
}

const NotificationPopover = ({
  notifications,
  refreshNotifications,
  clearNotifications,
  loader,
  navigateFromNotification,
}) => (
  <div id='NotificationsContainer'>
    {loader ? (
      <div className='p-4 animated fadeIn'>
        <ActivityLoader className='notifications-loader' type='ball-pulse' color={'lightgrey'} />
      </div>
    ) : (
      <div className='notifications-wrapper'>
        <div className='notifications-button-container'>
          {notifications && notifications.length > 0 && (
            <button
              className='btn btn-link notifications-clear-btn'
              onClick={clearNotifications}
            >
                Clear Notifications
            </button>
          )}
          <button
            className='btn btn-link notifications-refresh-btn'
            onClick={refreshNotifications}
          >
            <i className='fa fa-refresh' />
          </button>
        </div>
        {displayNotifications(notifications, navigateFromNotification)}
      </div>
    )}
  </div>
)

NotificationPopover.propTypes = {
  notifications: PropTypes.array,
  refreshNotifications: PropTypes.func,
  clearNotifications: PropTypes.func,
  loader: PropTypes.bool,
  navigateFromNotification: PropTypes.func,
}

export default NotificationPopover
