import { useState } from 'react'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

import { ReactComponent as CircleLogo } from '../../../../assets/images/common/icons/circle-logo.svg'
import { triggerResetPassword } from '../../../../actions/authActions'
import Loader from '../../../common/Op36Loader'
import SubmitButton from '../../../common/buttons/SubmitButton'
import ButtonLink from '../../../common/buttons/ButtonLink'
import FormInput from '../../SignUp/Steps/Helpers/FormInput'
import FieldError from '../../../common/FieldError'

import classes from './SendEmail.module.scss'

export const STEPS = {
  EMAIL_INPUT: 1,
  EMAIL_PENDING: 2,
  EMAIL_SENT: 3,
}

const SendEmail = () => {
  const dispatch = useDispatch()

  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [step, setStep] = useState(STEPS.EMAIL_INPUT)

  const handleResetPassword = () => {
    setStep(STEPS.EMAIL_PENDING)

    dispatch(triggerResetPassword(email))
      .then((_) => setStep(STEPS.EMAIL_SENT))
      .catch(() => {
        setError('Something went wrong')
        setStep(STEPS.EMAIL_INPUT)
      })
  }

  return (
    <div className={classes.sendEmail}>
      {step === STEPS.EMAIL_INPUT && (
        <div className={classNames(classes.emailInput, 'd-flex flex-column justify-content-center align-items-center h-100 w-100')}>
          <header className='d-flex flex-column justify-content-center align-items-center'>
            <CircleLogo className={classes.circleLogo} />
            <h2 className={classes.titleText}>Forgot Password?</h2>
            <h5 className={classes.subTitleText}>Enter your email or username to reset password</h5>
          </header>

          <form className='row w-100'>
            <div className='col-12 col-lg-6 offset-lg-3'>
              {error && <FieldError message={error} style={{ paddingBottom: '5px' }} general/>}

              <label htmlFor='email' className={classes.inputLabel}>
                Email or Username <span className='required-text'>*</span>
              </label>

              <FormInput
                name='email'
                type='text'
                onChange={(e) => { setEmail(e.target.value); setError('') }}
                value={email}
                symbol={<i className={classNames(classes.inputSymbol, 'fa fa-envelope')}/>}
              />

              <SubmitButton
                onClick={(e) => { e.preventDefault(); handleResetPassword() }}
                buttonMessage='Reset Password'
                buttonColor='orange-button'
                className={classes.resetPasswordButton}
                disabled={!email}
              />
            </div>
          </form>
        </div>
      )}

      {step === STEPS.EMAIL_PENDING && (
        <div className={classNames(classes.emailPending, 'd-flex flex-column justify-content-center align-items-start h-100 w-100')}>
          <Loader message='Sending Email' fullScreen='false' color='white' />
        </div>
      )}

      {step === STEPS.EMAIL_SENT && (
        <div className={classNames(classes.emailSent, 'd-flex flex-column justify-content-center align-items-center  h-100 w-100')}>
          <header className='d-flex flex-column justify-content-center align-items-center'>
            <CircleLogo className={classes.circleLogo} />
            <h2 className={classes.titleText}>Email has been sent!</h2>
            <h5 className={classes.subTitleText}>Please check your inbox to reset your password. Thank you!</h5>
          </header>

          <ButtonLink navigateTo='/log-in' className={classNames(classes.actionLink, 'mt-4 mb-5 mb-md-0')}>
            Back to Log In
          </ButtonLink>
        </div>
      )}
    </div>
  )
}

export default SendEmail
