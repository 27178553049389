import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { differenceInYears } from 'date-fns'
import classNames from 'classnames'

import { setEditingUserField } from '../../../../reducers/signUpReducer'
import { GENDER_SELECT_OPTIONS } from '../../../../misc/Constants'
import StepButton from './Helpers/StepButton'
import FormInput from './Helpers/FormInput'
import FormSelect from './Helpers/FormSelect'

import classes from './NewMemberAboutData.module.scss'

// eslint-disable-next-line max-lines-per-function
const NewMemberAboutData = () => {
  const dispatch = useDispatch()

  const {
    errors,
    familyAdmin,
    firstName,
    lastName,
    birthDate,
    gender,
    phone,
  } = useSelector(state => state.signUp.editingUser)

  const formInvalid = useMemo(() => {
    const anyEmptyFields = !firstName || !lastName || !birthDate || gender === null

    return anyEmptyFields
  }, [firstName, lastName, birthDate, gender])

  const handleInputChange = e => {
    const field = e.target.name
    const value = e.target.value

    dispatch(setEditingUserField({ field, value }))
  }

  return (
    <div className={classNames(classes.newMemberAboutData, 'd-flex flex-column')}>
      <form className={classNames(classes.mainContent, 'd-flex flex-column align-items-center justify-content-start')}>
        <div className='w-100'>
          <h1 className={classes.titleText}>Add Family Member</h1>

          <h4 className={classes.subtitleText}>
            Create an account for your other family members. You can manage these accounts,
            but have your kids have personal golfer accounts as well.
          </h4>
        </div>

        <div className='w-100'>
          <section className={classes.familySwitchWrapper}>
            <button
              type='button'
              className={classNames('d-flex justify-content-center align-items-center', classes.familySwitchButton)}
              onClick={() => handleInputChange({ target: { name: 'familyAdmin', value: !familyAdmin } })}
            >
              <span className={classNames(classes.switchOption, { [classes.switchSelected]: !familyAdmin })}>Standard Golfer</span>
              <span className={classNames(classes.switchOption, { [classes.switchSelected]: familyAdmin })}>Family Admin</span>
            </button>

            <p className={classes.familySwitchText}>
              {familyAdmin
                ? 'This user can create, access and manage the accounts of their Standard Golfer family members.'
                : 'This user can only access and manage their own account.'
              }
            </p>
          </section>

          <section className='row mb-2'>
            <div className='col-12'>
              <h3 className={classes.inputSection}>About</h3>
            </div>

            <div className='col-12 col-lg-6'>

              <label htmlFor='firstName' className={classes.inputLabel}>
                First Name <span className='required-text'>*</span>
              </label>

              <FormInput
                name='firstName'
                type='text'
                onChange={handleInputChange}
                value={firstName}
                error={errors.firstName}
                symbol={<i className={classNames(classes.inputSymbol, 'fa fa-user')}/>}
              />

              <label htmlFor='lastName' className={classes.inputLabel}>
                Last Name <span className='required-text'>*</span>
              </label>

              <FormInput
                name='lastName'
                type='text'
                onChange={handleInputChange}
                value={lastName}
                error={errors.lastName}
                symbol={<i className={classNames(classes.inputSymbol, 'fa fa-user')}/>}
              />

              <label htmlFor='phone' className={classes.inputLabel}>
                Phone Number
              </label>

              <FormInput
                name='phone'
                type='telephone'
                onChange={(e) => handleInputChange({ target: { name: 'phone', value: e } })}
                value={phone}
                error={errors.phone}
              />
            </div>

            <div className='col-12 col-lg-6'>
              <label htmlFor='birthDate' className={classes.inputLabel}>
                Birthdate <span className='required-text'>*</span>
              </label>

              <FormInput
                name='birthDate'
                type='date'
                onChange={(e) => {
                  handleInputChange({ target: { name: 'birthDate', value: e } })
                  handleInputChange({ target: { name: 'age', value: differenceInYears(new Date(), new Date(e)) } })
                }}
                maxDate={new Date()}
                placeholder='MM/DD/YYYY'
                value={birthDate}
                symbol={<i className={classNames(classes.inputSymbol, 'fa fa-calendar')}/>}
                error={errors.birthDate}
                autoComplete='off'
              />

              <label htmlFor='gender' className={classes.inputLabel}>
                Gender <span className='required-text'>*</span>
              </label>

              <FormSelect
                id='gender'
                isSearchable={false}
                placeholder='Choose Gender'
                value={GENDER_SELECT_OPTIONS.find((g) => g.value === gender)}
                onChange={(e) => handleInputChange({ target: { name: 'gender', value: e.value } })}
                options={GENDER_SELECT_OPTIONS}
              />
            </div>
          </section>
        </div>

        <StepButton color='orange' message='Continue' newStep={6} disabled={formInvalid}/>
        <StepButton color='navy' message='Cancel' newStep={4}/>
      </form>
    </div>
  )
}

export default NewMemberAboutData
