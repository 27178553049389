import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import { ReactComponent as Op36Icon } from '../../assets/images/common/icons/sidebarMenu/Op36.svg'
import DropdownIcon from '../../assets/images/common/icons/select-drop-down-icon-white.svg'
import StockProfilePic from '../../assets/images/mock/StockProfilePic.png'

import './CommunityHeader.scss'

const CommunityHeader = () => {
  const user = useSelector(state => state.user.current_user)

  return (
    <div className='community-top-header'>
      <a href='https://operation36.golf' target='_blank'>
        <Op36Icon className='op36Icon'/>
      </a>

      {user && (
        <>
          <div className='avatarContainer d-flex align-items-center ml-auto' data-for='userTooltip' data-tip>
            <div className='small-round-image-wrapper avatarWrapper'>
              <img
                className='round-image'
                src={user.avatar ? user.avatar.file_url : StockProfilePic}
                alt={`${user.first_name} ${user.last_name}`}
              />
            </div>

            <img src={DropdownIcon} alt='Dropdown Arrow' className='dropDownArrowIcon' />
          </div>

          <ReactTooltip
            id='userTooltip'
            offset={{ top: '4', left: '2' }}
            effect='solid'
            place='bottom'
            delayUpdate={100}
            delayHide={100}
            className='tooltipWrapper'
          >
            <div className='userTooltip d-flex flex-column p-0'>
              <Link to='/dashboard'>Dashboard</Link>
              <Link to='/log-in' className='logOut'>Log Out</Link>
            </div>
          </ReactTooltip>
        </>
      )}
    </div>
  )
}

export default CommunityHeader
