import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import moment from 'moment'

import * as familyActions from '../../../../../actions/familyActions.js'
import { deserialize } from '../../../../../utility/serialization'
import { displayWithCurrency } from '../../../../../utility/currency'
import { ReactComponent as MoneyCheckDollarIcon } from '../../../../../assets/images/common/icons/money-check-dollar-icon.svg'
import { ReactComponent as StripeIcon } from '../../../../../assets/images/common/icons/stripe-icon.svg'
import { ReactComponent as GolferIcon } from '../../../../../assets/images/common/icons/golfer-half-icon.svg'
import AvatarPlaceholder from '../../../../../assets/images/mock/default-profile-icon.png'
import SmallMilestoneIcon from '../../../../common/SmallMilestoneIcon.js'
import Loader from '../../../../common/Op36Loader.js'
import Tooltip from '../../../../common/Tooltip'
import TooltipText from '../../../../common/TooltipText'
import CommunityCard from '../../../../community-map/CommunityCard'

import classes from './EnrolledFamilyView.module.scss'

const EnrolledFamilyView = () => {
  const dispatch = useDispatch()

  const user = useSelector(state => state.user.current_user)
  const enrollments = useSelector(state => state.familyEnrollments)
  const [completed, setCompleted] = useState(false)
  const [loader, setLoader] = useState(true)

  const fetchEnrollments = useCallback(() => {
    setLoader(true)
    dispatch(familyActions.getFamilyEnrollments(user.family.id, user.family_admin ? null : user.id, completed ? 'completed' : null))
      .finally(() => setLoader(false))
  }, [dispatch, user.family.id, user.family_admin, user.id, completed])

  useEffect(() => {
    fetchEnrollments()
  }, [completed, fetchEnrollments])

  return (
    <div className={classes.enrolledFamilyView}>
      <section>
        <h5 className={classNames(classes.title, 'mb-3')}>My Communities</h5>

        <div className='row'>
          {user.communities.map((community, index) => (
            <div key={index} className='col-12 col-md-6 col-lg-4 mb-4'>
              <CommunityCard community={deserialize(community)} />
            </div>
          ))}
        </div>
      </section>

      <section className={classes.enrollmentsList}>
        <h5 className={classNames(classes.title, 'mb-3')}>Enrollments & Transactions</h5>

        <div className={classes.enrollmentsWrapper}>
          <section className={classNames(classes.completedSwitchWrapper, 'mt-2 mb-4')}>
            <button
              type='button'
              className={classNames('d-flex justify-content-center align-items-center', classes.completedSwitchButton)}
              onClick={() => setCompleted((prev) => !prev)}
            >
              <span className={classNames(classes.switchOption, { [classes.switchSelected]: !completed })}>Active</span>
              <span className={classNames(classes.switchOption, { [classes.switchSelected]: completed })}>Completed</span>
            </button>
          </section>

          {loader ? (
            <Loader message='loading enrollments' />
          ) : (
            <>
              {enrollments.length === 0 ? (
                <div className='d-flex flex-column align-items-center'>
                  <GolferIcon className={classNames(classes.golferIcon, 'mb-2')} />
                  <h5 className={classes.title}>No {completed ? 'Completed' : 'Active'} Enrollments</h5>
                </div>
              ) : (
                <div className={classNames(classes.enrollmentsTable, 'table-responsive')}>
                  <Tooltip offset={{ top: '-5' }} />
                  <table className='table table-borderless w-100 mb-0'>
                    <tbody className='table-body'>{enrollments.map((enrollment) =>
                      <tr key={enrollment.id}>
                        <td className={classes.golferInfo}>
                          <div className='d-flex align-items-center mt-1'>
                            <Link to={`/profile/${enrollment.user.id}`} className={classes.avatarWrapper}>
                              <div className={classNames(classes.golferAvatar, 'small-round-image-wrapper')}>
                                <img
                                  className='round-image'
                                  src={enrollment.user.avatar?.file_url || AvatarPlaceholder}
                                  alt='User Avatar'
                                />
                              </div>

                              <div className={classNames(classes.divisionWrapper)}>
                                <SmallMilestoneIcon division={enrollment.user.current_formal_division || 1} fromAvatar />
                              </div>
                            </Link>

                            <div className={classNames(classes.golferInfo, 'ml-4')}>
                              <TooltipText text={`${enrollment.user.first_name} ${enrollment.user.last_name}`} className={classes.golferName} />
                              <TooltipText text={enrollment.user.username || enrollment.user.email} className={classes.golferIdentification} />
                            </div>
                          </div>
                        </td>
                        <td className={classes.programInfo}>
                          <TooltipText className={classes.programName} text={enrollment.course.name} />

                          <div className={classNames(classes.communityWrapper, 'd-flex align-items-center')}>
                            <article className={classNames(classes.communityPhoto, 'smaller-round-image-wrapper')}>
                              <img className='round-image' src={enrollment.community.photo.original_file_url} alt='community avatar' />
                            </article>


                            <TooltipText className={classes.communityName} text={enrollment.community.name} />
                          </div>
                        </td>
                        <td className={classes.packageInfo}>
                          {enrollment.package ? (
                            <div className='d-flex align-items-center'>
                              <span className={classes.packageAmount}>
                                {enrollment.package && displayWithCurrency(enrollment.transaction_amount, enrollment.currency)}
                              </span>
                              <span className='mx-1'>-</span>
                              <TooltipText className={classes.packageName} text={enrollment.package.name} />
                            </div>
                          ) : (
                            <TooltipText className={classes.packageName} text='No Package' />
                          )}

                          <div className={classNames(classes.paymentMethod, 'mt-1')}>
                            {enrollment.payment_method === 'card' ? (
                              <div className={classNames(classes.onlinePayment, 'd-flex align-items-center')}>
                                <StripeIcon className={classNames(classes.stripeIcon, 'mr-1')} />
                                <span className={classNames(classes.paymentText, 'd-none d-md-block mt-auto')}>Online Payment</span>
                              </div>
                            ) : (
                              <div className={classNames(classes.offlinePayment, 'd-flex align-items-center')}>
                                <MoneyCheckDollarIcon className={classNames(classes.moneyCheckDollarIcon, 'mr-1')} />
                                <span className={classNames(classes.paymentText, 'd-none d-md-block mt-auto')}>Offline Payment</span>
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                          <span>{moment(enrollment.created_at).format('MM/DD/YYYY')}</span>
                        </td>
                      </tr>
                    )}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </div>
      </section>
    </div>
  )
}

export default EnrolledFamilyView
