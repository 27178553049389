import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import * as notificationActions from '../../../actions/notificationActions.js'

import Header from './Header/Header'
import HomeTab from './Home/HomeTab'
import ProgramsTab from './Programs/ProgramsTab'
import AccountTab from './Account/AccountTab.js'

import classes from './StudentDashboardPage.module.scss'

const StudentDashboardPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const user = useSelector(state => state.user.current_user)

  const [tabIndex, setTabIndex] = useState(0)

  useEffect(() => {
    dispatch(notificationActions.getUnreadNotifications(user.id))

    if (location.pathname.includes('/programs')) {
      setTabIndex(1)
    } else if (location.pathname.includes('/account')) {
      setTabIndex(2)
    } else {
      setTabIndex(0)
    }
  }, [dispatch, user.id, location])

  const tabNavigation = (index) => {
    switch (index) {
      case 1:
        return navigate('/dashboard/programs')
      case 2:
        return navigate('/dashboard/account')
      default:
        return navigate('/dashboard')
    }
  }

  return (
    <div className={classes.studentDashboardPage}>
      <Header />

      <Tabs
        className={classes.tabs}
        selectedIndex={tabIndex}
        onSelect={tabNavigation}
      >
        <TabList className={classes.tabList}>
          <Tab className={`${classes.tab} react-tabs__tab`} selectedClassName={classes.activeTab}>Home</Tab>
          <Tab className={`${classes.tab} react-tabs__tab`} selectedClassName={classes.activeTab}>Explore Programs</Tab>
          <Tab className={`${classes.tab} react-tabs__tab`} selectedClassName={classes.activeTab}>My Account</Tab>
        </TabList>
        <TabPanel>
          <HomeTab />
        </TabPanel>
        <TabPanel>
          <ProgramsTab />
        </TabPanel>
        <TabPanel>
          <AccountTab/>
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default StudentDashboardPage
