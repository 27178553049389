import * as types from '../actions/actionTypes'

export default function communityStudents(state = { students: [], pagination: {}, loading: true }, action){
  switch (action.type){

    case types.STUDENTS_LOADING:
      return {
        ...state,
        loading: action.loading,
      }

    case types.GET_COMMUNITY_STUDENTS:
      if (action.pagination.type === 'infiniteScroll' && action.pagination.page > 1) {
        return {
          ...state,
          students: [...state.students, ...action.students],
          pagination: action.pagination,
        }
      } else {
        return {
          ...state,
          students: action.students,
          pagination: action.pagination,
        }
      }


    default:
      return state
  }
}
