import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Collapse } from 'reactstrap'
import { UncontrolledPopover } from 'reactstrap'
import PropTypes from 'prop-types'

import * as constants from '../../misc/Constants'
import SeriesRow from './SeriesRow'
import Placeholder from '../common/Placeholder'
import Loader from '../common/Op36Loader-web'
import Pagination from '../common/Pagination'
import SeriesEventRow from './SeriesEventRow'

import styles from './SeriesList.module.scss'
import colors from '../../assets/styles/globals.scss'
import { ReactComponent as GolferIcon } from '../../assets/images/common/icons/golfer-half-icon.svg'
import classNames from 'classnames'

const COMPLETED_SERIES_PLACEHOLDER =
'After you have completed each of the 9 Hole Events in your first series, it will be stored here for your records.'

const SeriesList = ({
  series,
  typeCompleted,
  loader,
  searchTerm,
  activePage,
  setActivePage,
  handleSeriesMenuClick,
  removeSeries,
  navigateToEdit,
  navigateToEmails,
  localScores,
}) => {
  const pageCount = useSelector(
    state => typeCompleted ? state.series?.completedSeriesPagination?.totalPages : state.series?.upcomingSeriesPagination?.totalPages
  )
  const [expandedSeriesId, setExpandedSeriesId] = useState(-1)

  useEffect(() => {
    if (!series?.length > 0) {
      return
    }

    setExpandedSeriesId(series[0].id)
  }, [series])

  const expandSeries = (id) => {
    if (expandedSeriesId === id) {
      setExpandedSeriesId(-1)
    } else {
      setExpandedSeriesId(id)
    }
  }

  if (loader) {
    return (
      <div className='col-md-6 mx-auto'>
        <Loader message='loading series' />
      </div>
    )
  }

  if (!series?.length) {
    if (searchTerm) {
      return (
        <div className='mt-5 w-100'>
          <Placeholder
            title={'No Series Results Found'}
            icon={'fa-search'}
            subtext1={'Nothing matched the search criteria'}
            subtext2={'Please try again'}
          />
        </div>
      )
    } else {
      return (
        <div className={styles.noSeriesPlaceholder}>
          <GolferIcon id='golferIcon' fill={colors.lightNavy} width={'64px'} height={'64px'}/>
          <p className={styles.placeholderTitle}>{typeCompleted ? 'No Completed 9 Hole Event Series' : 'No 9 Upcoming Events'}</p>
          <div className={styles.placeholderDescription}>{typeCompleted ? COMPLETED_SERIES_PLACEHOLDER : constants.EVENTS_PLACEHOLDER}</div>
        </div>
      )
    }
  }

  return (
    <>
      {pageCount > 1 && (
        <Pagination
          pageCount={pageCount}
          activePage={activePage}
          fetchData={(newPage) => setActivePage(newPage)}
        />
      )}
      <div className={pageCount > 1 ? styles.seriesListPaginated : styles.seriesList}>
        {series.map((series, index) => {
          const isExpanded = series.id === expandedSeriesId
          return (
            <div className={classNames('row mx-0', styles.seriesGroup, isExpanded && styles.expanded)} key={series.id}>
              <SeriesRow
                series={series}
                expandSeries={expandSeries}
                isFirstChild={index === 0}
                handleSeriesMenuClick={handleSeriesMenuClick}
              />
              <Collapse className='w-100' isOpen={isExpanded}>
                {series.events.map((e, index) =>
                  <SeriesEventRow key={e.id} event={{ ...e, index: index }} inProgress={localScores?.[e.id]?.['scores']}/>)
                }
              </Collapse>
              <UncontrolledPopover
                placement='bottom'
                target='seriesMenuButton'
                trigger='legacy'
              >
                <div className={classNames('d-flex flex-column', styles.seriesActions)}>
                  <p aria-hidden='true' onClick={() => navigateToEdit(1)}>Edit Event Series</p>
                  <p aria-hidden='true' onClick={() => navigateToEdit(2)}>Edit Series Schedule</p>
                  <p aria-hidden='true' onClick={() => navigateToEdit(3)}>Edit Master Roster</p>
                  <p aria-hidden='true' onClick={() => navigateToEmails()}>Send an Email</p>
                  <p aria-hidden='true' onClick={() => removeSeries()}>Delete Event Series</p>
                </div>
              </UncontrolledPopover>
            </div>
          )
        }
        )}
      </div>
    </>
  )
}

SeriesList.propTypes = {
  typeCompleted: PropTypes.bool,
  series: PropTypes.array,
  loader: PropTypes.bool,
  searchTerm: PropTypes.string,
  activePage: PropTypes.number,
  setActivePage: PropTypes.func,
  handleSeriesMenuClick: PropTypes.func,
  navigateToEdit: PropTypes.func,
  removeSeries: PropTypes.func,
  navigateToEmails: PropTypes.func,
  localScores: PropTypes.object,
}

export default SeriesList
