import { useSelector } from 'react-redux'
import * as Constants from '../../../misc/Constants'
import PropTypes from 'prop-types'

import StudentPickerCard from '../../students/StudentPickerCard/StudentPickerCard'
import Loader from '../../common/Op36Loader-web'
import InfiniteScroll from '../../infinite-scroll/InfiniteScroll'

const perPage = Constants.PER_PAGE_DEFAULT

const CommunityStudentsList = ({ students, loader, fetchStudents, studentsSearch, studentsCheckBoxToggle }) => {
  const communityStudents = useSelector(state => state.communityStudents.students)
  const communityStudentsPagination = useSelector(state => state.communityStudents.pagination)
  const hasMoreStudents = !communityStudentsPagination?.is_last_page
  const nextPageStudents = parseInt(communityStudentsPagination?.page) + 1

  if (loader && !communityStudentsPagination) {
    return (
      <Loader message='Loading students...'/>
    )
  }

  return (
    communityStudents?.length > 0 ?
      <InfiniteScroll
        dataLength={communityStudents.length}
        next={() => fetchStudents(nextPageStudents, perPage, studentsSearch)}
        hasMore={hasMoreStudents}
        scrollableTarget='studentsList'
      >
        {communityStudents?.map((s) => (
          <StudentPickerCard
            key={s.id}
            checked={!!students.find((ss) => ss.user_id === s.id)}
            student={s}
            onClick={studentsCheckBoxToggle}
          />
        )) }
      </InfiniteScroll>
      :
      studentsSearch ? (
        <div className='d-flex flex-column justify-content-center mt-5'>
          <p className='placeholder-title'>No results for {studentsSearch}</p>
          <p className='placeholder-description'>Please check spelling and try again.</p>
        </div>
      ) : (
        <div className='d-flex flex-column justify-content-center mt-5'>
          <p className='placeholder-title'>No students available</p>
          <p className='placeholder-description'>Please try again.</p>
        </div>
      )
  )
}

CommunityStudentsList.propTypes = {
  students: PropTypes.array,
  loader: PropTypes.bool,
  studentsCheckBoxToggle: PropTypes.func.isRequired,
  fetchStudents: PropTypes.func.isRequired,
  studentsSearch: PropTypes.string,
}

export default CommunityStudentsList
