import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { getEnrollmentData } from '../../../actions/programRegistrationActions'
import { resetRegistration, setSelectedPaymentMethod } from '../../../reducers/programRegistrationReducer'
import _ from 'lodash'
import useDataForErrors from './useDataForErrors'

import Loader from '../../common/Op36Loader-web'
import LoadingSpinner from '../../common/LoadingSpinner'
import ProgramDetailsStep from '././ProgramDetailsStep/ProgramDetailsStep'
import ProgramMaterialsStep from '././ProgramMaterialsStep/ProgramMaterialsStep'
import ProgramRegistrationFooter from './ProgramRegistrationFooter'
import LockError from '../../common/LockError'
import SummaryPanel from '././SummaryPanel/SummaryPanel'

import './ProgramRegistrationPage.scss'

const ProgramRegistrationPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  const user = useSelector(state => state.user.current_user)
  const course = useSelector(state => state.programPresentation.course)
  const enrollmentData = useSelector(state => state.programRegistration.enrollmentData)
  const {
    status,
    errors,
    steps,
    currentStep,
  } = useSelector(state => state.programRegistration)
  const dataForError = useDataForErrors({ errors, course })

  useEffect(() => {
    if (_.isEmpty(user)) {
      localStorage.setItem('redirectUrl', location.pathname + location.search)
      sessionStorage.setItem('courseId', course.id)
      sessionStorage.setItem('courseName', course.name)
      navigate('/log-in', { replace: true })
    } else {
      dispatch(getEnrollmentData({ courseId: course.id }))
    }

    return () => {
      dispatch(resetRegistration())
    }
  }, [course.community.id, course.id, course.name, dispatch, location, navigate, searchParams, user])

  useEffect(() => {
    if (enrollmentData) {
      if (enrollmentData.coursePackages.length === 0) { // if we have no packages we can't do online payments
        dispatch(setSelectedPaymentMethod('cash'))
      } else if (!(course.paymentMethod?.cash && course.paymentMethod?.card)) { // if both payment methods are NOT available
        dispatch(setSelectedPaymentMethod(course.paymentMethod?.cash ? 'cash' : 'card'))
      }
    }
  }, [enrollmentData])

  const showCurrentStep = () => {
    switch (currentStep) {
      case 0:
        return <ProgramDetailsStep />
      case 1:
        if (steps[1] === 'Program Materials') {
          return <ProgramMaterialsStep />
        } else {
          return null
        }
      default:
        return null
    }
  }

  return (
    <>
      {status === 'error' ? (
        <LockError
          titleText={dataForError.titleText}
          linkAttributes={dataForError.linkAttributes}
        >
          {dataForError.body}
        </LockError>
      ) : (status === 'loading enrollment data' || status === 'inactive'
        ? <Loader message='Loading enrollment data' />
        : (status === 'processing registration'
          ? (
            <LoadingSpinner
              text='Processing registration...'
              classNames={{
                wrapper: 'h-100',
                spinner: 'fa-4x program-registration-spinner',
              }}
            />
          )
          : (
            <div id='ProgramRegistrationPage' className='h-100 d-flex flex-lg-row flex-column flex-grow-1 justify-content-center align-items-center'>
              <div className='col-lg-4 px-0 d-lg-flex align-items-lg-center summary-panel-wrapper'>
                <SummaryPanel />
              </div>
              <div className='h-100 col-lg-8 overflow-auto px-0 d-flex flex-column justify-content-between'>
                <div className='registration-step-wrapper'>
                  {showCurrentStep()}
                </div>
                <ProgramRegistrationFooter />
              </div>
            </div>
          )
        )
      )}
    </>
  )
}

export default ProgramRegistrationPage
