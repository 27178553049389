import React, { Component } from "react";
import FamilyRegisterForm from "../Forms/FamilyRegisterForm";
import "./FamilyAdminStep.scss";

import { Link } from "react-router-dom";

class FamilyAdminStep extends Component {
  render() {
    const {
      community,
      signUpFamily,
      invitation,
      error,
      loader,
      nextStep,
      previousStep,
      activeStep
    } = this.props;
    return (
      <div id='FamilyAdminStep' className='animated fadeIn'>
        <h5 className='title'>Create a Family Admin Account</h5>
        <p className='description'>
          Your first step is to create a family admin account on the Op 36
          Platform
        </p>
        <p className='important-note'>
          Please note: If you are a parent of a junior golfer, please fill out
          your information, you will add your junior golfer(s) later.
        </p>
        <br />
        <p className='description already-user'>
          Already have an account?{" "}
          <Link className='signin-link' to='/log-in'>
            Click Here to Sign In
          </Link>
        </p>
        <div>
          <FamilyRegisterForm
            community={community}
            invitation={invitation}
            signUpFamily={signUpFamily}
            error={error}
            loader={loader}
            activeStep={activeStep}
            nextStep={nextStep}
            previousStep={previousStep}
          />
        </div>
      </div>
    );
  }
}

export default FamilyAdminStep;
