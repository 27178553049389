import { useSelector } from 'react-redux'
import * as Constants from '../../../misc/Constants'
import PropTypes from 'prop-types'

import ClassPickerCard from '../ClassPickerCard/ClassPickerCard'
import Loader from '../../common/Op36Loader-web'
import InfiniteScroll from '../../infinite-scroll/InfiniteScroll'

const perPage = Constants.PER_PAGE_DEFAULT

const CommunityProgramsList = ({ courses, fetchCourses, loader, coursesSearch, packageAdded, packageRemoved, coursesCheckBoxToggle }) => {
  const communityCourses = useSelector(state => state.courses.courses)
  const communityCoursesPagination = useSelector(state => state.courses.pagination)
  const hasMoreCourses = !communityCoursesPagination?.is_last_page
  const nextPageCourses = parseInt(communityCoursesPagination?.page) + 1

  if (loader && !communityCoursesPagination) {
    return (
      <Loader message='Loading programs...'/>
    )
  }

  return (
    communityCourses?.length > 0 ? (
      <InfiniteScroll
        dataLength={communityCourses.length}
        next={() => fetchCourses(nextPageCourses, perPage, coursesSearch)}
        hasMore={hasMoreCourses}
        scrollableTarget='studentsList'
      >
        {communityCourses?.map((c) => {
          const selectedCourse = courses.find((sc) => sc.id === c.id)
          return (
            <div className='pb-3' key={c.id}>
              <ClassPickerCard
                checked={!!selectedCourse}
                course={selectedCourse || c}
                onClick={coursesCheckBoxToggle}
                errors={{}}
                handleAddPackage={packageAdded}
                handleDeletePackage={packageRemoved}
              />
            </div>
          )
        }) }
      </InfiniteScroll>
    ) : (
      coursesSearch ? (
        <div className='d-flex flex-column justify-content-center mt-5'>
          <p className='placeholder-title'>No results for {coursesSearch}</p>
          <p className='placeholder-description'>Please check spelling and try again.</p>
        </div>
      ) : (
        <div className='d-flex flex-column justify-content-center mt-5'>
          <p className='placeholder-title'>No programs available</p>
          <p className='placeholder-description'>Please try again.</p>
        </div>
      )
    )
  )
}

CommunityProgramsList.propTypes = {
  courses: PropTypes.array,
  loader: PropTypes.bool,
  coursesSearch: PropTypes.string,
  coursesCheckBoxToggle: PropTypes.func.isRequired,
  fetchCourses: PropTypes.func.isRequired,
  packageAdded: PropTypes.func.isRequired,
  packageRemoved: PropTypes.func.isRequired,
}

export default CommunityProgramsList
