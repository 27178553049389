import { useSelector } from 'react-redux'

import AddressPicker from './AddressPicker'
import Facilities from './Facilities'

const NewFamilyView = () => {
  const nearbyCommunities = useSelector(state => state.studentDashboard.nearbyCommunities)

  return (
    <div>
      {nearbyCommunities ? (
        <Facilities />
      ) : (
        <AddressPicker />
      )}
    </div>
  )
}

export default NewFamilyView
