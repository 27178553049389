// import { useEffect, useRef } from 'react';
// import { useNavigate } from 'react-router-dom'
import axios from "axios";
import store from "./store/store";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
});

axiosInstance.interceptors.request.use((axiosConfig) => {
  // console.log(store.getState().user);
  if (typeof store.getState().user.current_user !== "undefined") {
    const user_token = store.getState().user.current_user.authentication_token;
    if (user_token) {
      axiosConfig.headers.Authorization = `${user_token}`;
    }
  }

  return axiosConfig;
});

axiosInstance.interceptors.response.use(
  (response) => {
    // console.log('axios response:', response)
    return response
  },
  (error) => {
    if (error.response && (typeof error.response.data.errors === 'string' || error.response.data.errors instanceof String)) {
      error.response.data.errors = { general: [error.response.data.errors] }
    } else if (!error.response || error.response.status === 500) {
      error.response = {
        data: {
          errors: { general: ['Something went wrong, please try again'] }
        }
      }
    }
    if (error.response && error.response.status === 401) {
      window.location = '/log-in' // TODO: Find better solution
      // navigate('/log-in')
    }

    return Promise.reject(error)
  }
);

// export const Authorization = () => {
//   const navigate = useNavigate()
//   const interceptorId = useRef(null)

//   useEffect(
//     () => {
//       interceptorId.current = axiosInstance.interceptors.response.use(
//         (response) => response,
//         (error) => {
//           if (error.response.status === 401) {
//             navigate('/log-in')
//           }

//           return Promise.reject(error)
//         }
//       );

//       return () => {
//         axiosInstance.interceptors.response.eject(interceptorId.current);
//       };
//     },
//     [ navigate ]
//   )

//   return null
// }

export default axiosInstance;
