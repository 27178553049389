import { useSelector } from 'react-redux'
import { useLocation, Navigate, Outlet } from 'react-router-dom'
import WelcomePage from './components/WelcomePage'
import UpgradePrompt from './components/common/UpgradePrompt'
import FourOhFour from './components/common/FourOhFour'
import { isFeatureEnabled } from './utility/featureEnablement'
import LogInPage from './components/auth_2/LogIn/LogInPage'

export const RootPage = () => {
  const user = useSelector(state => state.user)

  if (user.length !== 0) {
    return <Navigate to='/dashboard' replace={true}/>
  }

  return <WelcomePage />
}

export const AuthenticatedApp = ({ children }) => {
  const user = useSelector(state => state.user)
  const location = useLocation()

  if (user.length === 0) {
    localStorage.setItem('redirectUrl', location.pathname)
    return <Navigate to='/log-in' replace={true}/>
  }

  return children
}

const useAuthenticatedUser = () => {
  const current_user = useSelector(state => state.user.current_user)
  const user = {...current_user}

  if (user.type === 'Student' && user.family_admin) {
    user.type = 'FamilyAdmin'
  }

  return user
}

const featureEnabled = (user, feature) => {
  if (isFeatureEnabled(user.communities[0], feature)) {
    return true
  }

  return false
}

export const AuthorizationRoute = ({ roles, level, specialUnlock, leadCoachRequired, feature }) => {
  const user = useAuthenticatedUser()
  const isCoach = user.type === 'Coach'

  if (feature && !featureEnabled(user, feature)) {
    return <FourOhFour />
  }

  if (roles) {
    roles = [].concat(roles) // Ensure roles is array

    if (!roles.includes(user.type)) {
      return <FourOhFour />
    }
  }

  // User is not a coach, so allow to continue (we don't have rules for other types of users yet)
  if (!isCoach) {
    return <Outlet />
  }

  if (leadCoachRequired && !user.lead_coach) {
    return <FourOhFour />
  }

  // We don't have its user settings, so the user needs to sign in
  if (!user.user_settings) {
    return <LogInPage />
  }

  // Still figuring out
  if (user.user_settings.coach_permission === 3) {
    if (specialUnlock) {
      return <Outlet />
    } else {
      return <UpgradePrompt />
    }
  }

  // User does not have the required permission level
  if (user.user_settings.coach_permission < level) {
    return <UpgradePrompt />
  }

  return <Outlet />
}
