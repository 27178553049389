import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { ReactComponent as ArrowIcon } from '../../../../../assets/images/common/icons/arrow-right.svg'
import communityMapPreview from '../../../../../assets/images/mock/communityMapPreview.webp'

import classes from './ExploreLocationsCard.module.scss'

const ExploreLocationsCard = () => {
  const address = useSelector(state => state.studentDashboard.address)

  useEffect(() => {
    localStorage.setItem('communityMapAddress', address)
  }, [address])

  return (
    <Link className={classNames(classes.exploreLocationsCard, 'd-flex align-items-center h-100')} to='/community-map' target='_blank'>
      <img className={classNames(classes.mapPreviewImage, 'd-none d-sm-block')} src={communityMapPreview} alt='Community Map Preview' />

      <section className='align-self-stretch d-flex flex-column justify-content-start ml-0 ml-sm-4 mr-4'>
        <h4 className={classNames(classes.title, 'mt-3')}>Explore more Locations Near Me</h4>

        <small className={classNames(classes.subtitle, 'mt-3')}>Explore over 800+ facilities and 10.000 programs!</small>
      </section>

      <ArrowIcon className={classNames(classes.arrowIcon, 'ml-auto')} />
    </Link>
  )
}

export default ExploreLocationsCard
