import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import PropTypes from 'prop-types'

import * as eventActions from '../../actions/eventActions'

import SearchInput from '../common/SearchInput'
import EventsList from './EventsList'
import EventInProgressCard from './EventInProgressCard'

import classNames from 'classnames'
import styles from './Events.module.scss'

const perPage = (window.innerHeight - 220) / 97

const CompletedEvents = ({ searchTerm, setSearchTerm, activePage, setActivePage, loadScores }) => {
  const dispatch = useDispatch()

  const events = useSelector(state => state.events.events)
  const pagination = useSelector(state => state.events.pagination)
  const currentUser = useSelector(state => state.user.current_user)

  const localScores = JSON.parse(localStorage.getItem('draftScores'))
  const eventScores = localScores && localScores[-1] && localScores[-1]['scores']

  const [loader, setLoader] = useState(false)

  const fetchEvents = useCallback((filters) => {
    setLoader(true)
    dispatch(
      eventActions.getEvents(currentUser.id, filters, activePage, perPage)
    ).then(() => {
      setLoader(false)
    })
  }, [dispatch, currentUser.id, activePage])

  const debouncedSearchEvents = useMemo(() => (
    _.debounce(fetchEvents, 400)
  ), [fetchEvents])

  const onChange = (e) => {
    const value = e.target.value
    setSearchTerm(value)
    setLoader(true)
    setActivePage(1)
    debouncedSearchEvents({ search: value })
  }

  useEffect(() => {
    if (events && pagination.page === activePage || events?.length === 0) {
      return
    }
    fetchEvents({ search: searchTerm })
  }, [fetchEvents, activePage, pagination, events, searchTerm])

  return (
    <div>
      <div className={classNames(styles['events-search-bar'], 'mt-2')}>
        <SearchInput value={searchTerm} placeholder='Search Events' onChange={onChange}/>
      </div>
      {eventScores && <div className='px-4 pb-3'><EventInProgressCard onClick={loadScores} isQuickLog/></div>}
      <EventsList events={events} loader={loader} searchTerm={searchTerm} activePage={activePage} setActivePage={setActivePage}/>
    </div>
  )
}

CompletedEvents.propTypes = {
  searchTerm: PropTypes.string,
  activePage: PropTypes.number,
  setSearchTerm: PropTypes.func.isRequired,
  setActivePage: PropTypes.func.isRequired,
  loadScores: PropTypes.func,
}

export default CompletedEvents
