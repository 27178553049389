import classNames from 'classnames'

import { ReactComponent as Op36Icon } from '../../../../assets/images/common/icons/sidebarMenu/Op36.svg'
import PageHeader from '../../../common/PageHeader/PageHeader'
import UserActions from './UserActions'

import classes from './Header.module.scss'

const Header = () => (
  <header className={classNames(classes.header, 'sticky-top')}>
    <PageHeader
      title='My Dashboard'
      icon={<a href='https://operation36.golf' target='_blank'><Op36Icon className={classes.op36Icon} /></a>}
      actionComponent={<UserActions />}
    />
  </header>
)

export default Header
