import React, { Component } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "./ConsentFlow/ConsentFlow.scss";
import { withRouting } from "../common/hooks";
import { isValidEmail } from '../../utility/helperFunctions'
import PropTypes from 'prop-types'

const CONSENT_NAMES = {
  privacy_policy: "Privacy Policy",
  eula: "Terms of Use",
  saas_agreement: "Coach End-User Licensing Agreement",
};

const FAMILY_CONSENT_NAMES = {
  privacy_policy: "Privacy Policy",
  eula: "Terms of Use",
  coppa_policy: "COPPA Policy",
};

class ConsentContainer extends Component {
  state = {
    allConsentCheckbox: false,
    over12Checkbox: false,
    submitting: false,
    birthdate: moment(),
    username: "",
    usernameAlreadyExists: false,
    familyAgeSkipped: false,
    familyAges: {},
    loadingFamilyAges: true,
    familyConsentsSkipped: false,
    needsPersonalConsent: false,
    familyConsentsCheckbox: false,
    submittingFamily: false,
  };

  componentDidMount() {
    this._initializeFamilyAgesObj();
    this._checkPersonalConsents();
  }

  // eslint-disable-next-line max-lines-per-function, complexity
  render() {
    const { consents, consentType } = this.props;
    if (!consents) {
      return null;
    }
    const {
      allConsentCheckbox,
      over12Checkbox,
      submitting,
      birthdate,
      username,
      usernameAlreadyExists,
      familyAgeSkipped,
      familyAges,
      loadingFamilyAges,
      familyConsentsSkipped,
      familyConsentsCheckbox,
      submittingFamily,
      needsPersonalConsent,
    } = this.state;
    if (consentType === "userAge") {
      return (
        <div id='ConsentContainer'>
          <h2>Please add your birthday to continue</h2>
          <p className='subtext'>
            Providing your birthday lets us know who is old enough to use the Op
            36 App without parental supervision and who can allow their younger
            family members to use the app. Learn more about our age requirements
            in our{" "}
            <a
              href='https://operation36.golf/legal/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Terms of Use
            </a>{" "}
            and{" "}
            <a
              href='https://operation36.golf/legal/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Privacy Policy
            </a>
            .
          </p>
          <p className='bold mb-0'>Input your birthday here:</p>
          <DatePicker
            selected={birthdate.toDate()}
            onChange={this._handleBirthdateChange}
          />
          <button
            onClick={this._submitOwnBirthdateChange}
            className='btn-rectangular btn-rectangular-main-positive mt-5'
            disabled={!birthdate}
          >
            Continu{submitting ? "ing" : "e"}
          </button>
        </div>
      );
    }

    if (consentType === "contactSupport") {
      return (
        <div id='ConsentContainer'>
          <h2>You must be at least 13 years old to be a family admin</h2>
          <p className='subtext'>
            You must be at least 13 years old to be a family admin in this app.
            Because you are under 13 and there are no other admins in your
            family, your account has been disabled for now. If you believe that
            this was a mistake, please contact us at{" "}
            <span className='#ff6633'>support@op36golf.com</span> so we can help
            you.
          </p>
          <div className='d-flex align-items-center justify-content-center'>
            <p className='signout' onClick={this._signOut}>
              -Sign Out-
            </p>
          </div>
        </div>
      );
    }

    if (consentType === "familyAdminDowngrade") {
      return (
        <div id='ConsentContainer'>
          <h2>
            You are too young to be a family admin. Please choose a new
            username:
          </h2>
          <p className='subtext'>
            Family admins need to be at least 13 years old. Because you are
            under 13, we will need to turn your Family Admin account into a
            standard golfer account. Learn more about our age requirements in
            our{" "}
            <a
              href='https://operation36.golf/legal/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Terms of Use
            </a>{" "}
            and{" "}
            <a
              href='https://operation36.golf/legal/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Privacy Policy
            </a>
            .
          </p>
          <p className='bold mb-0'>Pick a new username:</p>
          <input
            name='username'
            value={username}
            onChange={this._handleUsernameChange}
          />
          {usernameAlreadyExists && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffba00",
              }}
            >
              <p>username already exists or is invalid</p>
            </div>
          )}
          <button
            onClick={this._submitUsernameChange}
            className='btn-rectangular btn-rectangular-main-positive mt-5'
            disabled={!username}
          >
            Continu{submitting ? "ing" : "e"}
          </button>
        </div>
      );
    }

    if (consentType === "needsProxy") {
      return (
        <div id='ConsentContainer'>
          <h2>
            Please ask one of your family admins to consent to our updated legal
            agreements
          </h2>
          <p className='subtext'>
            To continue using Operation 36, please ask one of the family admins
            in your family to log in and consent to our updated legal
            agreements.
          </p>
          <p className='bold mb-0'>Ask one of these family members:</p>
          <ul>
            {consents.needs_proxy.map((fam_ad, i) => {
              return <li key={i}>{fam_ad}</li>;
            })}
          </ul>
          <div className='d-flex align-items-center justify-content-center'>
            <p className='signout' onClick={this._signOut}>
              -Sign Out-
            </p>
          </div>
        </div>
      );
    }

    if (
      consents.family_ages_needed &&
      consents.family_ages_needed.length > 0 &&
      !familyAgeSkipped
    ) {
      return (
        <div id='ConsentContainer'>
          <h2>Please add birthdays for these family members</h2>
          <p className='subtext'>
            Providing their birthdays lets us know who is old enough to use the
            Op 36 App without parental supervision and who can allow their
            younger family members to use the app. Learn more about our age
            requirements in our{" "}
            <a
              href='https://operation36.golf/legal/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Terms of Use
            </a>{" "}
            and{" "}
            <a
              href='https://operation36.golf/legal/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Privacy Policy
            </a>
            .
          </p>
          <p className='bold mb-0'>Input birthdays:</p>
          {loadingFamilyAges ? (
            <i className='fa fa-spinner fa-spin' aria-hidden='true' />
          ) : (
            consents.family_ages_needed.map((usr) => {
              return (
                <div
                  className='d-flex align-items-center justify-content-between'
                  key={usr[0]}
                >
                  <p className='mb-0'>{familyAges[usr[0]].name}</p>
                  <DatePicker
                    selected={familyAges[usr[0]].birthdate}
                    onChange={(data) =>
                      this._handleFamilyBirthdateChange(usr[0], data)
                    }
                  />
                </div>
              );
            })
          )}
          <div className='d-flex mt-4'>
            <button
              onClick={this._skipFamilyAges}
              className='btn-rectangular btn-rectangular-main-negative'
            >
              Skip
            </button>
            <button
              onClick={this._submitFamilyAges}
              className='btn-rectangular btn-rectangular-main-positive'
              disabled={this._anyMissingFamilyAges()}
            >
              Submit
            </button>
          </div>
        </div>
      );
    }

    if (
      consents.family_consents_needed &&
      consents.family_consents_needed.length > 0 &&
      !familyConsentsSkipped
    ) {
      return (
        <div id='ConsentContainer'>
          <h2>
            Please consent to our updated legal agreements for these family
            members
          </h2>
          <p className='subtext'>
            Some of your family members are under 13, so they need you to
            consent to these agreements on their behalf in order for them to use
            the Op 36 App. Please click on the links below to review the
            agreements before continuing.
          </p>
          <p className='bold mb-0'>Consent required for:</p>
          <ul>{this._renderFamilyConsentLinks()}</ul>
          {/* <div className='checkbox-container'>
          <div
            className={`checkbox ${over12Checkbox ? "filled" : ""}`}
            onClick={() => this._handleCheckbox("over12Checkbox")}
          >
            {over12Checkbox && <i className='fa fa-check' aria-hidden='true' />}
          </div>
          <p className='mb-0'>I agree that I am 13 years of age or older.</p>
        </div> */}
          <div className='checkbox-container mt-2'>
            <div
              className={`checkbox ${familyConsentsCheckbox ? "filled" : ""}`}
              onClick={() => this._handleCheckbox("familyConsentsCheckbox")}
            >
              {familyConsentsCheckbox && (
                <i className='fa fa-check' aria-hidden='true' />
              )}
            </div>
            <p>
              By checking here and clicking "Continue" below, you agree to
              consent on behalf of the users listed above.
            </p>
          </div>
          <div className='d-flex'>
            <button
              onClick={this._skipFamilyConsents}
              className='btn-rectangular btn-rectangular-main-negative'
            >
              Skip
            </button>
            <button
              onClick={this._submitFamilyConsent}
              className='btn-rectangular btn-rectangular-main-positive'
              disabled={!familyConsentsCheckbox || submittingFamily}
            >
              Continu{submittingFamily ? "ing" : "e"}
            </button>
          </div>
        </div>
      );
    }

    if (needsPersonalConsent) {
      return (
        <div id='ConsentContainer'>
          <h2>
            Please consent to the latest terms and conditions in the documents
            below before continuing
          </h2>
          <p className='subtext'>
            You are seeing this because you are either new to Operation36, or we
            have updated the documents liseted below. Please click on the links
            below to review these documents before continuing.
          </p>
          <p className='bold mb-0'>Consent required for:</p>
          <ul>{this._renderConsentLinks()}</ul>
          <div className='checkbox-container'>
            <div
              className={`checkbox ${over12Checkbox ? "filled" : ""}`}
              onClick={() => this._handleCheckbox("over12Checkbox")}
            >
              {over12Checkbox && (
                <i className='fa fa-check' aria-hidden='true' />
              )}
            </div>
            <p className='mb-0'>I agree that I am 13 years of age or older.</p>
          </div>
          <div className='checkbox-container mt-2'>
            <div
              className={`checkbox ${allConsentCheckbox ? "filled" : ""}`}
              onClick={() => this._handleCheckbox("allConsentCheckbox")}
            >
              {allConsentCheckbox && (
                <i className='fa fa-check' aria-hidden='true' />
              )}
            </div>
            <p>
              By checking here and clicking "Continue" below, you agree to be
              bound by the terms and conditions of the documents listed above.
            </p>
          </div>
          <button
            onClick={this._submitConsent}
            className='btn-rectangular btn-rectangular-main-positive'
            disabled={!over12Checkbox || !allConsentCheckbox || submitting}
          >
            Continu{submitting ? "ing" : "e"}
          </button>
        </div>
      );
    }
    // return null;
    return this._shutHerDown();
  }

  _shutHerDown = () => {
    // they skipped all the family stuff and don't have personal consents
    this.props.closeModal();
    return null;
  };

  _renderFamilyConsentLinks = () => {
    const { consents } = this.props;
    return consents.family_consents_needed.map((usr) => {
      return (
        <li key={usr.id}>
          {usr.name}
          <ul>{this._renderFamilyIndividualConsents(usr)}</ul>
        </li>
      );
    });
  };

  _renderFamilyIndividualConsents = (usr) => {
    let consentsNeeded = [];
    Object.keys(FAMILY_CONSENT_NAMES).forEach((consent) => {
      if (!usr[consent]) {
        consentsNeeded.push(consent);
      }
    });
    return consentsNeeded.map((consent, idx) => {
      return (
        <li key={idx}>
          <a
            href='https://operation36.golf/legal/'
            target='_blank'
            rel='noopener noreferrer'
          >
            {FAMILY_CONSENT_NAMES[consent]}
          </a>
        </li>
      );
    });
  };

  _checkPersonalConsents = () => {
    const { consents } = this.props;
    let ret = false;
    for (const [consentName, consentData] of Object.entries(consents)) {
      if (CONSENT_NAMES.hasOwnProperty(consentName)) {
        if (!consentData.consented) {
          ret = true;
        }
      }
    }
    this.setState({ needsPersonalConsent: ret });
  };

  _submitFamilyConsent = () => {
    this.setState({ submittingFamily: true }, () => {
      this.props.submitFamilyConsents();
    });
  };

  _submitFamilyAges = () => {
    const { submitFamilyBirthdates } = this.props;
    const { familyAges } = this.state;
    let userArray = [];
    for (const [id, data] of Object.entries(familyAges)) {
      if (familyAges.hasOwnProperty(id)) {
        userArray.push({ id: id, birthdate: data.birthdate });
      }
    }
    submitFamilyBirthdates(userArray);
  };

  _skipFamilyConsents = () => {
    this.setState({ familyConsentsSkipped: true });
  };

  _skipFamilyAges = () => {
    this.setState({ familyAgeSkipped: true });
  };

  _anyMissingFamilyAges = () => {
    const { familyAges, loadingFamilyAges } = this.state;
    if (loadingFamilyAges) {
      return true;
    }
    let ret = false;
    for (const property in familyAges) {
      if (familyAges.hasOwnProperty(property)) {
        if (!familyAges[property].birthdate) {
          ret = true;
        }
      }
    }
    return ret;
  };

  _handleFamilyBirthdateChange = (id, date) => {
    const { familyAges } = this.state;
    const familyAgesCopy = Object.assign({}, familyAges);
    familyAgesCopy[id].birthdate = date;
    this.setState({ familyAges: familyAgesCopy });
  };

  _initializeFamilyAgesObj = () => {
    const { consents } = this.props;
    const { familyAges } = this.state;
    if (
      !consents.family_ages_needed ||
      consents.family_ages_needed.length === 0
    ) {
      return;
    }
    let newFamilyObj = Object.assign({}, familyAges);
    consents.family_ages_needed.forEach((usr) => {
      newFamilyObj[usr[0]] = { name: `${usr[1]} ${usr[2]}`, birthdate: null };
    });
    this.setState({ familyAges: newFamilyObj, loadingFamilyAges: false });
  };

  _submitUsernameChange = () => {
    const { username } = this.state;
    const { submitUsername } = this.props;
    if (isValidEmail(username)) {
      return this.setState({ usernameAlreadyExists: true });
    }
    try {
      submitUsername(username);
    } catch (error) {
      console.log(error);
      this.setState({ usernameAlreadyExists: true });
    }
  };

  _submitOwnBirthdateChange = () => {
    const { birthdate } = this.state;
    const { submitOwnBirthdate } = this.props;
    submitOwnBirthdate(birthdate);
  };

  _submitConsent = () => {
    const { consents, submitConsents } = this.props;
    const { allConsentCheckbox, over12Checkbox, submitting } = this.state;
    if (!over12Checkbox || !allConsentCheckbox || submitting) {
      return false;
    }
    this.setState({ submitting: true }, () => {
      let updatedConsents = this._getConsentsNeeded(consents);
      submitConsents(updatedConsents);
    });
  };

  _handleUsernameChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      usernameAlreadyExists: false,
    });
  };

  _handleBirthdateChange = (date) => {
    this.setState({ birthdate: moment(date) });
  };

  _handleCheckbox = (val) => {
    this.setState((prevState) => {
      return { [val]: !prevState[val] };
    });
  };

  _renderConsentLinks = () => {
    const { consents } = this.props;
    let consentsNeeded = this._getConsentsNeeded(consents);
    return consentsNeeded.map((c, i) => {
      return (
        <li key={i}>
          <a
            href='https://operation36.golf/legal/'
            target='_blank'
            rel='noopener noreferrer'
          >
            {CONSENT_NAMES[c.name]}
          </a>
        </li>
      );
    });
  };

  _getConsentsNeeded = (consents) => {
    let consentArray = [];
    for (const [consentName, consentData] of Object.entries(consents)) {
      if (CONSENT_NAMES.hasOwnProperty(consentName)) {
        if (!consentData.consented) {
          consentArray.push({
            name: consentName,
            version: consentData.version,
          });
        }
      }
    }
    return consentArray;
  };

  _signOut = () => {
    const { closeModal } = this.props;
    this.props.navigate('/log-in', { replace: true })

    closeModal();
  };
}

ConsentContainer.propTypes = {
  navigate: PropTypes.func,
}

export default withRouting(ConsentContainer)
