import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import classNames from 'classnames'

import * as communitiesActions from '../../../../../actions/communitiesActions.js'
import { setAddress, setNearbyCommunities, setError } from '../../../../../reducers/studentDashboardReducer.js'
import { ReactComponent as SearchIcon } from '../../../../../assets/images/common/icons/search-icon.svg'
import { ReactComponent as GolferHalfIconRound } from '../../../../../assets/images/common/icons/golfer-half-icon-round.svg'
import { ReactComponent as FlagIconRound } from '../../../../../assets/images/common/icons/flag-icon-round.svg'
import communityMapPreview from '../../../../../assets/images/mock/communityMapPreview.webp'
import Input from '../../../../common/form/Input.js'

import classes from './AddressPicker.module.scss'

const AddressPicker = () => {
  const dispatch = useDispatch()

  const errors = useSelector(state => state.studentDashboard.errors)
  const [rawAddress, setRawAddress] = useState('')
  const [loader, setLoader] = useState(false)

  const handleSubmit = () => {
    setLoader(true)

    dispatch(setAddress(rawAddress))
    geocodeByAddress(rawAddress)
      .then(results => getLatLng(results[0]))
      .then(latLng => dispatch(communitiesActions.getNearbyCommunities(latLng)))
      .then(response => {
        const responseCommunities = response.payload.communities
        if (responseCommunities.length === 0) {
          dispatch(setNearbyCommunities([]))

          return
        }

        const service = new window.google.maps.DistanceMatrixService()
        const requestedLat = response.meta.arg.lat
        const requestedLng = response.meta.arg.lng
        const communitiesCoordinates = responseCommunities.map((community) => (
          new window.google.maps.LatLng(community.address.lat, community.address.lng)
        ))

        service.getDistanceMatrix(
          {
            origins: [new window.google.maps.LatLng(requestedLat, requestedLng)],
            destinations: communitiesCoordinates,
            travelMode: 'DRIVING',
            avoidHighways: false,
            avoidTolls: false,
            unitSystem: window.google.maps.UnitSystem.IMPERIAL,
          },
          (res, status) => {
            if (status === 'OK') {
              const enhancedCommunities = []

              for (let index = 0; index < responseCommunities.length; index++) {
                enhancedCommunities.push({
                  ...responseCommunities[index],
                  distance: res.rows[0].elements[index].distance,
                  duration: res.rows[0].elements[index].duration,
                })
              }

              dispatch(setNearbyCommunities(enhancedCommunities))
            } else {
              dispatch(setNearbyCommunities(responseCommunities))
            }

            setLoader(false)
          }
        )
      })
      .catch(_ => { dispatch(setError({ field: 'address', value: 'Invalid address' })); setLoader(false) })
  }

  return (
    <div className={classNames(classes.addressPicker, 'd-flex align-items-center justify-content-center')}>
      <main className={classNames(classes.addressWrapper, 'd-flex')}>
        <SearchIcon className={classNames(classes.searchIcon, 'd-none d-sm-block mr-3')} />

        <main className={classNames(classes.programInfo, 'd-flex flex-column mr-5')}>
          <h4 className={classes.title}>Find your first Program</h4>

          <p>
            Let us help you find your first program next to you. Share your zip code below and
            we&apos;ll show you the closest Op 36 Locations to you. You can change it anytime.
          </p>

          <section>
            <h6 className={classNames(classes.offerText, 'mt-3')}>We Offer:</h6>
            <section className='d-flex flex-wrap'>
              <div className='d-flex mt-2 mb-2 mr-4'>
                <GolferHalfIconRound className={classNames(classes.golferHalfIconRound, 'mr-3')} />
                <div className='d-flex flex-column'>
                  <h2 className={classes.statsCounter}>750</h2>
                  <h5 className={classes.statsText}>Communities</h5>
                </div>
              </div>

              <div className='d-flex mt-2 mb-2'>
                <FlagIconRound className={classNames(classes.flagIconRound, 'mr-3')} />
                <div className='d-flex flex-column'>
                  <h2 className={classes.statsCounter}>7000</h2>
                  <h5 className={classes.statsText}>Active Programs</h5>
                </div>
              </div>
            </section>
          </section>

          <div className={classNames(classes.inputWrapper, 'mt-2')}>
            <label htmlFor='address'>Address <span className='required-text'>*</span></label>

            <PlacesAutocomplete
              onChange={(value) => setRawAddress(value)}
              onSelect={(value) => setRawAddress(value)}
              value={rawAddress}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                <div>
                  <Input
                    {...getInputProps({
                      className: classes.addressInput,
                      placeholder: 'Enter Address',
                    })}
                    error={errors.address}
                  />

                  <div className={classNames({ [classes.dropdownContainer]: suggestions.length > 0 })}>
                    {suggestions.map((suggestion, index) => (
                      <div
                        key={index}
                        {...getSuggestionItemProps(
                          suggestion,
                          { className: classNames(classes.suggestionItem, { [classes.activeSuggestionItem]: suggestion.active }) }
                        )}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>

            <button
              className={classNames(classes.submitButton, 'btn btn-primary mt-4')}
              onClick={handleSubmit}
              disabled={loader}
            >
              {loader && <i className='fa fa-spinner fa-spin mr-2' />}
              Update Location
            </button>
          </div>
        </main>

        <img className={classNames(classes.mapPreviewImage, 'd-none d-md-flex ml-auto')} src={communityMapPreview} alt='Community Map Preview' />
      </main>
    </div>
  )
}

export default AddressPicker
