import PropTypes from 'prop-types'

import Select from '../../../../common/form/Select'

import classes from './FormSelect.module.scss'

const FormSelect = (props) => (
  <Select
    classNames={
      {
        container: () => classes.container,
        control: () => classes.control,
        singleValue: () => classes.singleValue,
      }
    }
    {...props}/>
)

FormSelect.propTypes = {
  props: PropTypes.object,
}

export default FormSelect
