import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import Input from '../../../common/form/Input'
import Select from '../../../common/form/Select'
import Modal from '../../../common/Modal'
import SubmitButton from '../../../common/buttons/SubmitButton'
import FieldError from '../../../common/FieldError'
import MCElement from '../../../common/MCElement'
import { GENDER_SELECT_OPTIONS } from '../../../../misc/Constants'
import { isValidEmail, isValidUsername, isValidPhone } from '../../../../utility/helperFunctions'
import CONSENTS from '../../../../constants/consentDocuments.json'

import styles from './NewFamilyMemberModal.module.scss'
import AvatarPlaceholder from '../../../../assets/images/mock/StockProfilePic.png'

const initialState = {
  firstName: '',
  lastName: '',
  birthdate: null,
  gender: null,
  familyAdmin: false,
  username: '',
  email: '',
  phone: '',
  password: '',
  passwordConfirmation: '',
  avatar: null,
}

const customErrorMessage = {
  birthdateAdmins: 'Family admins must be at least 13 years old',
  birthdateFuture: 'Birthdate cannot be in the future',
  username: 'Invalid username format',
  email: 'Invalid email address format',
  phone: 'Invalid phone number format',
  password: 'Password must have at least 6 characters',
  unmatchingPasswords: 'Passwords do not match',
}

const variableData = {
  'familyAdmin': {
    labelName: 'Email',
    inputAutocomplete: 'email',
    inputId: 'emailInput',
    inputName: 'email',
    placeholder: 'Ex. john123@golf.com',
    accountTypeText: 'This user can create, access and manage the accounts of their Standard Golfer family members.',
  },
  'student': {
    labelName: 'Username',
    inputAutocomplete: 'username',
    inputId: 'usernameInput',
    inputName: 'username',
    placeholder: 'Ex. john123golf',
    accountTypeText: 'This user can only access and manage their own account.',
  },
}

// eslint-disable-next-line max-lines-per-function
const NewFamilyMemberModal = ({ isOpen, onClose, handleCreate, loader }) => {
  const currentUser = useSelector(state => state.user.current_user)

  const [user, setUser] = useState(initialState)
  const [isProxyConsentGiven, setProxyConsentGiven] = useState(false)
  const [avatarUrl, setAvatarUrl] = useState('')
  const [errors, setErrors] = useState({})

  const customData = user.familyAdmin ? variableData.familyAdmin : variableData.student
  const isUnderThirteen = user.birthdate && moment().diff(user.birthdate, 'years') < 13
  const isInTheFuture = user.birthdate && moment().diff(user.birthdate, 'days') < 0
  const isProxyConsentNeeded = !user.familyAdmin && isUnderThirteen

  const isInvalidForm = useMemo(() => (
    user.firstName === ''
    || user.lastName === ''
    || user.birthdate === null
    || user.gender === null
    || (user.familyAdmin ? user.email === '' : user.username === '')
    || user.password === ''
    || user.passwordConfirmation === ''
    || isProxyConsentNeeded && !isProxyConsentGiven
  ), [user, isProxyConsentNeeded, isProxyConsentGiven])

  const handleClose = () => {
    setUser(initialState)
    setAvatarUrl('')
    setErrors({})
    onClose()
  }

  const handleInputChange = (e) => {
    const inputName = e.target.name
    const inputValue = e.target.value

    setUser(prevState => ({
      ...prevState,
      [inputName]: inputValue,
    }))
  }

  const handleDateChange = (date) => {
    setUser(prevState => ({
      ...prevState,
      birthdate: date,
    }))
  }

  const handleGiveProxyConsent = () => {
    setProxyConsentGiven(prevState => !prevState)
  }

  const handleGenderSelect = (gender) => {
    setUser(prevState => ({
      ...prevState,
      gender,
    }))
  }

  const handleSetFamilyAdmin = () => {
    setUser(prevState => ({
      ...prevState,
      familyAdmin: !prevState.familyAdmin,
      username: '',
      email: '',
    }))
    setProxyConsentGiven(false)
    setErrors(prevState => ({
      ...prevState,
      username: null,
      email: null,
    }))
  }

  const handlePhoneChange = (phone) => {
    setUser(prevState => ({
      ...prevState,
      phone,
    }))
  }

  const handleAvatarChange = (event) => {
    if (event.target.value) {
      const file = event.target.files[0]
      setUser(prevState => ({
        ...prevState,
        avatar: file,
      }))
      setAvatarUrl(URL.createObjectURL(file))
    }
  }

  const validateBirthdate = useCallback(() => {
    if (isInTheFuture){
      setErrors(prevState => ({
        ...prevState, birthdate: [customErrorMessage.birthdateFuture],
      }))
      return false
    } else if (user.familyAdmin && isUnderThirteen) {
      setErrors(prevState => ({
        ...prevState, birthdate: [customErrorMessage.birthdateAdmins],
      }))
      return false
    } else {
      setErrors(prevState => ({
        ...prevState, birthdate: null,
      }))
      return true
    }
  }, [isInTheFuture, isUnderThirteen, user])

  useEffect(() => {
    validateBirthdate()
  }, [user, isUnderThirteen, isInTheFuture, validateBirthdate])

  const validateUsernameInput = username => {
    if (username && !isValidUsername(username)) {
      setErrors(prevState => ({
        ...prevState, username: [customErrorMessage.username],
      }))
      return false
    } else {
      setErrors(prevState => ({
        ...prevState, username: null,
      }))
      return true
    }
  }

  const validateEmailInput = email => {
    if (email && !isValidEmail(email)) {
      setErrors(prevState => ({
        ...prevState, email: [customErrorMessage.email],
      }))
      return false
    } else {
      setErrors(prevState => ({
        ...prevState, email: null,
      }))
      return true
    }
  }

  const validatePhoneNumber = phone => {
    if (phone && !isValidPhone(phone)) {
      setErrors(prevState => ({
        ...prevState, phone: [customErrorMessage.phone],
      }))
      return false
    } else {
      setErrors(prevState => ({
        ...prevState, phone: null,
      }))
      return true
    }
  }

  const validatePassword = password => {
    if (password.length < 6) {
      setErrors(prevState => ({
        ...prevState, password: [customErrorMessage.password],
      }))
      return false
    } else {
      setErrors(prevState => ({
        ...prevState, password: null,
      }))
      return true
    }
  }

  const validatePasswordsMatch = () => {
    if (user.password && user.passwordConfirmation && user.password !== user.passwordConfirmation) {
      setErrors(prevState => ({
        ...prevState, unmatchingPasswords: [customErrorMessage.unmatchingPasswords],
      }))
      return false
    } else {
      setErrors(prevState => ({
        ...prevState, unmatchingPasswords: null,
      }))
      return true
    }
  }

  const validateAllFields = () => {
    const validAge = validateBirthdate()
    const validIdentifier = user.familyAdmin ? validateEmailInput(user.email) : validateUsernameInput(user.username)
    const validPhone = validatePhoneNumber(user.phone)
    const validPassword = validatePassword(user.password)
    const validMatchingPasswords = validatePasswordsMatch()

    return validAge && validIdentifier && validPhone && validPassword && validMatchingPasswords
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!validateAllFields()) {
      return
    }

    const consent = {
      document_consented: 'coppa_policy',
      consented_on: new Date(),
      version: CONSENTS.coppa_policy.version,
      proxy_consent: true,
      proxy_consent_given_by: currentUser.id,
    }

    const { email, username, ...restUser } = { ...user }

    const userData = {
      ...restUser,
      ...(user.familyAdmin ? { email } : { username }),
      birthdate: user.birthdate.toDate()
        .toISOString(),
      gender: user.gender.value,
      familyId: currentUser.family_id,
      proxyConsentGiven: JSON.stringify(consent),
    }

    handleCreate(userData)
      .then(() => handleClose())
      .catch((errors) => {

        setErrors(prevState => ({
          ...prevState,
          ...errors,
        }))
      })
  }

  return (
    <Modal isOpen={isOpen} className={styles.newFamilyMemberModal} onClose={handleClose}>
      <Container fluid>
        <Row className='mb-4 mb-sm-5'>
          <Col xs={12} sm={2} className='d-flex justify-content-center justify-content-sm-end mb-2 mb-sm-0'>
            <div className={classNames('round-image-wrapper', styles['dark-navy-border'])}>
              <img
                className='round-image'
                src={AvatarPlaceholder}
                alt='new user'
              />
            </div>
          </Col>
          <Col xs={12} sm={10} className='d-flex justify-content-center justify-content-sm-start align-items-center pl-sm-0'>
            <h3 className={styles.newUserModalTitle}>New Family Member</h3>
          </Col>
        </Row>
        <form onSubmit={handleSubmit}>
          <Row className='mb-3'>
            <Col xs={12} sm={6} className='mb-3 mb-sm-0'>
              <label htmlFor='firstNameInput'>First Name <span className='required-text'>*</span></label>
              <Input
                id='firstNameInput'
                type='text'
                name='firstName'
                autoComplete='given-name'
                placeholder='Ex: John'
                value={user.firstName}
                onChange={handleInputChange}
              />
            </Col>
            <Col xs={12} sm={6}>
              <label htmlFor='lastNameInput'>Last Name <span className='required-text'>*</span></label>
              <Input
                id='lastNameInput'
                type='text'
                name='lastName'
                autoComplete='family-name'
                placeholder='Ex: Doe'
                value={user.lastName}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6} className='mb-3 mb-sm-0'>
              <label htmlFor='birthdateInput'>Birthdate <span className='required-text'>*</span></label>
              <Input
                id='birthdateInput'
                type='date'
                name='birthdate'
                placeholder='MM/DD/YYYY'
                value={user.birthdate}
                onChange={handleDateChange}
                onBlur={validateBirthdate}
                symbol={<i className='fa fa-calendar' />}
                error={errors.birthdate}
              />
            </Col>
            <Col xs={12} sm={6}>
              <label htmlFor='genderSelect'>Gender <span className='required-text'>*</span></label>
              <Select
                id='genderSelect'
                isSearchable={false}
                placeholder='Choose a gender'
                value={user.gender}
                onChange={handleGenderSelect}
                options={GENDER_SELECT_OPTIONS}
              />
            </Col>
          </Row>
          <Row className='my-3'>
            <Col xs={12} sm={6} className='pr-sm-0'>
              <label htmlFor='familyAdminSwitch' className={styles.accountType}>Account type</label>
              <button
                id='familyAdminSwitch'
                type='button'
                className={classNames('d-flex justify-content-center align-items-center', styles.switchButton)}
                onClick={handleSetFamilyAdmin}
              >
                <div
                  className={classNames(styles.switchOption, !user.familyAdmin && styles.switchSelected)}
                >
                  Standard Golfer
                </div>
                <div
                  className={classNames(styles.switchOption, user.familyAdmin && styles.switchSelected)}
                >
                  Family Admin
                </div>
              </button>
            </Col>
            <Col xs={12}>
              <p className={styles.accountType}>{customData.accountTypeText}</p>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col xs={12} sm={6} className='mb-3 mb-sm-0'>
              <label htmlFor={customData.inputId}>
                {customData.labelName} <span className='required-text'>*</span>
              </label>
              <Input
                id={customData.inputId}
                type='text'
                name={customData.inputName}
                autoComplete={user.familyAdmin ? 'email' : 'off'}
                placeholder={customData.placeholder}
                value={user.familyAdmin ? user.email : user.username}
                onChange={handleInputChange}
                onBlur={user.familyAdmin ? e => validateEmailInput(e.target.value) : e => validateUsernameInput(e.target.value)}
                error={user.familyAdmin ? errors.email : errors.username}
              />
            </Col>
            <Col xs={12} sm={6}>
              <label htmlFor='telephoneInput'>Phone Number</label>
              <Input
                id='telephoneInput'
                type='telephone'
                value={user.phone}
                classNames={{ telInput: styles.telInput }}
                onChange={handlePhoneChange}
                onBLur={validatePhoneNumber}
                error={errors.phone}
              />
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col xs={12} sm={6} className='mb-3 mb-sm-0'>
              <label htmlFor='passwordInput'>Password <span className='required-text'>*</span></label>
              <Input
                id='passwordInput'
                type='password'
                name='password'
                autoComplete='new-password'
                placeholder='Password'
                value={user.password}
                onChange={handleInputChange}
                onBlur={e => {
                  validatePassword(e.target.value)
                  validatePasswordsMatch()
                }}
                error={errors.password}
              />
            </Col>
            <Col xs={12} sm={6}>
              <label htmlFor='confirmPasswordInput'>Confirm Password <span className='required-text'>*</span></label>
              <Input
                id='confirmPasswordInput'
                type='password'
                name='passwordConfirmation'
                autoComplete='new-password'
                placeholder='Confirm Password'
                value={user.passwordConfirmation}
                onChange={handleInputChange}
                onBlur={validatePasswordsMatch}
              />
            </Col>
            {errors.unmatchingPasswords && (
              <Col xs={12}>
                <FieldError message={errors.unmatchingPasswords} />
              </Col>
            )}
          </Row>
          <Row className='mb-3'>
            <Col xs={12} sm={6}>
              <input id='avatarInput' type='file' accept='image/*' className='d-none' onChange={handleAvatarChange} />
              <label htmlFor='avatarInput' className={classNames(styles.uploadedAvatar, 'd-flex align-items-center')}>
                <div className={classNames('small-round-image-wrapper', styles['dark-navy-border'])}>
                  <img
                    className='round-image'
                    src={avatarUrl || AvatarPlaceholder}
                    alt='new user'
                  />
                </div>
                <span className='mx-2'><i className='fa fa-plus' /></span>
                <span>Add Photo (Optional)</span>
              </label>
            </Col>
            {errors.photo && (
              <Col xs={12}>
                <FieldError message={errors.photo} />
              </Col>
            )}
          </Row>
          {isProxyConsentNeeded && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div
              className={classNames('mb-2 d-flex', styles.proxyConsent)}
              onClick={() => handleGiveProxyConsent()}
            >
              <MCElement type='checkbox' isSelected={isProxyConsentGiven} onKeyDown={e => { e.key === 'Enter' && handleGiveProxyConsent() }}/>
              <span className='ml-2'>Proxy consent for this user</span>
            </div>
          )}
          <p className={styles.requiredFieldsInfo}>* All fields are required to create an account on the Operation 36 platform</p>
          <div className='h-100 flex-column-reverse flex-sm-row justify-content-center align-items-center'>
            <SubmitButton
              noTopMargin
              buttonMessage='Create member'
              className='mb-3'
              loading={loader}
              disabled={loader || isInvalidForm}
            />
            <SubmitButton
              type='button'
              noTopMargin
              buttonMessage='Cancel'
              buttonColor='navy-button'
              onClick={handleClose}
            />
          </div>
        </form>
      </Container>
    </Modal>
  )
}

NewFamilyMemberModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleCreate: PropTypes.func.isRequired,
  loader: PropTypes.bool,
}

export default NewFamilyMemberModal
