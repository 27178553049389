import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import { ReactComponent as GolferIcon } from '../../../../assets/images/common/icons/golfer-half-icon.svg'
import { ReactComponent as FlagIcon } from '../../../../assets/images/common/icons/weavy-flag-icon.svg'
import { ReactComponent as GroupIcon } from '../../../../assets/images/common/icons/users-group-icon.svg'
import { setUserField } from '../../../../reducers/signUpReducer'
import ProgressBar from './Helpers/ProgressBar'
import StepButton from './Helpers/StepButton'

import classes from './AboutYou.module.scss'

const AboutYou = () => {
  const dispatch = useDispatch()

  const accountSegment = useSelector(state => state.signUp.user.accountSegment)

  const setAccountSegment = (value) => {
    dispatch(setUserField({ field: 'accountSegment', value: value }))
  }

  return (
    <div className={classNames(classes.aboutYou, 'd-flex flex-column')}>
      <header className={classes.header}>
        <ProgressBar step={1} label='About You'/>
      </header>

      <main className={classNames(classes.mainContent, 'd-flex flex-column align-items-center justify-content-start')}>
        <div className='w-100'>
          <h1 className={classes.titleText}>Let&apos;s get to know you... Which describes you best?</h1>
        </div>

        <section className={classNames(classes.accountSegments, 'row mb-2')}>
          <div className={classNames(classes.categoryWrapper, 'col-12 col-lg-8 offset-lg-2 d-flex flex-column')}>
            <article
              className={classNames(classes.categoryCard, { [classes.active]: accountSegment === 'individual' }, 'd-flex align-items-start')}
              aria-hidden='true'
              onClick={() => setAccountSegment('individual')}
            >
              <GolferIcon className={classes.individualIcon} />
              <div>
                <p className={classes.categoryTitle}>Individual Sign-Up</p>
                <p className={classes.categoryDetails}>I want to start my personal golf journey and improve my skills with Op 36.</p>
              </div>
            </article>
          </div>
          <div className={classNames(classes.categoryWrapper, 'col-12 col-lg-8 offset-lg-2 d-flex flex-column')}>
            <article
              className={classNames(classes.categoryCard, { [classes.active]: accountSegment === 'professional' }, 'd-flex align-items-start')}
              aria-hidden='true'
              onClick={() => setAccountSegment('professional')}
            >
              <FlagIcon className={classes.professionalIcon} />
              <div>
                <p className={classes.categoryTitle}>Family Sign-Up</p>
                <p className={classes.categoryDetails}>I&apos;m signing up my child and might also participate in the program.</p>
              </div>
            </article>
          </div>
          <div className={classNames(classes.categoryWrapper, 'col-12 col-lg-8 offset-lg-2 d-flex flex-column')}>
            <article
              className={classNames(classes.categoryCard, { [classes.active]: accountSegment === 'family' }, 'd-flex align-items-start')}
              aria-hidden='true'
              onClick={() => setAccountSegment('family')}
            >
              <GroupIcon className={classes.familyIcon} />
              <div>
                <p className={classes.categoryTitle}>Golf Professional</p>
                <p className={classes.categoryDetails}>I&apos;m a Coach interested in offering an Operation 36 Program.</p>
              </div>
            </article>
          </div>
        </section>

        <StepButton newStep={2} color='orange' message='Continue' disabled={!accountSegment}/>
      </main>
    </div>
  )
}

export default AboutYou
