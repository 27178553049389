import PropTypes from 'prop-types'

import Input from '../../../../common/form/Input'

import classes from './FormInput.module.scss'

const FormInput = (props) => (
  <Input
    classNames={
      {
        inputContainerWrapper: classes.inputContainerWrapper,
        inputContainer: classes.inputContainer,
        input: classes.input,
        telInput: classes.telInput,
        symbol: classes.symbol,
      }
    }
    {...props}
  />
)

FormInput.propTypes = {
  props: PropTypes.object,
}

export default FormInput
