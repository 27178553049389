import PropTypes from 'prop-types'
import { useEffect, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateStudent, deleteStudent, setField } from '../../../reducers/nineHoleEventReducer'
import { ReactComponent as XIcon } from '../../../assets/images/common/icons/X-icon.svg'
import { DIVISION_SELECT_OPTIONS } from '../../../misc/Constants'
import * as userActions from '../../../actions/userActions'

import Select from '../../common/form/Select'
import colors from '../../../assets/styles/globals.scss'
import TextInput from '../../common/form/TextInput'
import Error from '../../common/ErrorMessage'
import StudentWithAvatar from '../../students/StudentWithAvatar'
import SubmitButtom from '../../common/buttons/SubmitButton'
import { ReactComponent as GolferIcon } from '../../../assets/images/common/icons/golfer-half-icon.svg'

import './LogScores.scss'

const yardages = {
  1: 225,
  2: 450,
  3: 900,
  4: 1350,
  5: 1800,
  6: 'fullTee',
}

const LogScores = ({ handleAddGolfer, setScoresSaved, isQuickLog }) => {
  const dispatch = useDispatch()
  const { id, students } = useSelector(state => state.nineHoleEvent)
  const errors = useSelector(state => state.nineHoleEvent.errors.students)
  const [loadingStats, setLoadingStats] = useState(true)

  useEffect(() => {
    if (!loadingStats) {
      return
    }

    const newStudents = [...students]
    const studentsWithoutStats = students.filter(s => !s.stats).map(s => s.user_id)

    if (studentsWithoutStats.length === 0) {
      setLoadingStats(false)
      return
    }

    userActions.getActivitiesStats(studentsWithoutStats, id).then(response => {
      response.data.students_stats.forEach(a => {
        const index = newStudents.findIndex(s => s.user_id === a.user_id)
        newStudents[index] = { ...newStudents[index], stats: a.stats }
      })
      dispatch(setField({ field: 'students', value: newStudents }))
    })
    setLoadingStats(false)
  }, [dispatch, students, id, loadingStats])

  const filteredStudents = useMemo(() => {
    if (students?.length === 0) {
      return []
    } else if (isQuickLog) {
      return [...students].sort((a, b) => a.last_name.toLowerCase().localeCompare(b.last_name.toLowerCase()))
    } else {
      return [...students]
        .filter(s => s.status !== 'declined')
        .sort((a, b) => a.last_name.toLowerCase().localeCompare(b.last_name.toLowerCase()))
    }
  }, [students, isQuickLog])

  const getLabel = (std) => {
    if (std.level > 5) {
      if (std.fullTeeYardage > 1800) {
        return `Level ${getLevel(std.fullTeeYardage)}`
      } else {
        return 'Full Tee'
      }
    } else {
      return `Level ${std.level}`
    }
  }

  const getLevel = (yardage) => {
    if (yardage > 1800 && yardage <= 2300) {
      return 6
    } else if (yardage > 2300 && yardage <= 2600) {
      return 7
    } else if (yardage > 2600 && yardage <= 2900) {
      return 8
    } else if (yardage > 2900 && yardage <= 3200) {
      return 9
    } else if (yardage > 3200) {
      return 10
    } else {
      return null
    }
  }

  const getMilestonesText = (std) => {
    const record = std.stats ? parseInt(std.stats[std.level].record_score) : 0
    const improvedBy = record ? record - std.score : null
    let text = ''
    if (std.score > 8 ) {
      if (std.score <= 36) {
        text = `Level ${std.level} Passed!`
      } else if (std.score && std.score < record) {
        text = `New Personal Best ${std.score}`
        if (improvedBy) {
          text += ` Improved by ${improvedBy} ${improvedBy > 1 ? 'strokes' : 'stroke'}!`
        }
      }
    }
    return text
  }

  const removeStudent = (stdId) => {
    dispatch(deleteStudent(stdId))
  }

  const handleStudentFieldChange = (std, field) => {
    const student = { ...std }
    const { fieldName, fieldValue } = field
    student[fieldName] = fieldValue
    setScoresSaved(false)
    dispatch(updateStudent(student))
  }

  const handleDivisionChange = (std, opt) => {
    dispatch(updateStudent({ ...std, level: opt.value, yardage: yardages[opt.value] }))
  }

  const customStyles = {
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? colors.lightGray : null,
      color: colors.darkNavy,
      cursor: 'pointer',
    }),
  }

  return (
    <div id='LogScores' className='d-flex flex-column table-container'>
      {filteredStudents?.length > 0 ?
        <>
          <table className='table table-responsive animated fadeIn w-100'>
            <thead>
              <tr>
                <th className='golfer-header'>Golfer</th>
                <th className='centered-text'>Level Attempts</th>
                <th className='centered-text'>Baseline Score</th>
                <th className='centered-text'>Record Score</th>
                <th>Level</th>
                <th>Greens</th>
                <th>Putts</th>
                <th>Score</th>
                <th>Milestone</th>
                <th className='centered-text'>Remove</th>
              </tr>
            </thead>
            <tbody>
              {filteredStudents.map((std, index) =>
                <tr
                  key={index}
                  className={'students-table-row'}
                >
                  <td style={{ width: '24%' }} className='golfer-data'>
                    <StudentWithAvatar student={std} clickable/>
                  </td>
                  <td style={{ width: '8%', color: colors.primaryOrange }} className='centered-text'>
                    {std.stats && std.stats[std.level].level_attempts || '-'}
                  </td>
                  <td style={{ width: '8%', color: colors.primaryOrange }} className='centered-text'>
                    {std.stats && std.stats[std.level].baseline_score || '-'}
                  </td>
                  <td style={{ width: '8%', color: colors.primaryOrange }} className='centered-text'>
                    {std.stats && std.stats[std.level].record_score || '-'}
                  </td>
                  <td style={{ width: '20%' }} >
                    <div className='d-flex'>
                      <Select
                        id='divisionSelect'
                        className='mr-5 w-100'
                        styles={customStyles}
                        isSearchable={false}
                        value={{ value: std.level, label: getLabel(std) }}
                        onChange={(opt) => handleDivisionChange(std, opt)}
                        options={DIVISION_SELECT_OPTIONS}
                        menuPlacement='auto'
                      />
                      <div className='ml-2'>
                        {std.level > 5 &&
                      <TextInput
                        name='fullTeeYardage'
                        className='col-input'
                        value={std.fullTeeYardage}
                        onChange={(e) => handleStudentFieldChange(std, { fieldName: 'fullTeeYardage', fieldValue: e.target.value })}
                      />}
                      </div>
                    </div>
                  </td>
                  <td>
                    <TextInput
                      name='greens'
                      className='col-input'
                      value={std.greens ? std.greens.toString() : ''}
                      onChange={(e) => handleStudentFieldChange(std, { fieldName: 'greens', fieldValue: e.target.value })}
                    />
                  </td>
                  <td>
                    <TextInput
                      name='putts'
                      className='col-input'
                      value={std.putts ? std.putts.toString() : ''}
                      onChange={(e) => handleStudentFieldChange(std, { fieldName: 'putts', fieldValue: e.target.value })}
                    />
                  </td>
                  <td>
                    <TextInput
                      name='score'
                      className='col-input'
                      value={std.score ? std.score.toString() : ''}
                      onChange={(e) => handleStudentFieldChange(std, { fieldName: 'score', fieldValue: e.target.value })}
                    />
                  </td>
                  <td style={{ width: '13%' }} className='milestones-col'>
                    {getMilestonesText(std)}
                  </td>
                  <td style={{ width: '10%' }} className='centered-text'>
                    <XIcon className='trash-icon' fill={colors.red} onClick={() => removeStudent(std.user_id)}/>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {errors?.length > 0 && (
            <div className='row mb-4'>
              <div className='col-md-8 mx-auto'>
                <Error error={errors.join('\n')}/>
              </div>
            </div>
          )}
        </>
        :
        <div className='no-students-placeholder'>
          <GolferIcon id='golferIcon' fill={colors.lightNavy} width={'64px'} height={'64px'}/>
          <p className='placeholder-title'>No Golfers Attending</p>
          <div className='centered-text'>Looks there are no golfers attending this event. You can manually add below.</div>
        </div>
      }
      <div className='d-flex justify-content-center w-100'>
        <div className='add-golfer-button'>
          <SubmitButtom buttonMessage='Add Golfer' iconType='addButton' onClick={() => handleAddGolfer()}/>
        </div>
      </div>
    </div>
  )
}

LogScores.propTypes = {
  handleAddGolfer: PropTypes.func.isRequired,
  setScoresSaved: PropTypes.func.isRequired,
  isQuickLog: PropTypes.bool.isRequired,
}

export default LogScores
