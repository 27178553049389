import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import classNames from 'classnames'
import moment from 'moment'

import { selectedDateEvents, selectedDateSessions } from '../../../../../reducers/studentDashboardReducer'
import { getScheduleSessionsByMonth } from '../../../../../actions/scheduleSessionActions'
import { getEventsByMonth } from '../../../../../actions/eventActions'
import { ReactComponent as InfoIcon } from '../../../../../assets/images/common/icons/info-circle-icon-dark.svg'

import SessionCard from './SessionCard'
import EventCard from './EventCard'
import EmptySchedule from './EmptySchedule'

import classes from './Schedule.module.scss'

const Schedule = ({ className }) => {
  const { sessionsByMonth, selectedDate, eventsByMonth, selectedMonthDate, activeCourses } = useSelector(state => state.studentDashboard)
  const events = useSelector(selectedDateEvents)
  const sessions = useSelector(selectedDateSessions)
  const dispatch = useDispatch()
  const noPrograms = activeCourses?.length === 0

  useEffect(() => {
    !sessionsByMonth[selectedMonthDate.getMonth()] && dispatch(getScheduleSessionsByMonth({ selectedMonthDate }))
    !eventsByMonth[selectedMonthDate.getMonth()] && dispatch(getEventsByMonth({ selectedMonthDate }))
  }, [selectedMonthDate, dispatch, eventsByMonth, sessionsByMonth])


  const getScheduleTitle = () => {
    if (new Date().toDateString() === selectedDate.toDateString()){
      return 'Today\'s Schedule'
    } else {
      return `${moment(selectedDate).format('MMMM Do')} Schedule`
    }
  }

  return (
    <div className={classNames(classes.schedule, { [className]: className })}>
      <div className={classes.scheduleTitle}>
        <p className={classes.scheduleTitleText}>{getScheduleTitle()}</p>
        <InfoIcon data-for='infoTooltip' data-tip/>
        <ReactTooltip id='infoTooltip'
          effect='solid'
          delayUpdate={5}
          delayHide={5}
          place={'top'}
          className={classes.infoTooltip}
        ><p>Browse your calendar to see upcoming Programs and 9 Hole Events</p></ReactTooltip>
      </div>
      {noPrograms ?
        <EmptySchedule
          title='No Active Programs'
          text='Create a Program or a 9 Hole Event Series and Publish it to your students!'
          noActivePrograms={noPrograms}
        />
        :
        sessions.length === 0 && events.length === 0 ? (
          <EmptySchedule
            title='No Programs Today'
            text='Enjoy your time off, or explore other days in your calendar.'
          />
        ) : (
          <>
            {sessions.map((session, index) =>
              <SessionCard key={index} session={session}/>
            )}
            {events.map((e, index) =>
              <EventCard key={index} event={e}/>
            )}
          </>
        )
      }
    </div>
  )
}

Schedule.propTypes = {
  className: PropTypes.string,
}

export default Schedule
