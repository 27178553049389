import { useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import SubmitButton from '../../../../common/buttons/SubmitButton'
import NoCommunityModal from '../../../../community-map/NoCommunityModal'

import classes from './RequestLocationCard.module.scss'

const RequestLocationCard = () => {
  const address = useSelector(state => state.studentDashboard.address)

  const [showRequestLocationModal, setShowRequestLocationModal] = useState(false)

  return (
    <div className={classNames(classes.requestLocationCard, 'd-flex flex-column h-100')}>
      <h4 className={classes.title}>No Locations Near You?</h4>

      <small className={classNames(classes.subtitle, 'mt-1 mb-3')}>Request a Facility Location</small>

      <SubmitButton
        onClick={(e) => { e.preventDefault(); setShowRequestLocationModal(true) }}
        buttonMessage='Request a Location'
        buttonColor='orange-button'
        className='mt-auto'
      />

      <div
        className={classNames({ [classes.greyOutModalBackground]: showRequestLocationModal })}
        onClick={() => setShowRequestLocationModal(false)}
        aria-hidden='true'
      />

      {showRequestLocationModal && (
        <NoCommunityModal close={() => setShowRequestLocationModal(false)} location={address} />
      )}
    </div>
  )
}

export default RequestLocationCard
