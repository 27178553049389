import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { setField } from '../../../../../reducers/signUpReducer'
import classes from './StepButton.module.scss'

const StepButton = ({ newStep, onStepChange, color, message, disabled, displayLoader }) => {
  const dispatch = useDispatch()

  const { loader } = useSelector(state => state.signUp)

  const handleStepChange = () => {
    if (onStepChange) {
      onStepChange()
    } else {
      dispatch(setField({ field: 'step', value: newStep }))
    }
  }

  return (
    <button
      className={classNames(classes.stepButton, 'btn btn-primary', color === 'orange' ? classes.orange : classes.navy)}
      onClick={handleStepChange}
      disabled={disabled || loader}
    >
      {loader && displayLoader && <i className='fa fa-spinner fa-spin mr-2' />}
      {message}
    </button>
  )
}

StepButton.propTypes = {
  newStep: PropTypes.number,
  onStepChange: PropTypes.func,
  color: PropTypes.string,
  message: PropTypes.string,
  disabled: PropTypes.bool,
  displayLoader: PropTypes.bool,
}

export default StepButton
