import { useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { Button, Popover } from 'reactstrap'
import PropTypes from 'prop-types'

import ReportsPopover from './ReportsPopover'
import { hasUserWriteAccess, isUserCoachInCommunity } from '../../utility/userHelper'

const ProfileHeaderActions = ({
  user,
  onTogglePlayerProgressModal,
  onToggleJourneyModal,
  onToggleLogActionsModal,
  onToggleProgramsModal,
}) => {
  const currentUser = useSelector(state => state.user.current_user)
  const [reportDropdown, setReportDropdown] = useState(false)
  const navigate = useNavigate()

  const toggleDropdown = () => setReportDropdown(state => !state)

  const goToFamily = () => {
    const familyId = user.family.id

    navigate(
      `/familydetail/${familyId}`,
      { state: { familyId: familyId } }
    )
  }

  return (
    <div className='row container-fluid btn-row animated fadeIn'>
      {isUserCoachInCommunity(currentUser, user) && (
        <div className='pull-right hidden-sm-down'>
          {currentUser.user_settings.coach_permission > 1 && (
            <>
              <Button
                type='button'
                id='reports-popover'
                onClick={toggleDropdown}
                className='btn btn-primary edit-btn'
              >
                <i className='fa fa-book' />
              </Button>
              <Popover
                placement='bottom'
                isOpen={reportDropdown}
                target='reports-popover'
                toggle={toggleDropdown}
              >
                <ReportsPopover
                  toggleDropdown={toggleDropdown}
                  toggleJourneyModal={onToggleJourneyModal}
                  togglePlayerProgressModal={onTogglePlayerProgressModal}
                  fromSticky={false}
                />
              </Popover>
            </>
          )}
        </div>
      )}
      {hasUserWriteAccess(currentUser, user) && (
        <>
          <div className='pull-right hidden-sm-down'>
            <button
              className='btn btn-primary edit-btn'
              onClick={onToggleLogActionsModal}
            >
              <i className='fa fa-plus' />
            </button>
          </div>
          <div className='pull-right hidden-sm-down'>
            <NavLink
              to={{ search: '?editTab=personal' }}
              className='btn btn-primary edit-btn'
            >
              <i className='fa fa-pencil-square-o' />
            </NavLink>
          </div>
          <div className='pull-right hidden-sm-down'>
            <button
              onClick={goToFamily}
              className='btn btn-primary family-btn'
            >
              <i className='fa fa-group' />
            </button>
          </div>
          <div className='pull-right hidden-sm-down'>
            <button
              onClick={onToggleProgramsModal}
              className='btn btn-primary programs-btn'
            >
              <i className='fa fa-flag' />
            </button>
          </div>
        </>
      )}
    </div>
  )
}

ProfileHeaderActions.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    family: PropTypes.shape({
      id: PropTypes.number,
    }),
    communities: PropTypes.arrayOf({
      id: PropTypes.number,
    }),
  }),
  onTogglePlayerProgressModal: PropTypes.func,
  onToggleJourneyModal: PropTypes.func,
  onToggleLogActionsModal: PropTypes.func,
  onToggleProgramsModal: PropTypes.func,
}

export default ProfileHeaderActions
