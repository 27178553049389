import { createSlice, createSelector } from '@reduxjs/toolkit'
import { getScheduleSessionsByMonth } from '../actions/scheduleSessionActions'
import { getEventsByMonth } from '../actions/eventActions'
import { DATETIME_FORMAT, getMomentInCommunityTimezoneReversed } from '../utility/datesHelper'

import moment from 'moment'

const initialState = {
  sessionsByMonth: {},
  selectedMonthDate: new Date(),
  selectedDate: new Date(),
  eventsByMonth: {},
  address: '',
  nearbyCommunities: null,
  errors: {},
}

const studentDashboardSlice = createSlice({
  name: 'studentDashboard',
  initialState,
  reducers: {
    setSelectedMonthDate: (state, action) => {
      state.selectedMonthDate = action.payload
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload
    },
    setAddress: (state, action) => {
      state.address = action.payload
      state.errors.address = ''
    },
    resetAddress: (state, _) => {
      state.address = ''
      state.errors.address = ''
      state.nearbyCommunities = null
    },
    setNearbyCommunities: (state, action) => {
      state.nearbyCommunities = action.payload
    },
    setError: (state, action) => {
      const { field, value } = action.payload

      state.errors[field] = value
    },
  },
  extraReducers: {
    [getScheduleSessionsByMonth.fulfilled]: (state, action) => {
      state.sessionsByMonth[state.selectedMonthDate.getMonth()] = action.payload.scheduleSessions
    },
    [getEventsByMonth.fulfilled]: (state, action) => {
      state.eventsByMonth[state.selectedMonthDate.getMonth()] = action.payload.events
    },
  },
})

export const {
  setSelectedDate,
  setSelectedMonthDate,
  setAddress,
  resetAddress,
  setNearbyCommunities,
  setError,
} = studentDashboardSlice.actions

export default studentDashboardSlice.reducer

export const sessionsDates = createSelector(
  state => state.studentDashboard.sessionsByMonth,
  state => state.studentDashboard.selectedMonthDate,
  (sessions, selectedMonthDate) =>
    sessions[selectedMonthDate.getMonth()]
      ?.map(s => getMomentInCommunityTimezoneReversed(moment(s.startDate), 'America/New York', DATETIME_FORMAT).toDate()) || []
)

export const eventsDates = createSelector(
  state => state.studentDashboard.eventsByMonth,
  state => state.studentDashboard.selectedMonthDate,
  state => state.user.current_user.communities[0]?.timezone ?? 'UTC',
  (events, selectedMonthDate, timezone) =>
    events[selectedMonthDate.getMonth()]?.map(e => getMomentInCommunityTimezoneReversed(moment(e.eventDate), timezone, DATETIME_FORMAT).toDate())
      || []
)

export const selectedDateEvents = createSelector(
  state => state.studentDashboard.eventsByMonth,
  state => state.studentDashboard.selectedDate,
  state => state.user.current_user.communities[0]?.timezone ?? 'UTC',
  (
    events,
    selectedDate,
    timezone
  ) => {
    const selectedDateEvents = []
    events[selectedDate.getMonth()]?.forEach(e => {
      const studentsProfiles = e.enrolledUsers.map(u => u.avatar?.fileUrl)
      const event = {
        id: e.id,
        seriesName: e.series.title,
        name: e.indexName,
        format: e.format,
        location: e.location,
        date: getMomentInCommunityTimezoneReversed(moment(e.eventDate), timezone, DATETIME_FORMAT).toDate(),
        students: studentsProfiles,
      }
      if (event.date.toDateString() === selectedDate.toDateString()) {
        selectedDateEvents.push(event)
      }
    })

    return selectedDateEvents
  }
)

export const selectedDateSessions = createSelector(
  state => state.studentDashboard.sessionsByMonth,
  state => state.studentDashboard.selectedDate,
  state => state.user.current_user.communities[0]?.timezone ?? 'UTC',
  (
    sessions,
    selectedDate,
    timezone
  ) => {
    const selectedDateSessions = []
    sessions[selectedDate.getMonth()]?.forEach(s => {
      const enrollmentsProfiles = s.enrolledUsers.map(u => u.avatar?.fileUrl)
      const session = {
        programTitle: s.course.name,
        programId: s.course.id,
        categoryColor: s.course.courseCategory?.color,
        sessionId: s.id,
        enrollments: s.enrolledUsers.length,
        enrollmentsProfiles: enrollmentsProfiles,
      }
      session.sessionTitle = s.title
      session.sessionStartDate = getMomentInCommunityTimezoneReversed(moment(s.startDate), timezone, DATETIME_FORMAT).toDate()
      session.sessionEndDate = getMomentInCommunityTimezoneReversed(moment(s.endDate), timezone, DATETIME_FORMAT).toDate()
      session.location = s.location
      if (session.sessionStartDate.toDateString() === selectedDate.toDateString()) {
        selectedDateSessions.push(session)
      }
    })

    return selectedDateSessions
  }
)
