import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import ButtonLink from '../../../common/buttons/ButtonLink'
import StepButton from './Helpers/StepButton'
import AccountCard from '../../../account/AccountCard'

import classes from './Summary.module.scss'

const Summary = () => {
  const { user, familyMembers } = useSelector(state => state.signUp)
  const redirectUrl = localStorage.getItem('redirectUrl')

  useEffect(() => {
    localStorage.removeItem('redirectUrl')
  }, [redirectUrl])

  return (
    <div className={classNames(classes.summary, 'd-flex justify-content-center')}>
      <section className={classNames(classes.actionPanel, 'd-flex flex-column')}>
        <h1 className={classes.titleText}>
          {familyMembers.length > 0 ? `Welcome,\n${user.lastName} family` : `Hello ${user.firstName}!`}
        </h1>

        {[user].concat(familyMembers).map((u, index) => (
          <AccountCard
            key={index}
            avatarUrl={u.avatarUrl}
            fullName={`${u.firstName} ${u.lastName}`}
            age={u.age}
            familyAdmin={u.familyAdmin}
          />
        ))}

        {familyMembers.length === 0 && (
          <h3 className={classNames(classes.subtitleText)}>Congratulations! Your account has been created successfully!</h3>
        )}

        <StepButton color='navy' message='Add Family Member' newStep={5}/>

        <ButtonLink navigateTo={redirectUrl || '/dashboard'} className={classNames(classes.actionLink, 'mt-4')}>
          {redirectUrl ? 'Continue to Program Registration' : 'Go to Dashboard'}
        </ButtonLink>
      </section>
    </div>
  )
}

export default Summary
